import {
  EFilter,
  IFilter,
} from "../../models/utils/filter/FilterType.interface";

export function convertFiltersToQuery(filters?: IFilter[]) {
  if (filters) {
    return filters
      .map((filter) => {
        if (filter.value) {
          if (
            filter.type === EFilter.SELECT_MULTIPLE ||
            filter.type === EFilter.TAG
          ) {
            return filter.value
              .map((value: any) => `&${filter.name}[]=${value}`)
              .join("");
          }
          return `&${filter.name}=${filter.value}`;
        }

        return "";
      })
      .join("");
  }

  return "";
}

interface ParamUrl {
  key: string;
  value: any;
}

export function getParamsUrl(params: Array<ParamUrl>) {
  const paramsFilled = params.filter(
    (value) =>
      value.value != null && value.value !== "" && value.value !== undefined
  );
  const arrayParams = paramsFilled.map(
    (paramUrl) => `${paramUrl.key}=${paramUrl.value}`
  );
  const nbParamsFilled = arrayParams.length;
  let paramsUrl = nbParamsFilled === 1 ? arrayParams[0] : arrayParams.join("&");
  // paramsUrl = nbParamsFilled <= 1 ? paramsUrl.substring(0, paramsUrl.length - 1) : paramsUrl

  return paramsUrl !== "" ? `?${paramsUrl}` : "";
}

export function getBase64fromFile(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.readAsDataURL(file);

    fileReader.onload = function () {
      resolve(fileReader.result as string);
    };

    fileReader.onerror = function (error) {
      reject(error);
    };
  })
}

export async function getBase64FromImageUrl(imageUrl: string): Promise<string> {
  return new Promise(async (resolve, reject) => {
    const response = await fetch(imageUrl);

    const blob = await response.blob();

    const fileReader = new FileReader();

    fileReader.readAsDataURL(blob);

    fileReader.onload = function () {
      resolve(fileReader.result as string);
    };

    fileReader.onerror = function (error) {
      reject(error);
    };
  })
}

export function getImageNameFromImageUrl(imageUrl: string): string {
  const splitUrl = imageUrl.split('/');

  return splitUrl[splitUrl.length - 1];
}