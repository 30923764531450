import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import DatePicker from "../../../../../components/form/date-picker/date-picker";
import DateHelper from "../../../../../models/utils/DateHelper";
import documentsService from "../../../../../services/documents.service";
import Select from "../../../../../components/form/select/next-select/select";
import Table from "../../../../../components/fundamentals/table";
import TableContainer from "../../../../../components/fundamentals/table-container";
import { Document } from "../../../../../models/Document";
import { DataPaginate } from "../../../../../models/utils/DataPaginate";
import {getTablePagingState} from "../../../../../services/utils/TableHelper";


interface IDocumentProps {
    clientId: string;
}
  
type FormDataDocument = {
    start_at: string;
    end_at: string;
    type_document: string;
};
  
export default function DocumentsTableCustomer({ clientId }: IDocumentProps) {
    const [tmpSearch, setTmpSearch] = useState<string>();
    const { register, control } = useForm<FormDataDocument>();
    const [startAt, setStartAt] = useState<Date>();
    const [endAt, setEndAt] = useState<Date>();
    const [dataPaginated, setDataPaginated] = useState<DataPaginate>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [currentPage, setCurrentPage] = useState(1);
  
    useEffect(() => {
      async function fetchData(){
        try{
          setIsLoading(true)
          setDataPaginated(await documentsService.list(
            currentPage,
            clientId, 
            startAt ? DateHelper.dateToDDMMYYYY(startAt) : undefined, 
            endAt ? DateHelper.dateToDDMMYYYY(endAt) : undefined, 
          ));
          setIsLoading(false)
        }catch(e){
          setIsLoading(false)
        }
      }
      fetchData();
    }, [currentPage, clientId, startAt, endAt])
  
    const options = [
      {value: -1, label: 'Tous les documents'},
      {value: 1, label: 'Bon de livraison'},
      {value: 2, label: 'Offres de prix'},
    ]

    return (
      <>
        <TableContainer 
            numberOfRows={10}
            hasPagination
            pagingState={getTablePagingState(dataPaginated!, setCurrentPage, "résultats")}
            isLoading={isLoading}
        >
          {dataPaginated && <Table>
            <>
              <Table.Head>
                <Table.HeadRow>
                  <Table.HeadCell>Numéro de document</Table.HeadCell>
                  <Table.HeadCell>Dates</Table.HeadCell>
                  <Table.HeadCell>Type</Table.HeadCell>
                  <Table.HeadCell>Agence</Table.HeadCell>
                </Table.HeadRow>
              </Table.Head>
              <Table.Body>
                {dataPaginated.data?.map((doc: Document) => (
                  <DocumentRow doc={doc} key={`document-row-${doc.document_id}`} />
                ))}
              </Table.Body>
            </>
          </Table> }
        </TableContainer>
      </>
    );
  };
  
  interface IDocumentRow {
    doc: Document;
  }
  
  const DocumentRow = ({ doc }: IDocumentRow) => {
    return (
      <Table.Row
        color={"inherit"}
        onClick={() =>
          window.open(
            documentsService.generatePdfUrl(doc),
            "_blank",
            "noreferrer"
          )
        }
        draggable
        className="hover:bg-grey-5 cursor-pointer"
      >
        <Table.Cell>{doc.code + doc.document_id}</Table.Cell>
        <Table.Cell>{doc.created_at}</Table.Cell>
        <Table.Cell>{doc.type_document}</Table.Cell>
        <Table.Cell>{doc.class_code}</Table.Cell>
      </Table.Row>
    );
  };
  
  