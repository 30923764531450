import React from "react"
import IconProps from "../../fundamentals/types/icon-type"

const DecorationAuthTop: React.FC<IconProps> = ({
  color = "currentColor",
  ...attributes
}) => {
  return (
    <svg
        width="89" height="140"
      viewBox="0 0 89 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...attributes}
    >
        <path d="M89.5 140L0 91.1L89.5 0.199951L89.5 140Z" fill="#FFDF00"/>
        <path d="M50 83.8L58.3 82.6L39.8 73.5L12.4001 60.2L10.6 64.6L37.7001 77.8L50 83.8Z" fill="#2C57A0"/>
        <path d="M69.5 80.9L77.8 79.7L44.7001 63.6001L16.5 49.8L14.7 54.2L42.6 67.8L69.5 80.9Z" fill="#2C57A0"/>
        <path d="M11 89.5L19.3 88.3L4.10004 80.9L2.40005 85.3L11 89.5Z" fill="#2C57A0"/>
        <path d="M33.7001 86.2L38.8 85.4L34.9001 83.5L8.20005 70.5L6.50005 74.9L30.5 86.6L33.7001 86.2Z" fill="#2C57A0"/>
        <path d="M89 78.1L89.5 78V73L49.5 53.6L20.6 39.5L18.9 43.9L47.5 57.8L89 78.1Z" fill="#2C57A0"/>
        <path d="M89.5 65.8999V60.7L54.4 43.6L24.8 29.2L23 33.6L52.3 47.8L89.5 65.8999Z" fill="#2C57A0"/>
        <path d="M89.5 53.6001L89.4 48.3L59.3 33.6001L28.9 18.8L27.1 23.2L57.2 37.8L89.5 53.6001Z" fill="#2C57A0"/>
        <path d="M89.3999 41.2V35.9L64.0999 23.6L32.9999 8.5L31.2999 12.9L62.0999 27.9L89.3999 41.2Z" fill="#2C57A0"/>
        <path d="M89.3999 28.8V23.6L68.9999 13.6L37.1999 -1.90002L35.3999 2.49997L66.8999 17.9L89.3999 28.8Z" fill="#2C57A0"/>
        <path d="M89.3999 16.5V11.2L73.8999 3.60005L41.2999 -12.2L39.4999 -7.79996L71.7999 7.90004L89.3999 16.5Z" fill="#2C57A0"/>
        <path d="M89.3999 4.10003V-1.19997L78.6999 -6.29998L45.3999 -22.6L43.6999 -18.2L76.6999 -2.09998L89.3999 4.10003Z" fill="#2C57A0"/>
    </svg>
  )
}

export default DecorationAuthTop
