import Button, {ButtonProps} from "../../../button";
import {useMemo} from "react";

type Modal2ButtonsLayoutProps = {
    position: "right"|"left",
    submitButtonVariant?: ButtonProps["variant"],
    discardButtonLabel: string,
    discardButtonHandler: () => void,
    submitButtonLabel: string,
    submitButtonHandler: () => void,
    showSubmitButtonLoadingSpinner: boolean,
    disableSubmitButton: boolean,
}

/** Display a 2 buttons footer inside Modal.Footer **/
function Modal2ButtonsLayout(
    {
        position = "right",
        discardButtonLabel,
        discardButtonHandler,
        submitButtonLabel,
        submitButtonVariant = "primary",
        submitButtonHandler,
        showSubmitButtonLoadingSpinner,
        disableSubmitButton
    }: Modal2ButtonsLayoutProps)
{
    const buttonsPositionClassName = useMemo(() => {
            return position === "left" ? "justify-start" : "justify-end"
    }, [position]);

    return (
        <div className={`flex items-center ${buttonsPositionClassName} w-full gap-x-xsmall`}>
            <Button
                variant="secondary"
                size="small"
                type="submit"
                onClick={discardButtonHandler}
            >
                {discardButtonLabel}
            </Button>
            <Button
                variant={submitButtonVariant}
                size="small"
                loading={showSubmitButtonLoadingSpinner}
                onClick={submitButtonHandler}
                disabled={disableSubmitButton}
            >
                {submitButtonLabel}
            </Button>
        </div>
    )
}

export default Modal2ButtonsLayout;