const orderTunnelSteps = [
    {
        value: '0',
        label: "Sessions sur l'app",
        fill: "#5470c6"
    },
    {
        value: '1',
        label: "Visiter le panier",
        fill: "#92cc76"
    },
    {
        value: '2',
        label: "Valider le panier",
        fill: "#fac858"
    },
    {
        value: '3',
        label: "Finaliser la commande",
        fill: "#ee6767"
    },
    {
        value: '4',
        label: "Commander",
        fill: "#73c0de"
    },
]
export default orderTunnelSteps

export const orderFunnelDefault = orderTunnelSteps.map((orderTunnel, i) => {
    return {value: (100 - (i * 20)), name: `${orderTunnel.label}`, fill: orderTunnel.fill}
})