import React from "react"
import IconProps from "../../fundamentals/types/icon-type"

const DecorationAuthBottom: React.FC<IconProps> = ({
  color = "currentColor",
  ...attributes
}) => {
  return (
    <svg
        width="318" height="180"
      viewBox="0 0 318 180"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...attributes}
    >
      <path d="M-10.1831 145.951L-9.69414 129.151L4.81886 158.997L15.2559 180.36H6.61286L-2.19314 162.422L-10.1831 145.951Z" fill="white"/>
      <path d="M-9.20504 106.488L-8.71603 89.854L21.127 151.007L35.314 180.36H26.834L14.114 154.431L-9.20504 106.488Z" fill="white"/>
      <path d="M-8.22709 67.187L-7.73809 50.387L37.2709 143.179L55.5349 180.36H46.8919L30.4219 146.441L-8.22709 67.187Z" fill="white"/>
      <path d="M-7.24799 27.723L-7.08498 22.015L-6.75898 11.089L-2.51898 19.732L53.578 135.189L75.593 180.36H67.113L46.729 138.613L-7.24799 27.723Z" fill="white"/>
      <path d="M6.77596 15.166L-0.562035 -1.52588e-05L11.179 6.68599L13.625 11.904L69.885 127.198L95.814 180.36H87.171L63.036 130.623L6.77596 15.166Z" fill="white"/>
      <path d="M27.1598 15.66L38.9008 22.346L86.1928 119.37L115.872 180.36H107.392L79.3438 122.632L27.1598 15.66Z" fill="white"/>
      <path d="M54.882 31.311L66.623 37.834L102.5 111.38L136.093 180.36H127.45L95.488 114.804L54.882 31.311Z" fill="white"/>
      <path d="M82.605 46.966L94.505 53.489L118.805 103.389L156.314 180.36H147.671L111.795 106.814L82.605 46.966Z" fill="white"/>
      <path d="M110.49 62.46L122.232 69.144L135.114 95.56L176.372 180.36H167.892L128.102 98.823L110.49 62.46Z" fill="white"/>
      <path d="M138.213 78.113L149.954 84.799L151.254 87.571L196.593 180.36H187.95L144.409 90.996L138.213 78.113Z" fill="white"/>
      <path d="M177.676 100.454L216.651 180.36H208.171L165.935 93.768L177.676 100.454Z" fill="white"/>
      <path d="M205.563 116.109L236.873 180.36H228.23L193.658 109.423L205.563 116.109Z" fill="white"/>
      <path d="M233.285 131.76L256.442 179.218L256.931 180.36H248.451L221.544 125.078L233.285 131.76Z" fill="white"/>
      <path d="M249.266 140.733L261.008 147.26L272.752 171.228L277.152 180.36H268.509L265.737 174.652L249.266 140.733Z" fill="white"/>
      <path d="M276.989 156.225L288.73 162.911L289.056 163.4L297.21 180.36H288.73L282.044 166.66L276.989 156.225Z" fill="white"/>
      <path d="M316.616 178.566L317.431 180.36H308.788L304.711 171.88L316.616 178.566Z" fill="white"/>
    </svg>

  )
}

export default DecorationAuthBottom
