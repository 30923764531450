import { useState } from "react";
import { useForm } from "react-hook-form";
import Button from "../../../../../components/fundamentals/button";
import Modal from "../../../../../components/fundamentals/modal";
import useNotification from "../../../../../hooks/use-notification";
import categoryService from "../../../../../services/category.service";
import DragDropImage from "../../../../../components/form/drag-drop-image";
import Actionables, {
  ActionType,
} from "../../../../../components/fundamentals/actionables/actionables";
import TrashIcon from "../../../../../components/fundamentals/icons/trash-icon";
import { ETypeModalEdit } from "../CategoryDetailPage";
import { Category } from "../../../../../models/Category";

interface IProps {
  onClose: () => void;
  type: ETypeModalEdit;
  category: Category;
  refreshCategory: () => void;
}

type ModalCategoryEditImageFormData = {
  name: string;
  visibility: string | undefined;
};

export default function ModalCategoryEditImage({
  type,
  onClose,
  category,
  refreshCategory,
}: IProps) {
  const [isLoading, setIsLoading] = useState<boolean>();
  const notification = useNotification();
  const [image, setImage] = useState<any>(
    type === ETypeModalEdit.PHOTO ? category?.image_url : category?.picto_url
  );

  const submit = async () => {
    try {
      setIsLoading(true);
      await categoryService.update(
        category.id,
        type === ETypeModalEdit.PHOTO
          ? {
              enable: category.enable,
              order: category.order,
              img_base64: image,
            }
          : {
              enable: category.enable,
              order: category.order,
              picto_base64: image,
            }
      );
      await refreshCategory();
      notification(
        "Succès",
        type === ETypeModalEdit.PHOTO
          ? "La photo a été modifiée"
          : "Le picto a été modifié",
        "success"
      );
      onClose();
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      notification(
        "Erreur",
        `Impossible de modifier ${
          type === ETypeModalEdit.PHOTO ? "la photo" : "le picto"
        }`,
        "error"
      );
    }
  };

  const getActions = (): ActionType[] => [
    {
      label: "Supprimer",
      variant: "danger",
      onClick: () => setImage(null),
      icon: <TrashIcon size={20} />,
    },
  ];

  return (
    <Modal handleClose={onClose} isLargeModal>
      <Modal.Body>
        <Modal.Header handleClose={onClose}>
          <h1 className="inter-xlarge-semibold mb-xsmall">
            Modifier {type === ETypeModalEdit.PHOTO ? "la photo" : "le picto"}
          </h1>
        </Modal.Header>
        <Modal.Content>
          <div>
            <h2 className="inter-base-semibold mb-2xsmall">
              Vignette
            </h2>
            <p className="inter-small-regular text-grey-50">
              Utilisée pour représenter la catégorie
            </p>
            <div className="flex items-center gap-x-base my-6">
              <DragDropImage
                setFileUpl={(value: string | ArrayBuffer | null) =>
                  setImage(value)
                }
                fileUpl={image}
              />
            </div>
            {image && (
              <>
                <h2 className="inter-base-semibold mb-2xsmall mt-large">
                  Téléchargé
                </h2>
                <div className="w-full flex items-center justify-between my-10">
                  <img src={image} className="w-40" alt="" />
                  <Actionables forceDropdown={true} actions={getActions()} />
                </div>
              </>
            )}
          </div>
        </Modal.Content>
        <Modal.Footer>
          <div className="flex items-center justify-end w-full gap-x-xsmall">
            <Button
              variant="secondary"
              size="small"
              type="submit"
              onClick={onClose}
            >
              Annuler
            </Button>
            <Button
              variant="primary"
              size="small"
              loading={isLoading}
              onClick={submit}
            >
              Sauvegarder
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  );
}
