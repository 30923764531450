export class ProductDocument {

    public title: string;
    public type: string;
    public url: string;

    constructor(data: any) {
        this.title = data.title;
        this.type = data.type;
        this.url = data.url;
    }
}