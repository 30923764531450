function renderGoogleSignInButton(callback:  (googleResponse: any) => Promise<void>) {
    window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID!,
        callback: callback,
    });

    window.google.accounts.id.renderButton(document.getElementById("google-sign-in")!, {
        type: "standard",
        theme: "filled_blue",
        size: "medium",
        text: "signin_with",
        shape: "rectangular",
        width: "320",
    });
}

export default {renderGoogleSignInButton}