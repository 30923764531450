import TemplateLogin from "../../../../components/templates/TemplateLogin/TemplateLogin";
import {useCallback, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import authService from "../../../../services/auth.service";
import AuthForm, {AuthFormData} from "../AuthForm";

function PasswordReset() {

    const {token} = useParams();

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const onSubmit = useCallback(async (data: Partial<AuthFormData>) => {
        data.token = token;

        setErrorMessage("");

        setIsLoading(true);

        try {
            await authService.validatePasswordAfterReset(data as AuthFormData);

            navigate("/login");
        } catch (e: any) {
            if (e.status) {
                setErrorMessage(e.message);
            }
        } finally {
            setIsLoading(false);
        }
    }, []);

    return (
        <TemplateLogin>
            <div className="flex h-full w-full items-center justify-center">
                <div
                    className={
                        "flex min-h-[500px] w-[640px] bg-grey-0 rounded-rounded justify-center transition-['min-height'] duration-300"
                    }
                >
                    <div className="flex flex-col pt-12 w-full px-[120px] items-center">
                        <AuthForm
                            isLoading={isLoading}
                            onSubmit={onSubmit}
                            context="reset"
                            errorMessage={errorMessage}
                        />
                    </div>
                </div>
            </div>
        </TemplateLogin>
    )
}

export default PasswordReset