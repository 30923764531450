import {useCallback, useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import Section from "../../../../components/fundamentals/section";
import { Client } from "../../../../models/Client";
import clientsService from "../../../../services/clients.service";
import TableContainer from "../../../../components/fundamentals/table-container";
import Table from "../../../../components/fundamentals/table";
import { Order } from "../../../../models/Order";
import ordersService from "../../../../services/orders.service";
import { DataPaginate } from "../../../../models/utils/DataPaginate";
import OrderRow from "../../orders/orders-page/order-table/OrderRow";
import StatusIndicator from "../../../../components/fundamentals/status-indicator";
import BodyCard from "../../../../components/fundamentals/body-card/body-card";
import UsersTableCustomer from "./users-table/UsersTableCustomer";
import DocumentsTableCustomer from "./documents-table/DocumentsTableCustomer";
import OrdersTableCustomer from "./orders-table/OrdersTableCustomer";
import {ActionType} from "../../../../components/fundamentals/actionables/actionables";
import PlusIcon from "../../../../components/fundamentals/icons/plus-icon";
import ModalClientUser from "./modal-client-user/ModalClientUser";
import Breadcrumb from "../../../../components/fundamentals/breadcrumb/Breadcrumb";

export default function ClientDetailPage() {
  let { id } = useParams();
  const [client, setClient] = useState<Client>();
  const [loading, setLoading] = useState<boolean>(true);
  const [ordersPaginated, setOrdersPaginated] = useState<DataPaginate>();
  const [showCreateClientUserModal, setShowCreateClientUserModal] = useState<boolean>(false);

  useEffect(() => {
    async function fetchData() {
      try {
        if (id) {
          const clientData = await clientsService.find(id);
          setClient(clientData);
          setOrdersPaginated(await ordersService.list(1, undefined, undefined, Number(id)));
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
      }
    }
    fetchData();
  }, [id]);

  const optionsType = [
    { value: 1, label: "Tous les documents" },
    { value: 2, label: "Bon de livraison" },
    { value: 3, label: "Offre de prix" },
  ];

    const clientUsersActions: ActionType[] = [
        {
            label: "Nouvel utilisateur",
            onClick: () => setShowCreateClientUserModal(true),
            icon: <PlusIcon size={20} />,
        },
    ];

    const onClientUserAction = useCallback(async () => {
        setShowCreateClientUserModal(false);
        try {
            if (id) {
                const clientData = await clientsService.find(id);
                setClient(clientData)
            }
        } catch (e: any) {
            window.location.reload();
        }
    }, []);

  return (
    <div>
       {client && ordersPaginated &&
    <>
        <Breadcrumb previousRoute="/customers" previousBreadcrumb="Clients" currentPage="Détails clients"/>
        <BodyCard className={"relative mb-4 h-auto w-full pt-[100px]"}>
            <div className="from-gray-400 absolute inset-x-0 top-0 z-0 h-[120px] w-full bg-gradient-to-b" />
            <div className="flex grow flex-col overflow-y-auto">
              <div className="mb-4 h-[-15px] w-[40px]">
              </div>
              <div className="flex items-center">
                  <div className="w-[24px] h-[20px]">
                    <span
                      className={`w-full h-full items-center justify-center overflow-hidden select-none rounded-circle bg-fuschia-40`}
                    >
                      <span className="w-16 h-16 flex items-center justify-center bg-inherit text-grey-0 rounded-circle inter-small-semibold text-3xl">
                        {client.name.charAt(0)}
                      </span>
                    </span>
                  </div>
                </div>
                <br/><br/>
              <div className="flex items-center justify-between">
                <h1 className="inter-xlarge-semibold text-grey-90 max-w-[50%] truncate">
                  {client.name}
                </h1>
              </div>
            </div>
            <div className="mt-6 flex space-x-6 divide-x">
              <div className="flex flex-col">
                <div className="inter-smaller-regular text-grey-50 mb-1">
                  Siret
                </div>
                <div>{client.siret}</div>
              </div>
              <div className="flex flex-col pl-6">
                <div className="inter-smaller-regular text-grey-50 mb-1">Code</div>
                <div className="max-w-[200px] truncate">
                  {client.code}
                </div>
              </div>
              <div className="flex flex-col pl-6">
                <div className="inter-smaller-regular text-grey-50 mb-1">
                  Agence
                </div>
                <div>{client.agencie?.name}</div>
              </div>
              <div className="flex flex-col pl-6">
                <div className="inter-smaller-regular text-grey-50 mb-1">
                  Commandes
                </div>
                <div>{ordersPaginated.total}</div>
              </div>
              <div className="h-100 flex flex-col pl-6">
                <div className="inter-smaller-regular text-grey-50 mb-1">Actif</div>
                <div className="h-50 flex items-center justify-center">
                    <StatusIndicator
                        variant={client?.status ? "success" : "danger"}
                        title={client?.status ? "Actif" : "Inactif"}
                    />
                </div>
              </div>
            </div>
        </BodyCard>
    </>
       }
      <Section className="mb-5" title="Utilisateurs" forceDropdown actions={clientUsersActions}>
        <UsersTableCustomer
            users={client && client.users ? client.users : []}
            isLoading={loading}
            clientId={id}
            onClientUserAction={onClientUserAction}
        />
      </Section>


      <Section className="mb-5" title="Documents" forceDropdown>
        {client && <DocumentsTableCustomer clientId={client.id}/>}
      </Section>

      <Section className="mb-5"title={`Commandes (${ordersPaginated?.total})`} forceDropdown>
        {/*<OrdersTable orders={ordersPaginated?.data} isLoading={loading} />*/}
        {client &&<OrdersTableCustomer clientId={Number(client.id)} />}
      </Section>
      {showCreateClientUserModal &&
        <ModalClientUser
            handleClose={() => setShowCreateClientUserModal(false)}
            context="creation"
            clientId={id}
            afterSubmit={onClientUserAction}
        />
      }
    </div>
  );
}

type DetailDatasType = {
  label: string;
  value: string;
};

type DetailsProps = {
  datas: Array<DetailDatasType>;
};

const Details = ({ datas }: DetailsProps) => {
  return (
    <div className="flex flex-col mt-large grow">
      <div className="flex mt-6 space-x-6 divide-x">
      
        {datas.map((data: DetailDatasType, index: number) => (
          <div
            key={`details-command-${data.label}-${index}`}
            className={`flex-col ${index > 0 ? "pl-6" : ""}`}
          >
            <div className="inter-smaller-regular text-grey-50 mb-1">
              {data.label}
            </div>
            <span className="text-grey-90 gap-x-1 flex items-center">
              {data?.value ?? "-"}
            </span>    
          </div>
        ))}
      </div>
    </div>
  );
};

type DetailsClientProps = {
  client: Client;
  nbOrders : number;
};

const DetailsClient = ({ client, nbOrders}: DetailsClientProps) => {
  return (
    <div className="flex flex-col mt-large grow">  
      <Details
        datas={[
          {
            label: "Siret",
            value: client.siret,
          },
          {
            label: "code",
            value: client.code,
          },
          {
            label: "Agence",
            value: client.agencie_id,
          },
         {
            label: "Commandes",
            value: nbOrders.toString(),
          },
        
          {
            label: "Actif",
            value: client?.disabled ? "Inactif" : "Actif"
          },
        ]}
      />
    </div>
  );
};



{/*const OrdersTable = ({ orders, isLoading }: OrdersProps) => {
const [tmpSearch, setTmpSearch] = useState<string>();*/}

  





