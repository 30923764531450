export class DataPaginate{
    current_page: number
    data: any[] = [];
    results: any[] = [];
    first_page_url: string;
    from: number;
    last_page: number;
    last_page_url: string;
    next_page_url: string|null;
    path: string;
    per_page: number;
    prev_page_url?: string|null;
    to: number;
    total: number;

    constructor(data: any, fromElastic = false){
        if (fromElastic) {
            this.current_page = data.page;
            this.last_page = data.total === 10000 ? 100 : Math.ceil(data.total / data.page_size);
            this.next_page_url = this.current_page < this.last_page ? "random string" : null;
            this.prev_page_url = this.current_page > 1 ? "random string" : null;
            this.per_page = data.page_size;
            this.to = this.current_page * this.per_page;
            this.from = (this.current_page * this.per_page) - 9;
        } else {
            this.current_page = data.current_page;
            this.last_page = data.last_page;
            this.next_page_url = data.next_page_url;
            this.prev_page_url = data.prev_page_url;
            this.per_page = data.per_page;
            this.to = data.to;
            this.from = data.from ?? data.limit;
        }

        this.data = data.data;
        this.results = data.results;
        this.first_page_url = data.first_page_url;
        this.last_page_url = data.last_page_url;
        this.path = data.path;
        this.total = data.total;
    }

    setData(data: any){
        this.data = data;
    }
}