import {useEffect, useState} from "react";
import { Controller, useForm } from "react-hook-form";
import Button from "../../../../../components/fundamentals/button";
import Modal from "../../../../../components/fundamentals/modal";
import useNotification from "../../../../../hooks/use-notification";
import { ETypeModalImport } from "../ProductsPage";
import FileUploadField from "../../../../../components/form/file-upload-field/FileUploadField";
import FileSummary from "../../../../../components/fundamentals/file-summary/FileSummary";
import DownloadIcon from "../../../../../components/fundamentals/icons/download-icon";
import FileSaver from "file-saver";
import productsService from "../../../../../services/products.service";
import { EErrors } from "../../../../../models/utils/ErrorsHelper";
import flowsService from "../../../../../services/flows.service";
import {Flow} from "../../../../../models/Flow";

interface IProps {
  open: boolean;
  onClose: () => void;
  type: ETypeModalImport;
  refresh?: () => void;
}

type ModalImportFormData = {
  file: File;
};

type ModalImportFlows = {
  [type: string]: {
    flow: Flow
    size: number
  }
}

export default function ModalImport({
  open,
  type,
  onClose,
  refresh,
}: IProps) {
  const {
    formState: { isValid, isDirty, errors }, 
    control,
    handleSubmit,
    getValues,
    setError,
    clearErrors
  } = useForm<ModalImportFormData>({mode: 'onChange'});
  const [isLoading, setIsLoading] = useState<boolean>();
  const [flows, setFlows] = useState<ModalImportFlows|null>(null);
  const notification = useNotification();

  useEffect(() => {
    (async () => {
      if (!type) return;

      const characteristicsImportFlow = await flowsService.getSampleCharacteristicsImportFlow();

      const res = await fetch(characteristicsImportFlow.url_file);

      const file = await res.blob();

      setFlows({...flows, [type]: {flow: characteristicsImportFlow, size: file.size}});
    })()
  }, [type]);

  const submit = async () => {
    try {
      setIsLoading(true);
      await productsService.importFile(
        getValues("file"), 
        type === ETypeModalImport.CHARACTERISTICS
          ? 'details'
          : 'documents'
      )
      notification(
        "Succès",
        `Les ${type} ont été modifiés`,
        "success"
      );
      onClose();
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      notification(
        "Erreur",
        `Impossible de modifier les ${type}`,
        "error"
      );
    }
  };

  return (
    <Modal open={open} handleClose={onClose} isLargeModal>
      <Modal.Body>
        <Modal.Header handleClose={onClose}>
          <div>
            <h1 className="inter-xlarge-semibold">
              Import {type}
            </h1>
          </div>
        </Modal.Header>
        <Modal.Content>
          <div>
            <h2 className="inter-base-semibold mb-2xsmall">
              Import {type}
            </h2>
            <p className="inter-small-regular text-grey-50">
              Grâce aux importations, vous pouvez ajouter ou mettre à jour les {type} des produits. Pour les mettre à jour, vous devez définir un id existant dans les colonnes id du produit. Si la valeur n'est pas définie, les {type} ne seront pas ajoutées. Une confirmation vous sera demandée avant l'importation.
            </p>
            <Controller
              name="file"
              // defaultValue={promotion?.end_date.toDateString() ?? new Date().toDateString()}
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <FileUploadField
                    className="my-6"
                    text={value ? 
                        <span className="flex flex-col items-center my-8">
                          <span className="inter-base-regular">
                            Votre fichier :{" "}
                            <span className="text-blue-70">{value.name}</span>
                          </span>
                        </span>
                      :
                      <span className="flex flex-col items-center my-6">
                        <span className="inter-base-regular">
                          Déposer votre fichier ici, ou{" "}
                          <span className="text-blue-70">cliquez pour parcourir</span>
                        </span>
                        <span className="inter-small-regular">
                          Seulement les fichiers .csv sont pris en charge
                        </span>
                      </span>
                    }
                    onFileChosen={(file: File[], error?: EErrors) => {
                      clearErrors()
                      onChange(file[0])
                      if (error) {
                        setError('file', { type: 'custom', message: error });
                      }
                    }} 
                    filetypes={['text/csv']}
                    errorMessage={`Erreur: ${errors.file?.message}`}
                  />
                );
              }}
            />
            {(flows && flows[type]) &&
                <>
                  <h2 className="inter-base-semibold mb-2xsmall">
                    Vous ne savez pas comment organiser votre liste ?
                  </h2>
                  <p className="inter-small-regular text-grey-50">
                    Téléchargez le modèle ci-dessous pour vous assurer que vous suivez le bon format.
                  </p>
                  <FileSummary
                      name={"Fichier.csv"}
                      size={flows[type].size}
                      action={
                        <DownloadIcon
                            className="cursor-pointer"
                            stroke="#9CA3AF"
                            onClick={() => {
                              FileSaver.saveAs(flows[type].flow.url_file, `fichier.csv`);
                            }}
                        />
                      }
                  />
                </>
            }
          </div>
        </Modal.Content>
        <Modal.Footer>
          <div className="flex items-center justify-end w-full gap-x-xsmall">
            <Button
              variant="secondary"
              size="small"
              type="reset"
              onClick={onClose}
            >
              Annuler
            </Button>
            <Button
              variant="primary"
              size="small"
              loading={isLoading}
              onClick={handleSubmit(submit)}
              disabled={!isValid || !isDirty}
            >
              Importer
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  );
}
