import axios from "axios";
import { Analytics } from "../models/Analytics";
import DateHelper from "../models/utils/DateHelper";
import { TopViewAnalytics } from "../models/TopViewAnalytics";
import { OrderTunnelAnalytics } from "../models/OrderTunnelAnalytics";
import moment from "moment";

function get(startDate?: Date, endDate?: Date, pageName?: string, type?: string, source?: string): Promise<Analytics|null> {
  let params = ``;

  if (startDate) {
      params = `?start_date=${moment(startDate).format('YYYY-MM-DD')}`
  }
  if (endDate) {
      params = `${params ? `${params}&` : '?'}end_date=${moment(endDate).format('YYYY-MM-DD')}`
  }
  if (pageName) {
      params = `${params ? `${params}&` : '?'}filter=${pageName}`
  }
  if (type) {
    params = `${params ? `${params}&` : '?'}subset=${type}`
  }
  if (source) {
    params = `${params ? `${params}&` : '?'}source=${source}`
  }
  

  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_API_URL}dashboard${params}`)
      .then((response) => {
        resolve(new Analytics(response.data))
      })
      .catch((error) => {
        reject({status: 500, message: "Service unavailable"});
      })
  })
}

function getTopViewsBy(startDate: Date | null, endDate: Date | null, orderBy: string = 'views', nbItems: number = 20, pageName?: string, source?: string): Promise<TopViewAnalytics[]|null> {
  
  let params = `?nb_items_per_page=${nbItems}`;
  
  if (orderBy) {
      params = `${params}&top=${orderBy}`
  }
  if (startDate) {
      params = `${params}&start_date=${moment(startDate).format('YYYY-MM-DD')}`
  }
  if (endDate) {
      params = `${params}&end_date=${moment(endDate).format('YYYY-MM-DD')}`
  }
  if (pageName) {
      params = `${params}&filter=${pageName}`
  }
  if (source) {
    params = `${params}&source=${source}`
  }

  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_API_URL}dashboard/views${params}`)
      .then(response => {
        resolve(response.data.map((view: any) =>  new TopViewAnalytics(view)))
      })
      .catch((error) => {
        reject({status: 500, message: "Service unavailable"});
      })
  })
}

function getOrderTunnel(startDate: Date | null, endDate: Date | null, source?: string): Promise<OrderTunnelAnalytics[]|null> {
  let params = ``;

  if (startDate) {
      params = `?start_date=${moment(startDate).format('YYYY-MM-DD')}`
  }
  if (endDate) {
      params = `${params ? `${params}&` : '?'}end_date=${moment(endDate).format('YYYY-MM-DD')}`
  }
  if (source) {
      params = `${params ? `${params}&` : '?'}source=${source}`
  }

  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_API_URL}dashboard/order-tunnel${params}`)
      .then(response => {
        resolve(response.data.map((step: any) =>  new OrderTunnelAnalytics(step)))
      })
      .catch((error) => {
        reject({status: 500, message: "Service unavailable"});
      })
  })
}

export default {
  get,
  getTopViewsBy,
  getOrderTunnel,
};
