import Modal from "../../../../../components/fundamentals/modal";
import ModalH1TitleLayout from "../../../../../components/fundamentals/modal/layouts/header/ModalH1TitleLayout";
import Modal2ButtonsLayout from "../../../../../components/fundamentals/modal/layouts/footer/Modal2ButtonsLayout";
import {useCallback, useEffect, useMemo, useState} from "react";
import InputField from "../../../../../components/form/input";
import {User} from "../../../../../models/User";
import userService from "../../../../../services/users.service";
import useNotification from "../../../../../hooks/use-notification";

type ModalClientUserProps = {
    handleClose: () => void,
    user?: User,
    context: "creation"|"edit",
    clientId: string|undefined,
    afterSubmit: () => void,
}

export type ClientUserForm = {
    firstname: string,
    lastname: string,
    email: string,
    function: string,
    phoneNumber: string
    mobileNumber: string,
}

// Take undefined as default value to respect <InputError> component format
type ClientUserFormError = {
    [key in keyof ClientUserForm]: ClientInputError|undefined;
};

type ClientInputError = {
    [key: string]: {message: string};
};

type ClientUserFormInputValidationHelper = {
    email: {
        value: string,
        validation: boolean,
        notEmptyCheck: boolean,
        message: string,
    },
    phoneNumber: {
        value: string,
        validation: boolean,
        notEmptyCheck: boolean,
        message: string,
    },
    mobileNumber: {
        value: string,
        validation: boolean,
        notEmptyCheck: boolean,
        message: string,
    },
}

const initialClientUserFormError: ClientUserFormError = {
    firstname: undefined,
    lastname: undefined,
    email: undefined,
    function: undefined,
    phoneNumber: undefined,
    mobileNumber: undefined,
}

const initialFormValues: ClientUserForm = {
    firstname: "",
    lastname: "",
    email: "",
    function: "",
    phoneNumber: "",
    mobileNumber: "",
}

const requiredInputs: string[] = ["firstname", "lastname", "email"];

function ModalClientUser({handleClose, user, context, clientId, afterSubmit}: ModalClientUserProps) {

    const [formValues, setFormValues] = useState<ClientUserForm>(initialFormValues);
    const [formErrors, setFormErrors] = useState<ClientUserFormError>(initialClientUserFormError);
    // Triggered at submit
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const notificationToast = useNotification();

    const onSubmit = useCallback(async () => {
        try {
            setIsLoading(true);

            /* Update is currently not implemented due to ERP constraints
             * function would have to be implemented in userService if needed
             */
            context === "creation"
                ? await userService.create(formValues, clientId)
                : console.log("update");

            notificationToast(
                "Succès",
                context === "creation" ? "L'utilisateur a bien été crée" : "L'utilisateur a bien été modifié",
                "success"
            );

            afterSubmit();
        } catch (e: any) {
            if (e.status) {
                setIsLoading(false);

                let errors = {...e.errors, ...{}};

                // Mimic InputError type
                for (const field in errors) {
                    errors[field] = {[field]: {message: errors[field].join('')}};
                }

                setFormErrors({...formErrors, ...errors});
            }
        }
    }, [formValues, context, clientId, formErrors, notificationToast, afterSubmit]);

    const onChange = useCallback((key: keyof ClientUserForm, value: string) => {
        if (key === "phoneNumber" || key === "mobileNumber") {
            if (isNaN(Number(value))) {
                return;
            }
        }

        setFormValues({...formValues, [key]: value});
    }, [formValues]);

    const isMailValid = useMemo<boolean>(() => {
        return formValues.email === "" ? true : !!formValues.email.match(/.+@.+\.[a-z]{2,4}/);
    }, [formValues.email]);

    const isPhoneNumberValid = useMemo<boolean>(() => {
        if (formValues.phoneNumber === "") return true;

        return userService.checkPhoneNumberFormat(formValues.phoneNumber);
    }, [formValues.phoneNumber]);

    const isMobileNumberValid = useMemo<boolean>(() => {
        if (formValues.mobileNumber === "") return true;

        return userService.checkPhoneNumberFormat(formValues.mobileNumber);
    }, [formValues.mobileNumber]);

    /* Helper object to ensure specific fields validation thanks to memoized values
     * `notEmptyCheck` is used for global form validation and enable/disable of submit button
     * `message` is used for local input validation and display of error
     */
    const fieldsValidation: ClientUserFormInputValidationHelper = useMemo(() => {
        return {
            email: {
                value: formValues.email,
                validation: isMailValid,
                notEmptyCheck: false,
                message: "Email invalide",
            },
            phoneNumber: {
                value: formValues.phoneNumber,
                validation: isPhoneNumberValid,
                notEmptyCheck: true,
                message: "Numéro invalide",
            },
            mobileNumber: {
                value: formValues.mobileNumber,
                validation: isMobileNumberValid,
                notEmptyCheck: true,
                message: "Numéro invalide",
            }
        }
    }, [
        formValues.email,
        formValues.phoneNumber,
        formValues.mobileNumber,
        isMailValid,
        isPhoneNumberValid,
        isMobileNumberValid
    ]);

    const isFormValid = useMemo<boolean>(() => {
        let result: boolean;

        // Extract values of required keys and check if value is empty
        result = !Object.entries(formValues)
            .filter(([key, value]) => requiredInputs.includes(key))
            .map(([key, value]) => value)
            .some(value => value === "");

        // For fields that need advanced validation, we check the boolean that told us if its valid
        Object.entries(fieldsValidation).forEach(([fieldName, validationOption]) => {
            // If those fields are empty it's considered as valid because they're not required
            if (validationOption.notEmptyCheck) {
                if (result && validationOption.value !== "") {
                    result = validationOption.validation
                }
                return;
            }

            if (result) {
                result = validationOption.validation
            }
        })

        return result;
    }, [formValues, fieldsValidation]);

    /* This useEffect allow forms to show fields error to user at typing
    * If value is undefined it means that we have no error so we "reset" the error
    * with a value that InputError will interpret
    */
    useEffect(() => {
        // We generate a partial ClientUserFormError for specific fields that has to be validated at typing
        const partialFormErrors: Partial<ClientUserFormError> = {};

        // We set or reset the error for a field based on fieldValidation.validation state (true or false)
        Object.entries(fieldsValidation).forEach(([fieldName, validationOptions]) => {
            validationOptions.validation
                ? partialFormErrors[fieldName as keyof ClientUserForm] =  undefined
                : partialFormErrors[fieldName as keyof ClientUserForm] = {[fieldName]: {message: validationOptions.message}};
        })

        keepBackendErrors(formErrors, partialFormErrors, fieldsValidation);

        const completeFormErrors = {...formErrors, ...partialFormErrors};

        // We only set the new ClientUserFormError if we found a difference
        if (JSON.stringify(formErrors) !== JSON.stringify(completeFormErrors)) {
            setFormErrors({...formErrors, ...partialFormErrors});
        }
    }, [formErrors, fieldsValidation]);

    useEffect(() => {
        if (!user) {
            return;
        }

        const userFormValues: ClientUserForm = {
            firstname: user.firstname ?? "",
            lastname: user.lastname ?? "",
            email: user.email ?? "",
            function: user.function ?? "",
            phoneNumber: user.phoneNumber ?? "",
            mobileNumber: user.mobileNumber ?? "",
        };

        setFormValues(userFormValues);
    }, [user]);

    return (
        <Modal handleClose={handleClose}>
            <Modal.Body>
                <Modal.Header handleClose={handleClose}>
                    <ModalH1TitleLayout title={context === "creation" ? "Nouvel utilisateur" : "Modifier l'utilisateur"}/>
                </Modal.Header>
                <Modal.Content>
                    <div className="flex flex-col gap-y-6">
                        <div className="flex items-center gap-x-base">
                            <InputField
                                name="firstname"
                                label="Prénom"
                                required
                                placeholder="Prénom"
                                value={formValues.firstname}
                                onChange={(evt) => onChange("firstname", evt.target.value)}
                                errors={formErrors.firstname}
                            />
                            <InputField
                                name="lastname"
                                label="Nom"
                                required
                                placeholder="Nom"
                                value={formValues.lastname}
                                onChange={(evt) => onChange("lastname", evt.target.value)}
                                errors={formErrors.lastname}
                            />
                        </div>
                        <div className="flex items-center gap-x-base">
                            <InputField
                                name="email"
                                label="Email"
                                required
                                placeholder="nom@exemple.com"
                                value={formValues.email}
                                onChange={(evt) => onChange("email", evt.target.value)}
                                errors={formErrors.email}
                            />
                            <InputField
                                name="function"
                                label="Fonction"
                                placeholder="Fonction"
                                value={formValues.function}
                                onChange={(evt) => onChange("function", evt.target.value)}
                                errors={formErrors.function}
                            />
                        </div >
                        <div className="flex items-center gap-x-base">
                            <InputField
                                name="phoneNumber"
                                label="Téléphone"
                                placeholder="01..."
                                value={formValues.phoneNumber}
                                onChange={(evt) => onChange("phoneNumber", evt.target.value)}
                                errors={formErrors.phoneNumber}
                            />
                            <InputField
                                name="mobileNumber"
                                label="Mobile"
                                placeholder="06..."
                                value={formValues.mobileNumber}
                                onChange={(evt) => onChange("mobileNumber", evt.target.value)}
                                errors={formErrors.mobileNumber}
                            />
                        </div >
                    </div>
                </Modal.Content>
                <Modal.Footer>
                    <Modal2ButtonsLayout
                        position="right"
                        discardButtonLabel="Annuler"
                        discardButtonHandler={handleClose}
                        submitButtonLabel="Enregistrer"
                        submitButtonHandler={onSubmit}
                        showSubmitButtonLoadingSpinner={isLoading}
                        disableSubmitButton={!isFormValid}
                    />
                </Modal.Footer>
            </Modal.Body>
        </Modal>
    )
}

/* The purpose of this function is to compare errors before getting changed in useEffect and watch if an error
 * has been sent by backend and preserve it if it's the case.
 */
function keepBackendErrors(
    formErrors: ClientUserFormError,
    partialFormErrors: Partial<ClientUserFormError>,
    fieldsValidation: ClientUserFormInputValidationHelper
) {
    /* We retrieve concrete errors (errors that aren't undefined) and we return object value without the keu
     * Because the value is also an object that has the concerned field as key
     */
    const initialConcreteErrorsArray = Object.entries(formErrors)
        .map(([fieldName, error]) => error)
        .filter(error => error);

    // We transform the array into an object for further comparisons
    let initialConcreteErrors: {[key: string]: any} = {};

    initialConcreteErrorsArray.forEach(error => {
        initialConcreteErrors = {...initialConcreteErrors, ...error};
    })

    /* If an error from formErrors hasn't been yet defined in partialFormErrors and the error message
     * from initialConcreteErrors is different from fieldValidation<fieldName>.message,
     * it means that is an error sent by the backend, so we change partialFormErrors<fieldName>
     * with the initial error to avoid crushing it.
     * Example : In already email used case, the local validation don't see an error because email format is correct
     * so partialFormErrors.email is undefined but the backend says that is already used, we change partialFormErrors
     * to take into account this backend error
     */
    Object.entries(initialConcreteErrors).forEach(([key, value]) => {
        if (!partialFormErrors[key as keyof ClientUserFormError]
            && value.message !== fieldsValidation[key as keyof ClientUserFormInputValidationHelper].message) {
            partialFormErrors[key as keyof ClientUserFormError] = {[key]: initialConcreteErrors[key]};
        }
    });
}

export default ModalClientUser;