import { Agency } from "./Agency";
import { User } from "./User";

export enum ClientStatus {
  ACTIF = "Actif",
  INACTIF ="Inactif",
}

export class Client {
  id: string;
  name: string;
  siret: string;
  code: string;
  last_date_update: Date;
  agencie_id: string;
  agencie?: Agency ;
  users: User[] | null;
  status: ClientStatus;
  disabled: boolean;
  createdAt: Date;
  updatedAt: Date;

  constructor(data: any) {
    this.id = data.id;
    this.name = data.name;
    this.siret = data.siret;
    this.code = data.code;
    this.last_date_update = new Date(data.last_date_update);
    this.agencie_id = data.agencie_id;
    //this.agencie_name = data.agencie.name;
    if(data.agencie){
      this.agencie=new Agency(data.agencie)
    }
    this.status = data.disable? ClientStatus.INACTIF : ClientStatus.ACTIF;
    this.disabled = data.disable
    this.users = data.users
      ? data.users.map((user: object) => new User(user))
      : null;
    this.createdAt = new Date(data.createdAt);
    this.updatedAt = new Date(data.updatedAt);
    this.id = data.id;
  }
}
