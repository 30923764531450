import * as React from "react"
import IconProps from "../types/icon-type"

const CalendarIcon: React.FC<IconProps> = ({
    ...attributes
  }) => {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        {...attributes}
      >
        <path
          stroke="#667085"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.667}
          d="M17.5 8.333h-15m10.833-6.667v3.333M6.667 1.666v3.333M6.5 18.333h7c1.4 0 2.1 0 2.635-.273a2.5 2.5 0 0 0 1.092-1.092c.273-.535.273-1.235.273-2.635v-7c0-1.4 0-2.1-.273-2.635a2.5 2.5 0 0 0-1.092-1.093c-.535-.272-1.235-.272-2.635-.272h-7c-1.4 0-2.1 0-2.635.272a2.5 2.5 0 0 0-1.093 1.093C2.5 5.232 2.5 5.933 2.5 7.333v7c0 1.4 0 2.1.272 2.635a2.5 2.5 0 0 0 1.093 1.092c.535.273 1.235.273 2.635.273Z"
        />
      </svg>
    )
  }
export default CalendarIcon
