import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Button from "../../../../components/fundamentals/button";
import Modal from "../../../../components/fundamentals/modal";
import { Product } from "../../../../models/Product";
import ProductTable, { columnProduct } from "../../products/products-page/product-table/ProductTable";

interface IProps {
  open: boolean;
  onClose: () => void;
  productsSelected: Product[]
  save: (products: Product[]) => void;
}

export default function ModalProducts({
  open,
  onClose,
  productsSelected,
  save
}: IProps) {

  const [selected, setSelected] = useState<Product[]>([...productsSelected]);

  function handleClose() {
    setSelected(productsSelected)
    onClose()
  }

  return (
    <Modal open={open} handleClose={handleClose} isLargeModal>
      <Modal.Body>
        <Modal.Header handleClose={handleClose}>
          <div>
            <h1 className="inter-xlarge-semibold">
              Choisir des produits
            </h1>
            <p>{selected.length} produits séléctionné(s)</p>
          </div>
        </Modal.Header>
        <Modal.Content>
          <ProductTable
            selected={selected}
            setSelected={setSelected}
            columns = {[columnProduct.SELECT, columnProduct.NAME, columnProduct.PRICE, columnProduct.EAN, columnProduct.STOCK]}
            useElasticSearch={true}
          />
        </Modal.Content>
        <Modal.Footer>
          <div className="flex items-center justify-end w-full gap-x-xsmall">
            <Button
              variant="secondary"
              size="small"
              type="reset"
              onClick={handleClose}
            >
              Annuler
            </Button>
            <Button
              variant="primary"
              size="small"
              onClick={() => save(selected)}
              disabled={selected.length == 0}
            >
              Enregister
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  );
}
