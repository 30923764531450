import { useEffect, useState } from "react";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { Analytics } from "../../../../models/Analytics";

interface IProps {
    analytics: Analytics
}
export default function AnalyticsGraph({analytics}: IProps) {
    const [lineChartData, setLineChartData] = useState<any[]>([])

    useEffect(() => {
        if (analytics.subsets){
            setLineChartData(Object.entries(analytics.subsets).map(([key, value]) => { 
                return {
                    name: key,
                    Visiteurs: value.visitors,
                    Visites: value.visits,
                    Vues: value.views
                }
            }))
        }
    }, [analytics])
    
  return (
    <div className="flex flex-col grow">
        <div className="flex flex-row mb-10 ml-6 gap-2">
            <div className="flex align-center justify-between flex-col mr-2">
                <span style={{color: "#33488a"}} className="inter-xlarge-semibold">{analytics.visitors}</span>
                <span className="inter-large-semibold">Visiteurs</span>
            </div>
            <div className="flex align-center justify-between flex-col mr-2">
                <span style={{color: "#3bad03"}} className="inter-xlarge-semibold">{analytics.visits}</span>
                <span className="inter-large-semibold">Visites</span>
            </div>
            <div className="flex align-center justify-between flex-col mr-2">
                <span style={{color: "#ffae00"}} className="inter-xlarge-semibold">{analytics.views}</span>
                <span className="inter-large-semibold">Vues</span>
            </div>
        </div>
        <ResponsiveContainer width="100%" height={450}>    
            <LineChart data={lineChartData} layout={'horizontal'}>
                <CartesianGrid strokeDasharray="10 10" />
                <XAxis dataKey="name"/>
                <YAxis interval={0} />
                <Tooltip />
                <Legend verticalAlign="top" height={36} />
                <Line type="monotone" strokeWidth={2} dataKey="Visiteurs" stroke="#33488a" />
                <Line type="monotone" strokeWidth={2} dataKey="Visites" stroke="#3bad03" />
                <Line type="monotone" strokeWidth={2} dataKey="Vues" stroke="#ffae00" />
            </LineChart>
        </ResponsiveContainer>
    </div>
  );
}
