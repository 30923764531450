import axios from "axios";
import { User } from "../models/User";
import { DataPaginate } from "../models/utils/DataPaginate";
import { IFilter } from "../models/utils/filter/FilterType.interface";
import { convertFiltersToQuery } from "./utils/serviceHelper";
import {ClientUserForm} from "../pages/logged/clients/client-detail-page/modal-client-user/ModalClientUser";

function list(page: number, search?: string, filters?: IFilter[]) {
    let params = `?page=${page}`;
    if (search) {
      params = `${params}&search=${search}`;
    }
    if (filters) {
      params = `${params}${convertFiltersToQuery(filters)}`;
    }
  
    return axios
      .get(`${process.env.REACT_APP_API_URL}admins/users${params}`)
      .then((response: any) => {
        const dataPaginate = new DataPaginate(response.data);
        dataPaginate.setData(dataPaginate.data.map((item) => new User(item)));
  
        return dataPaginate;
      });
  }
  
  function find(id: string) {
    return axios
      .get(`${process.env.REACT_APP_API_URL}admins/clients/${id}`)
      .then((response) => new User(response.data));
  }
  
  function patchDisable(id: any, value: boolean) {
    return axios
      .patch(
        `${process.env.REACT_APP_API_URL}admins/users/${id}/disable?value=${value}`
      )
      .then((response) => new User(response.data));
  }

  function update(id: number, body: any){
    return axios.put(`${process.env.REACT_APP_API_URL}admins/${id}`, body)
      .then(response => response.data)
  }

function create(userFormValues: ClientUserForm, clientId: any): Promise<void> {
    return new Promise((resolve, reject) => {
        axios.post(`${process.env.REACT_APP_API_URL}admins/clients/${clientId}/users`, formatClientUserFormForApi(userFormValues))
            .then(response => {
                resolve()
            })
            .catch(error => {
                if (error.response) {
                    console.log(error.response, error.response.status, 'err')
                    switch (error.response.status) {
                        case 422:
                            // API doesn't return same variable in already used email, so we create an array if we have a single error
                            if (error.response.data.error) {
                                reject({status: error.response.status, errors: {email: ['Cet email est déjà utilisé']}})
                            }

                            // Missing fields
                            reject({status: error.response.status, errors: error.response.data.errors});
                    }
                }
            })
    })
}

function formatClientUserFormForApi(clientUserForm: ClientUserForm) {
    const apiExpectedFormat: {[key: string]: {required: boolean, fields: string[]}} = {
        name: {
            required: true,
            fields: ["firstname", "lastname"]
        },
        email: {
            required: true,
            fields: ["email"]
        },
        function: {
            required: false,
            fields: ["function"]
        },
        phone_number: {
            required: false,
            fields: ["phoneNumber"]
        },
        mobile_number: {
            required: false,
            fields: ["mobileNumber"]
        }
    }

    let clientUserFormatApi = {};

    for (const [fieldName, options] of Object.entries(apiExpectedFormat)) {
        if (options.required) {
            switch (options.fields.length) {
                case 1:
                    clientUserFormatApi = {
                        ...clientUserFormatApi,
                        [fieldName]: clientUserForm[options.fields[0] as keyof ClientUserForm]
                    };
                    break;
                default:
                    /* For a field expected by the API we retrieve the associated fields in ClientUserForm
                    *  We directly use reduce() on fields waited for API because we're sure to find a value
                    *  in the ClientUserForm since they're required
                    */
                    const fieldValue = options.fields.reduce(
                        (accumulator, currentValue) => {
                            return accumulator === ''
                                ? `${clientUserForm[currentValue as keyof ClientUserForm]}`
                                : `${accumulator} ${clientUserForm[currentValue as keyof ClientUserForm]}`
                        },
                        ''
                    );

                    clientUserFormatApi = {...clientUserFormatApi, [fieldName]: fieldValue}
            }
            continue;
        }

        switch (options.fields.length) {
            case 1:
                if (clientUserForm[options.fields[0] as keyof ClientUserForm]) {
                    clientUserFormatApi = {
                        ...clientUserFormatApi,
                        [fieldName]: clientUserForm[options.fields[0]as keyof ClientUserForm]
                    }
                }
                break;
            default:
                /* For a field expected by the API we retrieve the associated fields in ClientUserForm
                 * and we filter them to eliminate the empty ones
                 * first filter() keep the ClientUserForm fields that are associated to the API field
                 * map() returns only value
                 * second() filter eliminates the empty ones.
                 * join() create a single value to use in API call
                 */
                const fieldValue = Object.entries(clientUserForm)
                    .filter(([key, value]) => options.fields.includes(key))
                    .map(([key, value]) => value)
                    .filter(value => value !== "")
                    .join(' ');

                clientUserFormatApi = {...clientUserFormatApi, [fieldName]: fieldValue};
        }
    }

    return clientUserFormatApi;
}

/* Check if phone number has right national format (France format) */
function checkPhoneNumberFormat(phoneNumber: string): boolean {
    return phoneNumber.startsWith('0') && phoneNumber.length === 10
}

// delete is a reserved keyword
function deleteClientUser(userId: number, clientId: string): Promise<void> {
    return new Promise((resolve, reject) => {
        axios.delete(`${process.env.REACT_APP_API_URL}admins/clients/${clientId}/users/${userId}`)
            .then(response => {
                resolve();
            })
            .catch(error => {
                if (error.response) {
                    switch (error.response.status) {
                        case 404:
                            reject({status: error.response.status, message: "Utilisateur inconnu"});
                            break;
                        default:
                            reject({status: 500, message: "Une erreur inattendue a eu lieu, réessayez"})
                    }

                }
            })
    });
}
  
  export default {
    list,
    find,
    patchDisable,
    update,
    create,
    deleteClientUser,
    checkPhoneNumberFormat,
  };
  