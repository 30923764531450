import clsx from "clsx"
import * as React from "react"
import { Link } from "react-router-dom"
import Actionables from "../../../../../components/fundamentals/actionables/actionables"
import Button from "../../../../../components/fundamentals/button"
import ListIcon from "../../../../../components/fundamentals/icons/list-icon"
import MoreHorizontalIcon from "../../../../../components/fundamentals/icons/more-horizontal-icon"
import TileIcon from "../../../../../components/fundamentals/icons/tile-icon"
import ImagePlaceholder from "../../../../../components/fundamentals/image-placeholder/image-placeholder"
import StatusIndicator from "../../../../../components/fundamentals/status-indicator"
import { Product } from "../../../../../models/Product"

type IProps = {
  products?: Product[]
  toggleListView: () => void
}

const ProductList = ({
  products,
  toggleListView,
}: IProps) => {
  return (
    <>
      <div className="flex justify-end border-t border-b border-grey-20 py-2.5 pr-xlarge">
        <div className="inter-small-semibold text-grey-50 flex justify-self-end">
          <span
            onClick={toggleListView}
            className={clsx(
              "hover:bg-grey-5 cursor-pointer rounded p-0.5 text-grey-40"
            )}
          >
            <ListIcon size={20} />
          </span>
          <span
            className={clsx(
              "hover:bg-grey-5 cursor-pointer rounded p-0.5 text-grey-90"
            )}
          >
            <TileIcon size={20} />
          </span>
        </div>
      </div>
      <div className="grid grid-cols-5">
        {products?.map((product) => (
          <ProductTile key={`product_item_${product.id}`} product={product} />
        ))}
      </div>
    </>
  )
}

interface IPropsProductTile{
    product: Product;
}
const ProductTile = ({ product }: IPropsProductTile) => {

    

  return (
    <div className="p-base group rounded-rounded hover:bg-grey-5 flex-col">
      <div className="relative">
        <div
          className={clsx("rounded-base inline-block absolute top-2 right-2")}
        >
          <Actionables
            customTrigger={
              <Button
                variant="ghost"
                size="small"
                className="w-xlarge h-xlarge hidden-actions group-hover:opacity-100 focus-within:opacity-100 opacity-0 bg-grey-0"
              >
                <MoreHorizontalIcon size={20} />
              </Button>
            }
          />
        </div>
        <Link to={`${product.id}`}>
          {product.image_url ? (
            <img
              className="min-h-[230px] block object-cover rounded-rounded"
              src={product.image_url}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "/images/placeholder-product.svg";
              }}
            />
          ) : (
            <div className="min-h-[230px] flex items-center justify-center bg-grey-5 rounded-rounded">
              <ImagePlaceholder />
            </div>
          )}
          <div>
            <div className="mt-base flex items-center justify-between">
              <p className="inter-small-semibold text-grey-90 line-clamp-1 mr-3">
                {product.name}
              </p>
              -
            </div>
          </div>
        </Link>
      </div>
    </div>
  )
}

export default ProductList
