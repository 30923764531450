import { Product } from "./Product";

export class Promotion {
  _id: string;
  name: string;
  visible: boolean;
  order: number;
  end_date: Date;
  start_date: Date;
  image_url: string;
  nb_products: number;
  createdAt: Date;
  updatedAt: Date;
  id: number;
  products: Product[]

  constructor(data: any) {
    this._id = data._id;
    this.name = data.name;
    this.visible = data.visible;
    this.order = data.order;
    this.image_url = data.image_url;
    this.start_date = new Date(data.start_date);
    this.end_date = new Date(data.end_date);
    this.nb_products = data.nb_products;
    this.createdAt = new Date(data.createdAt);
    this.updatedAt = new Date(data.updatedAt);
    this.id = data.id;
    this.products = data.products;
  }
}
