import axios from "axios";
import { Flow } from "../models/Flow";
import { DataPaginate } from "../models/utils/DataPaginate";

const CHARACTERISTICS_IMPORT_FLOW_NAME = "import_products_details";

function list(page: number) {
  let params = `?page=${page}`;

  return axios
    .get(`${process.env.REACT_APP_API_URL}process${params}`)
    .then((response: any) => {
      const dataPaginate = new DataPaginate(response.data);
      dataPaginate.setData(
        dataPaginate.data.map((item) => new Flow(item))
      );

      return dataPaginate;
    });
}

function getSampleCharacteristicsImportFlow(): Promise<Flow> {
    return new Promise((resolve, reject) => {
        axios.get(`${process.env.REACT_APP_API_URL}process?type=import`)
            .then((response) => {
                const importProcess = response.data.data.find(
                    (process: any) => process.name === CHARACTERISTICS_IMPORT_FLOW_NAME && !process.message
                );

                if (!importProcess) {
                    throw {
                        response: {
                            status: 404,
                            data: {
                                message: "Process inconnu"
                            }
                        }
                    }
                }

                resolve(new Flow(importProcess))
            })
            .catch((error) => {
                switch (error.response.status) {
                    case 404:
                        reject({status: error.response.status, message: "Process inconnu"});
                        break;
                    default:
                        reject({status: error.response.status, message: "Veuillez contacter un administrateur"});
                }
            })
    })
}

export default {
  list,
  getSampleCharacteristicsImportFlow,
};
