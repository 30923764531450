import React, { useCallback } from "react"
import Collapsible from "react-collapsible"
import { NavLink } from "react-router-dom"

type SidebarMenuSubitemProps = {
  pageLink: string
  text: string
  disabled: boolean
}

type SidebarMenuItemProps = {
  pageLink: string
  text: string
  icon: JSX.Element
  triggerHandler: () => any
  subItems?: SidebarMenuSubitemProps[],
  SubItem?: any,
  disabled?: boolean,
  isHomePage?: boolean
}

const SidebarMenuItem: React.FC<SidebarMenuItemProps> = ({
    pageLink,
    icon,
    text,
    triggerHandler,
    subItems = [],
    SubItem,
    disabled,
    isHomePage = false,
}: SidebarMenuItemProps) => {

  const styles =
    `py-1.5 px-3 my-0.5 rounded-base flex text-grey-90 hover:bg-grey-10 items-center ${disabled ? 'cursor-not-allowed opacity-50' : ''}`
  const activeStyles = "bg-grey-10 text-blue-60"
  const classNameFn = useCallback(
    ({ isActive }: any) => {
      if (isHomePage && window.location.pathname === "/") {
        return `${styles} ${activeStyles}`
      }

       return isActive ? `${styles} ${activeStyles}` : styles
    }, [isHomePage]);

  const handleClick = (e: any) => {
    if(disabled) e.preventDefault();
  }

  return (
    <Collapsible
      transitionTime={150}
      transitionCloseTime={150}
      {...triggerHandler()}
      trigger={
        <NavLink className={classNameFn} to={pageLink} onClick={handleClick}>
          <span className="items-start">{icon}</span>
          <span className="ml-3">{text}</span>
        </NavLink>
      }
    >
      {subItems?.length > 0 &&
        subItems.map(({ pageLink, text }) => (
          <SubItem pageLink={pageLink} text={text} disabled={disabled}/>
        ))}
    </Collapsible>
  )
}

const SubItem: any = ({ pageLink, text, disabled }: SidebarMenuSubitemProps) => {
  const styles = "py-0.5 px-1 my-0.5 rounded-base flex hover:bg-grey-10"
  const activeStyles = "bg-grey-10 font-semibold"
  const classNameFn = useCallback(
    ({ isActive }: any) => (isActive ? `${styles} ${activeStyles}` : styles),
    []
  )

  const handleClick = (e: any) => {
    if(disabled) e.preventDefault();
  }

  return (
    <NavLink className={classNameFn} to={'/products'} onClick={handleClick} >
      <span className="text-grey-90 text-small ml-3">{text}</span>
    </NavLink>
  )
}

//SidebarMenuItem.SubItem = SubItem

export default SidebarMenuItem
