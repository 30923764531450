export class Category {
  _id: string;
  name: string;
  enable: boolean;
  order: number;
  parent_id: boolean;
  level: number;
  has_children: boolean;
  has_children_enabled: boolean;
  image_url: string;
  picto_url: string;
  createdAt: Date;
  updatedAt: Date;
  id: number;

  constructor(data: any) {
    this._id = data._id;
    this.name = data.name;
    this.enable = data.enable;
    this.order = data.order;
    this.parent_id = data.parent_id;
    this.level = data.level;
    this.has_children = data.has_children;
    this.has_children_enabled = data.has_children_enabled;
    this.image_url = data.image_url;
    this.picto_url = data.picto_url;
    this.createdAt = new Date(data.createdAt);
    this.updatedAt = new Date(data.updatedAt);
    this.id = data.id;
  }
}
