import {useCallback, useState} from "react";
import Actionables, {
  ActionType,
} from "../../../../../components/fundamentals/actionables/actionables";
import EditIcon from "../../../../../components/fundamentals/icons/edit-icon";
import Spinner from "../../../../../components/fundamentals/spinner";
import StatusSelector from "../../../../../components/fundamentals/status-selector";
import Table from "../../../../../components/fundamentals/table";
import { Promotion } from "../../../../../models/Promotion";
import DateHelper from "../../../../../models/utils/DateHelper";
import promotionsService from "../../../../../services/promotions.service";
import moment from "moment/moment";
import {getBase64FromImageUrl, getImageNameFromImageUrl} from "../../../../../services/utils/serviceHelper";
import {PromotionFormData} from "../../modal-promotion/ModalPromotion";
import useNotification from "../../../../../hooks/use-notification";
import TrashIcon from "../../../../../components/fundamentals/icons/trash-icon";
import useImperativeDialog from "../../../../../hooks/use-imperative-dialog";

interface IProps {
  promotion: Promotion;
  index: number;
  refresh: () => void;
  setPromotionEdit: () => void;
}
export default function PromotionRow({
  promotion,
  index,
  refresh,
  setPromotionEdit,
}: IProps) {
  const [isLoadingVisibility, setIsLoadingVisibility] = useState<boolean>();

  const notificationToast = useNotification();

  const deletePromotionDialog = useImperativeDialog();

  const onDeletePromotion = useCallback(async () => {
    const hasPromotionToBeDeleted = await deletePromotionDialog ({
      heading: "Supprimer la promotion",
      text: "Êtes-vous sûr de vouloir supprimer cette promotion"
    });

    /** Verification of clientId existence is needed by Typescript **/
    if (hasPromotionToBeDeleted) {
      try {
        await promotionsService.deletePromotion(promotion.id);

        notificationToast("Succès", "La promotion a été supprimée", "success");

        refresh();
      } catch (e: any) {
        if (e.status) {
          notificationToast("Erreur", e.message, "error");
        }
      }
    }
  }, [promotion, refresh, notificationToast, deletePromotionDialog]);

  const getActions = (): ActionType[] => [
    {
      label: "Modifier",
      onClick: () => setPromotionEdit(),
      icon: <EditIcon size={20} />,
    },
    {
      label: "Supprimer",
      variant: "danger",
      onClick: onDeletePromotion,
      icon: <TrashIcon size={20} />
    },
  ];

  const updateVisibility = useCallback(async () => {
    try {
      setIsLoadingVisibility(true);

      // Promotion is not fetched with all it's data in <PromotionTable> so we have to fetch it here
      const fetchedPromotion = await promotionsService.find(promotion.id);

      const promotionImageBase64 = await getBase64FromImageUrl(fetchedPromotion.image_url);

      const promotionImageName = getImageNameFromImageUrl(fetchedPromotion.image_url);

      // Object "cleaning"
      const promotionToSend: {[key: string]: any} = {
        ...fetchedPromotion,
        ...{
          start_date: moment(new Date(fetchedPromotion.start_date)).format('YYYY-MM-DD'),
          end_date: moment(new Date(fetchedPromotion.end_date)).format('YYYY-MM-DD'),
          image: {base64: promotionImageBase64, name: promotionImageName},
          visible: !fetchedPromotion.visible,
        }
      };

      ["createdAt", "updatedAt", "id", "_id", "nb_products", "image_url"].forEach((item) => {
        delete promotionToSend[item]
      })

      await promotionsService.update(fetchedPromotion.id, promotionToSend as PromotionFormData & {order: number});

      await refresh();
    } catch (e: any) {
      notificationToast("Erreur", "Veuillez contacter un administrateur", "error");
    } finally {
      setIsLoadingVisibility(false)
    }
  }, [promotion, refresh, notificationToast]);

  return (
    <>
      <Table.Row onClick={setPromotionEdit} linkTo={``} color={"inherit"}>
        <Table.Cell>{promotion.name}</Table.Cell>
        <Table.Cell>{promotion.nb_products}</Table.Cell>
        <Table.Cell>
          {DateHelper.dateToDDMMYYYY(promotion.start_date)}
        </Table.Cell>
        <Table.Cell>{DateHelper.dateToDDMMYYYY(promotion.end_date)}</Table.Cell>
        <Table.Cell>
          {!isLoadingVisibility ? (
            <StatusSelector
              isActive={promotion.visible}
              activeState="Visible"
              inactiveState="Non visible"
              onChange={updateVisibility}
            />
          ) : (
            <Spinner size="small" variant="secondary" />
          )}
        </Table.Cell>
        <Table.Cell onClick={(e) => e.stopPropagation()} className="w-[32px]">
          <Actionables forceDropdown={true} actions={getActions()} />
        </Table.Cell>
      </Table.Row>
    </>
  );
}
