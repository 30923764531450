import { useState } from "react";
import { useForm } from "react-hook-form";
import InputField from "../../../../../components/form/input";
import Button from "../../../../../components/fundamentals/button";
import Modal from "../../../../../components/fundamentals/modal";
import useNotification from "../../../../../hooks/use-notification";
import { Category } from "../../../../../models/Category";
import categoryService from "../../../../../services/category.service";
import Select from "../../../../../components/form/select/next-select/select";

interface IProps {
  category?: Category;
  onClose: () => void;
}

type CategoryModalFormData = {
  name: string;
  visibility: string | undefined;
};

const optionsVisibility = [
  { value: 1, label: "Visible" },
  { value: 0, label: "Non visible" },
];

export default function ModalCategory({ category, onClose }: IProps) {
  const [isLoading, setIsLoading] = useState<boolean>();
  const { register, handleSubmit } = useForm<CategoryModalFormData>();
  const notification = useNotification();

  const submit = async (data: CategoryModalFormData) => {
    try {
      if (!category) {
        setIsLoading(true);
        await categoryService.create(data);
        setIsLoading(false);
        notification("Succès", "Votre category a été créée !", "success");
        onClose();
      }
    } catch (e) {
      notification("Erreur", "Impossible de supprimer la category", "error");
    }
  };

  return (
    <Modal handleClose={onClose} isLargeModal>
      <Modal.Body>
        <Modal.Header handleClose={onClose}>
          <div>
            <h1 className="inter-xlarge-semibold mb-2xsmall">
              {!category ? "Ajouter une catégorie" : "Modifier une catégorie"}
            </h1>
            {!category && (
              <p className="inter-small-regular text-grey-50">
                Pour créer une categorie, vous avez besoin d'un titre et d'un
                slug
              </p>
            )}
          </div>
        </Modal.Header>
        <form onSubmit={handleSubmit(submit)}>
          <Modal.Content>
            <div>
              <h2 className="inter-base-semibold mb-base">Général</h2>
              <div className="flex items-center gap-x-base">
                <InputField
                  label="Nom"
                  required
                  placeholder="Lumière d'ambiance"
                  {...register("name", { required: true })}
                  defaultValue={category?.name ?? ""}
                />
                {/* <InputField
                  label="Visibilité"
                  placeholder="lunettes-de-soleil"
                  {...register("visibility")}
                  prefix="/"
                  tooltip={
                    <IconTooltip content="URL Slug de la categorie. Sera autogénéré si le champs est blanc." />
                  }
                /> */}
                <Select
                  label="Visibilité"
                  placeholder="Visibilité"
                  {...register("visibility")}
                  required
                  options={optionsVisibility}
                  isSearchable={false}
                  defaultValue={
                    category
                      ? optionsVisibility.find(
                          (op) => !!op.value === category.enable
                        )
                      : optionsVisibility[0]
                  }
                />
              </div>
            </div>
          </Modal.Content>
          <Modal.Footer>
            <div className="flex items-center justify-end w-full gap-x-xsmall">
              <Button
                variant="secondary"
                size="small"
                type="button"
                onClick={onClose}
              >
                Annuler
              </Button>
              <Button variant="primary" size="small" loading={isLoading}>
                {`${category ? "Sauvegarder" : "Publier"} la catégorie`}
              </Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal.Body>
    </Modal>
  );
}
