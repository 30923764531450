type ModalH1TitleLayoutProps = {
    title: string
}

/** Display a left H1 Style title inside Modal.Header **/
function ModalH1TitleLayout({title}: ModalH1TitleLayoutProps) {
    return (
        <h1 className="inter-xlarge-semibold mb-xsmall">
            {title}
        </h1>
    )
}

export default ModalH1TitleLayout
