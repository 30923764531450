export enum UserStatus {
  WAITING = "waiting",
  ENABLED = "enabled",
  DISABLED = "disabled",
}

export class User {
  public id: number;
  public name: string;
  public firstname: string;
  public lastname: string;
  public email: string;
  public function: string|undefined|null;
  public phoneNumber: string|undefined|null;
  public mobileNumber: string|undefined|null;
  public createdAt: Date;
  public updatedAt: Date;
  public status: string;
  static users: User[];

  constructor(data: any) {
    const {firstname, lastname} = this.parseName(data.name);

    this.id = data.id ?? data._id;
    this.name = data.name;
    this.firstname =  data.firstname ?? firstname;
    this.lastname = data.lastname ?? lastname;
    this.email = data.email;
    this.function = data.function;
    this.phoneNumber = data.phone_number;
    this.mobileNumber = data.mobile_number;
    this.createdAt = new Date(data.createdAt);
    this.updatedAt = new Date(data.updatedAt);
    this.status = data.status;
  }

  parseName(name: string|undefined): {firstname: string|undefined, lastname: string|undefined} {
    if (!name) {
      return {firstname: undefined, lastname: undefined};
    }

    const arrayName = name.split(' ');

    if (arrayName.length < 2) {
      return {firstname: undefined, lastname: undefined};
    }

    // Assumption, first item is firstname, remaining items are lastname;
    return {firstname: arrayName.slice(0,1).join(), lastname: arrayName.slice(1, arrayName.length).join(' ')};
  }

  getLabelStatus() {
    switch (this.status) {
      case UserStatus.ENABLED:
        return "Activé";
      case UserStatus.DISABLED:
        return "Désactivé";
      default:
        return "En attente";
    }
  }
}
