import { useEffect, useState } from "react";

export interface IitemDrag {
  position: number;
  item: any;
}

export const useDraggable = () => {
  const [dragItem, setDragItem] = useState<IitemDrag | null>();
  const [dragOverItem, setDragOverItem] = useState<IitemDrag | null>();

  useEffect(() => {
    document.addEventListener("dragover", (event) => {
      event.preventDefault();
    });
  }, [dragItem]);

  const dragStart = (e: any, item: IitemDrag) => setDragItem(item);

  const dragEnter = (e: any, item: IitemDrag) => setDragOverItem(item);

  const clear = () => {
    if (dragItem && dragOverItem) {
      setDragItem(null);
      setDragOverItem(null);
    }
  };

  return { dragStart, dragEnter, clear, dragItem, dragOverItem };
};
