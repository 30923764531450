import { Route, Routes } from "react-router-dom";
import BodyCard from "../../../components/fundamentals/body-card/body-card";
import AlertIcon from "../../../components/fundamentals/icons/alert-icon";
import CoinsIcon from "../../../components/fundamentals/icons/coins-icon";
import CrosshairIcon from "../../../components/fundamentals/icons/crosshair-icon";
import HappyIcon from "../../../components/fundamentals/icons/happy-icon";
import HelpCircleIcon from "../../../components/fundamentals/icons/help-circle";
import KeyIcon from "../../../components/fundamentals/icons/key-icon";
import UsersIcon from "../../../components/fundamentals/icons/users-icon";
import PageDescription from "../../../components/fundamentals/page-description";
import SettingsCard from "../../../components/fundamentals/settings-card";
import { Client } from "../../../models/Client";
import { User } from "../../../models/User";
import adminService from "../../../services/admin.service";
import FlowsPage from "./flows/flows-page/FlowsPage";
import PersonalInformation from "./personal-information";
import users from "./users";
import UsersList from "./users";

 function SettingsPage() {
  
  return (
   <>
    <PageDescription
        title={"Paramètres"}
        subtitle={"Gérer les paramètres de votre boutique"}
    />
    <div className="flex flex-col grow h-2"> 
      <div className="grid medium:grid-cols-2 auto-cols-fr grid-cols-1 gap-x-base gap-y-xsmall">
      
      <SettingsCard
      heading={"Gestion des points de ventes"}
      description={"Gérer les points de vente"}
      icon={<CrosshairIcon />}
      href={`${process.env.REACT_APP_STRAPI_URL}admin/content-manager/collectionType/api::agence.agence?page=1&pageSize=10&sort=city:ASC`}
      disabled={false}     
       />
     <SettingsCard
      heading={"Informations personnelles"}
      description={"Gérer les informations de otre profil"}
      icon={<HappyIcon  />}
      to= {`/settings/personal-information`}
      disabled={false}     
       />
      <SettingsCard
      heading={"Utilisateurs"}
      description={"Gérer les droits des utilisateurs"}
      icon={<UsersIcon />}
      to= {`/settings/users`}
      disabled={false}     
       />
      <SettingsCard
      heading={"FAQ"}
      description={"Gérer la Foire aux Questions"}
      icon={<HelpCircleIcon />}
      href={`${process.env.REACT_APP_STRAPI_URL}admin/content-manager/collectionType/api::faq.faq?page=1&pageSize=10&sort=id:ASC`}
      disabled={false}     
       />
      <SettingsCard
      heading={"Informations Légales"}
      description={"Gérer les mentions légales"}
      icon={<AlertIcon />}
      href={`${process.env.REACT_APP_STRAPI_URL}admin/content-manager/singleType/api::config.config`}
      disabled={false}     
       />
      <SettingsCard
      heading={"Flux automatiques"}
      description={"Voir les flux automatiques"}
      icon={<KeyIcon />}
      to= {'/settings/flows'}
      disabled={false}     
       />
      </div>
    </div>
    </>
  );
}

const Settings = () => (
  <Routes >
    <Route index element={<SettingsPage />} />
    <Route path="/personal-information" element={<PersonalInformation />} />
    <Route path="/users" element={<UsersList/>} />
    <Route path="/flows" element={<FlowsPage/>} />
    {/* admin && admin.users ? admin.users : [] */}
  </Routes>
)


export default Settings ;
    
 






