import axios from "axios";
import { Admin } from "../models/Admin";
import { DataPaginate } from "../models/utils/DataPaginate";
import {AdminUserFormData} from "../pages/logged/settings/users/modal-admin-user/ModalAdminUser";

function list(page: number, search?: string) {
    let params = `?page=${page}`;
    if (search) {
      params = `${params}&search=${search}`;
    }
    // if (filters) {
    //   params = `${params}${convertFiltersToQuery(filters)}`;
    // }
  
    return axios
      .get(`${process.env.REACT_APP_API_URL}admins${params}`)
      .then((response: any) => {
        const dataPaginate = new DataPaginate(response.data);
        dataPaginate.setData(dataPaginate.data.map((item) => new Admin(item)));
  
        return dataPaginate;
      });
  }

  function create(adminUserFormData: AdminUserFormData): Promise<Admin> {
    return new Promise((resolve, reject) => {
        axios.post(`${process.env.REACT_APP_API_URL}admins`, adminUserFormData)
            .then((response) => {
                resolve(new Admin(response.data));
            })
            .catch((error) => {
                switch (error.response.status) {
                    case 403: // Already used email case
                        reject({status: 403, errors: [{key: 'email', message: error.response.data.error}]});
                        break;
                    case 422:
                        const errors = Object.entries<{[key: string]: string[]}>(error.response.data.errors)
                            .map(([fieldName, message]) => (
                                {key: fieldName, message: message[0]}
                            ));

                        reject({status: 422, errors});
                        break;
                    default:
                        reject({status: error.response.status, errors: [{key: 'unknown', message: 'Veuillez réessayer'}]})
                }
            })
    })
  }

  function update(id: number, body: AdminUserFormData): Promise<Admin> {
    return new Promise((resolve, reject) => {
        axios.put(`${process.env.REACT_APP_API_URL}admins/${id}`, body)
            .then((response) => {
                resolve(new Admin(response.data));
            })
            .catch((error) => {
                switch (error.response.status) {
                    case 403: // Already used email case
                        reject({status: 403, errors: [{key: 'email', message: error.response.data.error}]});
                        break;
                    case 422:
                        const errors = Object.entries<{[key: string]: string[]}>(error.response.data.errors)
                            .map(([fieldName, message]) => (
                                {key: fieldName, message: message[0]}
                            ));

                        reject({status: 422, errors});
                        break;
                    default:
                        reject({status: error.response.status, errors: [{key: 'unknown', message: 'Veuillez réessayer'}]})
                }
            })
    })
  }

  function deleteAdmin(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
        axios.delete(`${process.env.REACT_APP_API_URL}admins/${id}`)
            .then((response) => {
                resolve();
            })
            .catch((error) => {
                reject({status: error.response.status, errors: [{key: 'unknown', message: 'Veuillez réessayer'}]});
            })
    })
  }
  
  export default {
    list,
    create,
    update,
    deleteAdmin,
  };