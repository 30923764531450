import Modal from "../../../../../components/fundamentals/modal";
import ModalH1TitleLayout from "../../../../../components/fundamentals/modal/layouts/header/ModalH1TitleLayout";
import {useForm} from "react-hook-form";
import InputField from "../../../../../components/form/input";
import Modal2ButtonsLayout from "../../../../../components/fundamentals/modal/layouts/footer/Modal2ButtonsLayout";
import {useCallback} from "react";
import adminService from "../../../../../services/admin.service";
import useNotification from "../../../../../hooks/use-notification";
import {Admin} from "../../../../../models/Admin";

type ModalAdminUserProps = {
    handleClose: () => void,
    postSubmit: () => Promise<void>,
    adminUser?: Admin,
}

export type AdminUserFormData = {
    email: string,
    firstname: string,
    lastname: string,
    function: string,
}
function ModalAdminUser({handleClose, postSubmit, adminUser}: ModalAdminUserProps) {

    const {
        register,
        formState: {errors, isValid},
        handleSubmit,
        setError
    } = useForm<AdminUserFormData>({
        mode: "onChange",
        defaultValues: {
            firstname: adminUser?.firstname ?? "",
            lastname: adminUser?.lastname ?? "",
            email: adminUser?.email ?? "",
            function: adminUser?.function ?? "",
        }
    });

    const notificationToast = useNotification();

    const onSubmit = useCallback(async (data: AdminUserFormData) => {
        try {

            adminUser
                ? await adminService.update(adminUser.id, data)
                : await adminService.create(data);

            notificationToast(
                "Succès",
                `L'administrateur a été ${adminUser ? "modifié" : "ajouté"}`,
                "success"
            );

            await postSubmit();
        } catch (e: any) {
            if (e.status) {
                for (const item of e.errors) {
                    item.key !== "unknown"
                        ? setError(item.key, {type: 'backend', message: item.message})
                        : notificationToast("Erreur", item.message, "error");
                }
            }
        }
    }, [])

    return (
        <Modal handleClose={handleClose}>
            <Modal.Body>
                <Modal.Header handleClose={handleClose}>
                    <ModalH1TitleLayout title={adminUser ? "Modifier l'utilisateur" : "Nouvel utilisateur"}/>
                </Modal.Header>
                <Modal.Content>
                    <div className="flex flex-col gap-y-6">
                        <div className="flex items-center gap-x-base">
                            <InputField
                                label="Prénom"
                                placeholder="Prénom"
                                required={true}
                                errors={errors}
                                {...register('firstname', {required: true})}
                            />
                            <InputField
                                label="Nom"
                                placeholder="Nom"
                                required={true}
                                errors={errors}
                                {...register('lastname', {required: true})}
                            />
                        </div>
                        <div className="flex items-center gap-x-base">
                            <InputField
                                label="Email"
                                placeholder="Email"
                                required={true}
                                errors={errors}
                                {...register('email', {
                                    required: true,
                                    pattern: {value: /^.+@.+\.\w{2,4}$/, message: "Email invalide"}
                                })}
                            />
                            <InputField
                                label="Fonction"
                                placeholder="Fonction"
                                errors={errors}
                                {...register('function')}
                            />
                        </div>
                    </div>
                </Modal.Content>
                <Modal.Footer>
                    <Modal2ButtonsLayout
                        position="right"
                        discardButtonLabel="Fermer"
                        discardButtonHandler={handleClose}
                        submitButtonLabel="Enregistrer"
                        submitButtonHandler={handleSubmit(onSubmit)}
                        showSubmitButtonLoadingSpinner={false}
                        disableSubmitButton={!isValid}
                    />
                </Modal.Footer>
            </Modal.Body>
        </Modal>
    )
}

export default ModalAdminUser