export class Admin {
    public id: number;
    public firstname: string;
    public lastname: string;
    public email: string;
    public function : string | undefined;
    public phone_number :number | undefined;
    public createdAt: Date;
    public updatedAt: Date;
    public status: string;
    static users: Admin[];
  
    constructor(data: any) {
      this.id = data.id ?? data._id;
      this.firstname = data.firstname;
      this.lastname = data.lastname;
      this.email = data.email;
      this.function = data.function; 
      this.phone_number =data.phone_number;
      this.createdAt = new Date(data.createdAt);
      this.updatedAt = new Date(data.updatedAt);
      this.status = data.status;
    }

}