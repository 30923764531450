import axios from "axios";
import Axios from "axios";
import { User } from "../models/User";
import router from "../router/router";
import {AuthFormData} from "../pages/not-logged/auth/AuthForm";

function authenticate(credentials: any) {
    return axios
      .post(`${process.env.REACT_APP_API_URL}admins/login`, credentials)
      .then((response: any) => {
        localStorage.setItem("auth", JSON.stringify(response.data));
        return true;
      });
}

function me() {
    console.log(process.env.REACT_APP_API_URL)
    return axios
      .get(`${process.env.REACT_APP_API_URL}admins/me`)
      .then((response: any) => new User(response.data));
}

async function updateAxiosAuthorization() {
    await Axios.interceptors.request.use((config: any) => {
      config.headers.Authorization = "Bearer " + getAuthToken();
  
      return config;
    });
  }
  
async function updateAxiosInterceptors() {
    await Axios.interceptors.response.use(undefined, (error) => {
        const { status } = error.response;
        if (status === 401) {
        logout();
        router.navigate("/login");
        }
        return Promise.reject(error);
    });
}

function getAuthToken() {
    let auth = localStorage.getItem("auth");

    return auth ? JSON.parse(auth).access_token : null;
}

async function logout() {
    window.localStorage.removeItem("auth");
  }

function loginWithGoogle(googleToken: string): Promise<void> {
    return new Promise((resolve, reject) => {
        axios.post(`${process.env.REACT_APP_API_URL}admins/login-with-google`, {google_token: googleToken})
            .then((response) => {
                localStorage.setItem("auth", JSON.stringify(response.data));

                resolve();
            })
            .catch((error) => {
                switch (error.response.status) {
                    case 401:
                        reject({status: 401, message: "Le jeton d'identification Google est invalide"});
                        break;
                    case 404:
                        reject({status: 404, message: "Utilisateur inconnu"});
                        break;
                    default:
                        reject({status: error.response.status, message: "Contactez un administrateur"})
                }
            })
    })
}

function resetPassword(email: string): Promise<void> {
    return new Promise((resolve, reject) => {
        axios.post(`${process.env.REACT_APP_API_URL}admins/ask-reset-password`, {email})
            .then((response) => {
                resolve()
            })
            .catch((error) => {
                switch (error.response.status) {
                    case 404: // Not found
                        reject({status: 404, message: "Utilisateur inconnu"});
                        break;
                    case 422:
                        reject({status: 422, message: "Email invalide"});
                        break;
                    default:
                        reject({status: error.response.status, message: "Veuillez réessayer"})
                }
            })
    })
}

function validateAccount(authFormData: AuthFormData): Promise<void> {
    return new Promise((resolve, reject) => {
        const body: any = {...authFormData};

        body.token_creating_account = body.token;

        delete body.token;

        axios.post(`${process.env.REACT_APP_API_URL}admins/validate-account`, body)
            .then((response) => {
                resolve();
            })
            .catch((error) => {
                switch (error.response.status) {
                    case 404:
                        reject({status: 404, message: "Compte inconnu"});
                        break;
                    default: // 422 + others
                        reject({status: error.response.status, message: "Veuillez réessayer"});
                }
            })
    })
}

function validatePasswordAfterReset(authFormData: AuthFormData): Promise<void> {
    return new Promise((resolve, reject) => {
        const body: any = {...authFormData};

        body.token_resetting_password = body.token;

        delete body.token;

        axios.post(`${process.env.REACT_APP_API_URL}admins/validate-password`, body)
            .then((response) => {
                resolve();
            })
            .catch((error) => {
                switch (error.response.status) {
                    case 404:
                        reject({status: 404, message: "Compte inconnu"});
                        break;
                    case 403:
                        reject({status: 403, message: "Le jeton de réinitialisation a expiré"})
                        break;
                    default: // 422 + others
                        reject({status: error.response.status, message: "Veuillez réessayer"});
                }
            });
    })
}

export default {
    authenticate,
    me,
    logout,
    loginWithGoogle,
    resetPassword,
    validateAccount,
    validatePasswordAfterReset,
    updateAxiosAuthorization,
    updateAxiosInterceptors
}