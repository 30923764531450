import Ca2eIcon from "../../../components/fundamentals/icons/ca2e-icon";
import SigninInput from "../../../components/form/input-signin";
import Button from "../../../components/fundamentals/button";
import {useForm, UseFormHandleSubmit, UseFormRegister} from "react-hook-form";

export type AuthFormData = {
    email: string,
    password: string,
    token: string,
}

type AuthFormProps = {
    isLoading: boolean,
    onSubmit: (data: Partial<AuthFormData>) => Promise<void>,
    context: "validate"|"reset",
    errorMessage: string,
}

function AuthForm({isLoading, onSubmit, context, errorMessage}: AuthFormProps) {

    const {
        register,
        formState: {errors, isValid},
        handleSubmit
    } = useForm<AuthFormData>({mode: "onChange"});

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col items-center">

                <Ca2eIcon />
                <span className="inter-2xlarge-semibold mt-4 text-grey-90">
                                Bienvenue
                            </span>
                <span className="inter-base-regular text-grey-50 mb-xlarge">
                                {context === "validate"
                                    ? "Confirmez votre compte ci-dessous"
                                    : "Réinitialisez votre mot de passe ci-dessous"
                                }
                            </span>
                <SigninInput
                    placeholder="Entrez votre email..."
                    {...register("email", { required: true, pattern: {value: /^.+@.+\.\w{2,4}$/, message: "Email invalide"}})}
                    autoComplete="email"
                />
                <SigninInput
                    placeholder="Mot de passe..."
                    type={"password"}
                    {...register("password", { required: true })}
                    autoComplete="current-password"
                />
                {errorMessage &&
                    <span className="text-rose-50 w-full mt-2 inter-small-regular">
                        {errorMessage}
                    </span>
                }
                <Button
                    className="rounded-rounded mt-4 w-[320px] inter-base-regular"
                    variant="primary"
                    size="large"
                    type="submit"
                    disabled={!isValid}
                    loading={isLoading}
                >
                    Continuer
                </Button>
            </div>
        </form>
    )
}

export default AuthForm