import { useEffect, useState } from "react";
import Table from "../../../../../components/fundamentals/table";
import TableContainer from "../../../../../components/fundamentals/table-container";
import { Order } from "../../../../../models/Order";
import { DataPaginate } from "../../../../../models/utils/DataPaginate";
import ordersService from "../../../../../services/orders.service";
import OrderRow from "../../../orders/orders-page/order-table/OrderRow";
import {getTablePagingState} from "../../../../../services/utils/TableHelper";

interface OrdersProps {
   
    clientId: number | undefined;
  }
  export default function OrdersTableCustomer({ clientId }: OrdersProps){  

   
    const [dataPaginated, setDataPaginated] = useState<DataPaginate>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [currentPage, setCurrentPage] = useState(1); 

    useEffect(() => {
        async function fetchData(){
          try{
            setIsLoading(true)
            setDataPaginated(await ordersService.list(
              currentPage,
              undefined,
              undefined, 
              clientId, 
            ));
            setIsLoading(false)
          }catch(e){
            setIsLoading(false)
          }
        }
        fetchData();
      }, [currentPage, clientId])



    return (

    <TableContainer     
        
        numberOfRows={10}
        hasPagination
        pagingState={getTablePagingState(dataPaginated!, setCurrentPage, "résultats")}
        isLoading={isLoading}
     >
     {dataPaginated && <Table>
        <>
          <Table.Head>
            <Table.HeadRow>
              <Table.HeadCell>Id</Table.HeadCell>
              <Table.HeadCell>Date</Table.HeadCell>
              <Table.HeadCell>Adresse de retrait</Table.HeadCell>
              <Table.HeadCell>Total</Table.HeadCell>
            </Table.HeadRow>
          </Table.Head>
          <Table.Body>
            {dataPaginated?.data?.map((order: Order, index: number) => (
              <OrderRow order={order} index={index} key={`orders-row-${order.id}`} showColClient={false} />
            ))}
          </Table.Body>
        </>
      </Table>}
    </TableContainer>

        );
    }