import Table from "../../../../../components/fundamentals/table";
import TableContainer from "../../../../../components/fundamentals/table-container";
import { User } from "../../../../../models/User";
import Actionables, {ActionType} from "../../../../../components/fundamentals/actionables/actionables";
import {useCallback, useState} from "react";
import ModalClientUser from "../modal-client-user/ModalClientUser";
import TrashIcon from "../../../../../components/fundamentals/icons/trash-icon";
import useImperativeDialog from "../../../../../hooks/use-imperative-dialog";
import userService from "../../../../../services/users.service";
import useNotification from "../../../../../hooks/use-notification";

interface IProps{
    isLoading: boolean;
    users: User[];
    clientId: string|undefined;
    onClientUserAction: () => void,
}
export default function UserTableCustomer({users, isLoading, clientId, onClientUserAction}: IProps){
    return (
        <TableContainer 
            numberOfRows={users.length + 1}
            isLoading={isLoading}
        >
            <Table>
                <>
                <Table.Head>
                    <Table.HeadRow>
                    <Table.HeadCell>Email</Table.HeadCell>
                    <Table.HeadCell>Nom</Table.HeadCell>
                    <Table.HeadCell>Téléphone</Table.HeadCell>
                    </Table.HeadRow>
                </Table.Head>
                <Table.Body>
                    {users?.map((user: User) => (
                    <UsersRow
                        user={user}
                        clientId={clientId}
                        key={`users-row-${user.id}`}
                        onClientUserAction={onClientUserAction}
                    />
                    ))}
                </Table.Body>
                </>
            </Table>
        </TableContainer>
    );
}


interface TUsersRow {
    user: User;
    clientId: string|undefined
    onClientUserAction: () => void,
}
  
const UsersRow = ({ user, clientId, onClientUserAction }: TUsersRow) => {
    const [showEditClientUserModal, setShowEditClientUserModal] = useState<boolean>(false);

    const deleteClientUserDialog = useImperativeDialog();

    const notificationToast = useNotification();

    const onDeleteClientUser = useCallback(async () => {
        const hasClientUserToBeDeleted = await deleteClientUserDialog({
            heading: "Supprimer l'utilisateur",
            text: "Êtes-vous sûr de vouloir supprimer cet utilisateur"
        });

        /** Verification of clientId existence is needed by Typescript **/
        if (hasClientUserToBeDeleted && clientId) {
            try {
                await userService.deleteClientUser(user.id, clientId);

                notificationToast("Succès", "L'utilisateur a été supprimé", "success");

                onClientUserAction();
            } catch (e: any) {
                if (e.status) {
                    notificationToast("Erreur", e.message, "error");
                }
            }
        }
    }, [user, clientId, onClientUserAction, notificationToast, deleteClientUserDialog])


    const clientUserActions: ActionType[] = [
        // Edit feature is not fully implemented
        // {
        //     label: "Modifier",
        //     onClick: () => setShowEditClientUserModal(true),
        //     icon: <EditIcon size={20} />,
        // },
        {
            label: "Supprimer",
            variant: "danger",
            onClick: onDeleteClientUser,
            icon: <TrashIcon size={20} />,
        },
    ];

    return (
        <>
            <Table.Row
            color={"inherit"}
            className="hover:bg-grey-5 cursor-pointer"
            >
            <Table.Cell>{user.email }</Table.Cell>
            <Table.Cell>{user.name}</Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell onClick={(e) => e.stopPropagation()}>
                <Actionables forceDropdown={true} actions={clientUserActions}></Actionables>
            </Table.Cell>
            </Table.Row>
            {/*Edit feature is not fully implemented*/}
            {/*{showEditClientUserModal &&*/}
            {/*    <ModalClientUser*/}
            {/*        handleClose={() => setShowEditClientUserModal(false)}*/}
            {/*        user={user}*/}
            {/*        context="edit"*/}
            {/*        clientId={clientId}*/}
            {/*        afterSubmit={onClientUserAction}*/}
            {/*    />*/}
            {/*}*/}
        </>
    );
};
  