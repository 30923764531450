import { userInfo } from "os";
import React, { useEffect, useState } from "react"
import BackButton from "../../../../components/fundamentals/back-button"
import { User } from "../../../../models/User";
import authService from "../../../../services/auth.service";
import EditUserInformation from "./edit-user-information";


const PersonalInformation = () => {

  const [user, setUser] = useState<User>();

  useEffect(() => {
      async function fetchUser(){
          setUser(await authService.me());
      }

      fetchUser();
  }, [])

  /* CODE POUR MODIFIER UN UTILISATEUR 
  useEffect(() => {    
    async function fetchData(){
      if(user){
        usersService.update(user.id, {
         ...user,
         firstname: 'Admin',
         lastname: 'Webexpr'
        })
      }
    }
    fetchData();
  }, [user]);*/


  return (
    <div>
      <BackButton
        label="Back to Settings"
        path="/settings"
        className="mb-xsmall"
      />
      <div className="bg-white flex flex-col rounded-rounded border border-grey-20 pt-large pb-xlarge px-xlarge gap-y-xlarge large:max-w-[50%]">
        <div className="flex flex-col gap-y-2xsmall">
          <h1 className="inter-xlarge-semibold">Informations personnelles</h1>
          <p className="inter-base-regular text-grey-50">
            Gérer les informations de votre profil
             {/* {user ? user.firstname + ' ' + user.lastname + '- ' + user.email : ''} */}
          </p>
        </div>
      <div className="flex flex-col">
           <div className="border-t border-grey-20 py-xlarge">
           <EditUserInformation/>
          </div> 
          
          </div>
      </div>
    </div>
  )
}

export default PersonalInformation
