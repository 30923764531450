export interface IFilter {
     name: string;
     label: string;
     type: EFilter;
     value?: any;
     typeFilterDate?: ETypeFilterDate;
     list?: {label: string, value: any}[];
     listFiltered?: {label: string, value: any}[];
     isMin?: boolean;
     isMax?: boolean;
}

export enum EFilter{
    TEXT="text",
    NUMBER="number",
    DATE="date",
    SELECT="select",
    SELECT_MULTIPLE="select_multiple",
    TAG="tag"
}

export enum ETypeFilterDate{
    START_AT="start_at",
    END_AT="end_at",
}