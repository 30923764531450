import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import {
  EFilter,
  IFilter,
} from "../../../models/utils/filter/FilterType.interface";
import CheckIcon from "../icons/check-icon";
import ChevronDownIcon from "../icons/chevron-down";
import * as RadixPopover from "@radix-ui/react-popover";
import { useWindowDimensions } from "../../../hooks/use-window-dimensions";
import Button from "../button";
import React from "react";
import PlusIcon from "../icons/plus-icon";
import DropdownFilterItem from "./DropdownFilterItem";
import { type } from "os";
import { cloneDeep } from "lodash";

interface IProps {
  filters: IFilter[];
  onClear: () => void;
  onSubmit: (filters: IFilter[]) => void;
}
export default function DropdownFilter({ filters, onClear, onSubmit }: IProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { height } = useWindowDimensions();
  const [cloneFilters, setCloneFilters] = useState<IFilter[]>(
    cloneDeep(filters)
  );

  const ref = useRef<HTMLButtonElement>(null);
  const [heightStyle, setHeightStyle] = useState({
    maxHeight: height,
  });

  useEffect(() => {
    setHeightStyle({
      maxHeight: height - (ref?.current?.getBoundingClientRect().y ?? 0) - 50,
    });
  }, [ref]);

  function updateFilter(filter: IFilter, value: any) {
    if (filter.type == EFilter.SELECT) {
      if (filter.value != value) {
        filter.value = value;
      } else {
        filter.value = undefined;
      }
    } else if (filter.type == EFilter.SELECT_MULTIPLE) {
      if (!filter.value) {
        filter.value = [value];
      } else if (Array.isArray(filter.value)) {
        if (filter.value.includes(value)) {
          filter.value = filter.value.filter((v) => v != value);
        } else {
          filter.value = [...filter.value, value];
        }
      }
    } else if (filter.type == EFilter.TAG) {
      filter.value = value;
    }

    const newFilters = cloneFilters.map((f) => {
      if (f.name === filter.name) {
        return filter;
      }

      return f;
    });
    setCloneFilters(newFilters);
  }

  return (
    <div className="flex space-x-1">
      <RadixPopover.Root open={isOpen} onOpenChange={setIsOpen}>
        <RadixPopover.Trigger ref={ref} asChild>
          <button
            className={clsx(
              "flex rounded-rounded items-center space-x-1 focus-visible:outline-none focus-visible:shadow-input focus-visible:border-blue-60"
            )}
          >
            <div className="flex rounded-rounded items-center bg-grey-5 border border-grey-20 inter-small-semibold px-2 h-6">
              Filters
              <div className="text-grey-40 ml-1 flex items-center rounded">
                <span className="text-blue-60 inter-small-semibold">
                  {filters.filter((f) => f.value).length}
                </span>
              </div>
            </div>
            <div className="flex items-center rounded-rounded bg-grey-5 border border-grey-20 inter-small-semibold p-1">
              <PlusIcon size={14} />
            </div>
          </button>
        </RadixPopover.Trigger>
        <RadixPopover.Content
          sideOffset={8}
          style={heightStyle}
          className="bg-grey-0 overflow-y-auto rounded-rounded shadow-dropdown max-w-[272px] py-4 z-40"
        >
          <div className="flex px-4 pb-4 border-b border-grey-20">
            <Button
              size="small"
              tabIndex={-1}
              className="mr-2 border border-grey-20"
              variant="ghost"
              onClick={() => {
                setIsOpen(false);
                onClear();
              }}
            >
              Clear
            </Button>
            <Button
              tabIndex={-1}
              variant="primary"
              className="w-44 justify-center"
              size="small"
              onClick={() => {
                setIsOpen(false);
                onSubmit(cloneFilters);
              }}
            >
              Apply
            </Button>
          </div>
          {cloneFilters.map((filter) => (
            <div
              className="border-b border-grey-20 py-2 px-4 last:pb-0 last:border-0"
              key={`filter_item_${filter.name}`}
            >
              <DropdownFilterItem
                filter={filter}
                update={(filter, value) => updateFilter(filter, value)}
              />
            </div>
          ))}
        </RadixPopover.Content>
      </RadixPopover.Root>
    </div>
  );
}
