import React, { useEffect, useState } from "react"
import Avatar from "../../../../../components/fundamentals/avatar";
import Button from "../../../../../components/fundamentals/button";
import useToggleState from "../../../../../hooks/use-toggle-state";
import { User } from "../../../../../models/User";
import authService from "../../../../../services/auth.service";
import EditUserInformationModal from "./edit";



const EditUserInformation = () => {

    const [user, setUser] = useState<User>();
    const { state, toggle, close } = useToggleState();
    
   useEffect(() => {
        async function fetchUser(){
            setUser(await authService.me());
        }
  
        fetchUser();
    }, [])


    return (
        <>
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-x-base">
              <div className="w-18 aspect-square rounded-full border border-grey-20 flex items-center justify-center box-border">
                {user ? (
                  <div className="w-16 aspect-square">
                    <Avatar
                      user={{ ...user }}
                      color="bg-rose-400"
                      font="inter-2xlarge-semibold"
                    />
                  </div>
                ) : (
                  <div className="" />
                )}
              </div>
              <div className="flex flex-col">
                {!!user ? (
                  <>  
                    <p className="inter-base-semibold">{user?.email}</p>
                  </>
               ) : (
                  <p className="inter-base-semibold">{}</p>

                )} 
              </div>
            </div>
            <Button
              variant="secondary"
              size="small"
              disabled={!user}
              onClick={toggle}
            >
              Modifier
            </Button>
          </div>
          {user && (
           <EditUserInformationModal open={state} onClose={close} user={user} />
          )}        
        </>
      )
    }
    
    export default EditUserInformation
    