//12/05/2022
function dateToDDMMYYYY(date: Date) {
  const day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
  const month =
    date.getMonth() + 1 > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;

  return `${day}/${month}/${date.getFullYear()}`;
}

//14 December 2022 01:44 pm
function dateToDDMMYYYYLong(date: Date) {
  return date.toLocaleDateString("fr-FR", {
    day: "2-digit",
    month: "long",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
}

function dateToDDMMYYYYAndHM(date: Date) {
  const day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
  const month =
    date.getMonth() + 1 > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;

  return `${day}/${month}/${date.getFullYear()} à ${date.getHours()}h${date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}`;
}

function dateToDDMMYYYYAndHHMMSS(date: Date) {
  const day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
  const month =
    date.getMonth() + 1 > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;

    return `${day}/${month}/${date.getFullYear()} - ${date.getHours()}:${date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}:${date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()}`;
}

// Il y'a 6 jours
function formatThereIsDate(date: number) {
  const plurials = (word: string, nb: number) => {
    return nb !== 0 ? word + "s" : word;
  };
  const today = new Date().getTime();
  const diff = new Date(today - date);
  const months = diff.getMonth();
  const days = Math.trunc(diff.getTime() / (1000 * 3600 * 24));
  const hours = diff.getHours() - 1;
  const mins = diff.getMinutes();

  if (months > 0) return `Il y a ${months} mois`;
  else if (days > 0) return `Il y a ${days} ${plurials("jour", days)}`;
  else if (hours > 0) return `Il y a ${hours} ${plurials("heure", hours)}`;
  else if (mins > 0) return `Il y a ${mins} ${plurials("minute", days)}`;
  else return `Il y a moins d'une minutes`;
}

export default {
  dateToDDMMYYYY,
  dateToDDMMYYYYAndHM,
  dateToDDMMYYYYLong,
  formatThereIsDate,
  dateToDDMMYYYYAndHHMMSS
};
