import axios from "axios";
import { Document } from "../models/Document";
import { DataPaginate } from "../models/utils/DataPaginate";
import { getParamsUrl } from "./utils/serviceHelper";

function list(
  page: number,
  client_id: string | null,
  start_date?: string,
  end_date?: string,
  source?: string
) {
  let paramsUrl = getParamsUrl([
    { key: "page", value: page },
    { key: "start_date", value: start_date },
    { key: "end_date", value: end_date },
    { key: "client_id", value: client_id },
    { key: "source", value: source },
  ]);

  return axios
    .get(`${process.env.REACT_APP_API_URL}documents${paramsUrl}`)
    .then((response: any) => {

      const dataPaginate = new DataPaginate(response.data);
      dataPaginate.setData(dataPaginate.data.map((item) => new Document(item)));

      return dataPaginate;
    });
}

function find(id: string) {
  return axios
    .get(`${process.env.REACT_APP_API_URL}documents/${id}`)
    .then((response) => new Document(response.data));
}

const generatePdfUrl = (doc: Document) => {
  return `${process.env.REACT_APP_API_URL}documents/${doc.document_id}/download?code=${doc.code}&class_code=${doc.class_code}`;
};

export default {
  list,
  find,
  generatePdfUrl,
};
