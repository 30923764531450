const sources = [
    {
        value: "",
        label: "App & Site Web"
    },
    {
        value: "WEB",
        label: "Site Web"
    },
    {
        value: "APP",
        label: "App mobile"
    },
]

export default sources