export class Document {
  type_document: string;
  document_id: string;
  class_code: string;
  code: string;
  created_at: string;

  constructor(data: any) {
    this.type_document = data.type_document;
    this.document_id = data.document_id;
    this.class_code = data.class_code;
    this.code = data.code;
    this.created_at = data.created_at;
  }
}
