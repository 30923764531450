import axios from "axios";
import { Client } from "../models/Client";
import { User } from "../models/User";
import { DataPaginate } from "../models/utils/DataPaginate";
import { IFilter } from "../models/utils/filter/FilterType.interface";
import { convertFiltersToQuery } from "./utils/serviceHelper";

function list(page: number, search?: string, filters?: IFilter[]) {
  let params = `?page=${page}`;
  if (search) {
    params = `${params}&search=${search}`;
  }
  if (filters) {
    params = `${params}${convertFiltersToQuery(filters)}`;
  }

  return axios
    .get(`${process.env.REACT_APP_API_URL}admins/clients${params}`)
    .then((response: any) => {
      const dataPaginate = new DataPaginate(response.data);
      dataPaginate.setData(dataPaginate.data.map((item) => new Client(item)));

      return dataPaginate;
    });
}

function find(id: string) {
  return axios
    .get(`${process.env.REACT_APP_API_URL}admins/clients/${id}`)
    .then((response) => new Client(response.data));
}

function patchDisable(id: any, value: boolean) {
  return axios
    .patch(
      `${process.env.REACT_APP_API_URL}admins/clients/${id}/disable?value=${value}`
    )
    .then((response) => new Client(response.data));
}

//TODO - Voir avec Loïc pour la route API qui permet de récupérer les utilisateurs d'un client
function findUsers(clientId: number, page: number, search?: string, filters?: IFilter[]){
  let params = `?page=${page}`;
  if (search) {
    params = `${params}&search=${search}`;
  }
  if (filters) {
    params = `${params}${convertFiltersToQuery(filters)}`;
  }

  return axios
    .get(`${process.env.REACT_APP_API_URL}admins${params}`)
    .then((response: any) => {
      const dataPaginate = new DataPaginate(response.data);
      dataPaginate.setData(dataPaginate.data.map((item) => new User(item)));

      return dataPaginate;
    });

}

export default {
  list,
  find,
  patchDisable,
  findUsers
};
