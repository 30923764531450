import {useCallback, useEffect, useState} from "react";
import { useLocation, useParams } from "react-router-dom";
import BackButton from "../../../../components/fundamentals/back-button";
import DownloadIcon from "../../../../components/fundamentals/icons/download-icon";
import Section from "../../../../components/fundamentals/section";
import Spinner from "../../../../components/fundamentals/spinner";
import StatusSelector from "../../../../components/fundamentals/status-selector";
import NotFound from "../../../../components/NotFound/NotFound";
import { Product } from "../../../../models/Product";
import productsService from "../../../../services/products.service";
import useNotification from "../../../../hooks/use-notification";
import {ProductDocument} from "../../../../models/ProductDocument";
import {Stock} from "../../../../models/Stock";
import agencesService from "../../../../services/agences.service";
import {Agency} from "../../../../models/Agency";

export default function ProductDetailPage() {
  let { id } = useParams();
  const [product, setProduct] = useState<Product>();
  const [productDocuments, setProductDocuments] = useState<ProductDocument[]>([]);
  const [productStocks, setProductStocks] = useState<Stock[]>([]);
  const [productAgencies, setProductAgencies] = useState<Agency[]>([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const notificationToast = useNotification();

  useEffect(() => {
    async function fetchProduct() {
      try {
        setLoading(true);
        if (id) {
          setProduct(await productsService.find(Number(id)));

          setProductDocuments(await productsService.findProductDocuments(Number(id)));

          const stocks = await productsService.findProductStock(Number(id))

          setProductStocks(stocks);

          const agencies = await Promise.all(
              stocks.map(async ({stock, agencyId}) => {
                return await agencesService.find(agencyId);
              })
          );

          setProductAgencies(agencies);
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    }
    fetchProduct();
  }, [id]);

  const changeVisibility = useCallback(async () => {
    if (!product) return;

    try {
      setLoading(true);

      const updatedProductId = await productsService.changeVisibility(product.id, product.is_disabled);

      setProduct(await productsService.find(updatedProductId));
    } catch (e: any) {
      notificationToast("Erreur", e.message, "error");
    } finally {
      setLoading(false);
    }
  }, [product]);

  const getStockAgency = useCallback((agencyId: number) => {
    return productStocks.find(stock => Number(stock.agencyId) === agencyId)!.stock.toString();
  }, [productStocks]);

  return (
    <div className="flex flex-col grow h-full">
      <div className="w-full flex flex-col grow">
        {!loading && product && (
          <div className="pb-5xlarge">
            <BackButton
              label={
                !location.search.includes("?from=orders")
                  ? "Retour aux produits"
                  : "Retour à la commande"
              }
              className="mb-xsmall"
            />
            <div className="grid grid-cols-12 gap-x-base">
              <div className="col-span-8 flex flex-col gap-y-xsmall">
                <Section
                  title={product.name}
                  forceDropdown
                  status={
                    <StatusSelector
                      isActive={!product.is_disabled}
                      activeState="Visible"
                      inactiveState="Non visible"
                      onChange={changeVisibility}
                    />
                  }
                >
                  <p className="inter-base-regular text-grey-50 mt-2 whitespace-pre-wrap">
                    {product.description}
                  </p>
                  {product.details.length > 0 &&
                      <div className="flex flex-col gap-y-3 mt-8">
                        {product.details.map((detail, index) => (
                            <Detail key={`detail-${detail.label}-${index}`} title={detail.label} value={detail.value}/>
                        ))}
                      </div>
                  }
                </Section>
                {/*Characteristics are not currently available*/}
                {false &&
                  <Section title={"Caractéristiques"} forceDropdown>
                    <div className="flex flex-col gap-y-3 mt-8">
                      <h2 className="inter-base-semibold">Dimensions</h2>
                      <Detail title="Hauteur" value="" />
                      <Detail title="Largeur" value="-" />
                      <Detail title="Longueur" value="-" />
                      <Detail title="Poids" value="-" />
                    </div>
                  </Section>
                }
                {productDocuments.length > 0 &&
                    <Section title={"Documents associés"} forceDropdown>
                      <div className="flex flex-col gap-y-3 mt-8">
                        {productDocuments.map((productDocument, index) =>
                            <a
                                key={`${productDocument.title}-${index}`}
                                className="flex justify-between items-center inter-base-regular text-grey-50 hover:cursor-pointer"
                                href={productDocument.url}
                                target="_blank"
                            >
                              <p>{productDocument.title}</p>
                                <DownloadIcon />
                            </a>
                        )}
                      </div>
                    </Section>
                }
              </div>
              <div className="flex flex-col col-span-4 gap-y-xsmall">
                <Section title={"Vignette"} forceDropdown>
                  <div className="flex flex-col gap-y-3 mt-8">
                    <div className="aspect-square flex items-center justify-center">
                      <img
                        src={
                          product.image_url
                            ? product.image_url
                            : "/images/placeholder-product.svg"
                        }
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = "/images/placeholder-product.svg";
                        }}
                        alt={`Alt`}
                        className="object-contain rounded-rounded w-full h-full"
                      />
                    </div>
                  </div>
                </Section>
                {productAgencies.length > 0 &&
                  <Section title={"Stock"}>
                    <div className="flex flex-col gap-y-3 mt-8">
                            <h2 className="inter-base-semibold">Agences</h2>
                            {productAgencies.map(productAgency =>
                                <Detail
                                    key={`${productAgency.name}-${productAgency.id}`}
                                    title={productAgency.name}
                                    value={getStockAgency(productAgency.id)}
                                />
                            )}
                    </div>
                  </Section>
                }
              </div>
            </div>
          </div>
        )}
        {loading && <Spinner size="large" variant="secondary" />}
        {!loading && !product && <NotFound />}
      </div>
    </div>
  );
}

type DetailProps = {
  title: string;
  value?: string | null;
};
const Detail = ({ title, value }: DetailProps) => {
  return (
    <div className="flex justify-between items-center inter-base-regular text-grey-50">
      <p>{title}</p>
      <p>{value ? value : "–"}</p>
    </div>
  );
};
