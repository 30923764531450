import { useEffect, useState } from "react";
import { Funnel, FunnelChart, Label, LabelList, ResponsiveContainer, Tooltip } from "recharts";
import { OrderTunnelAnalytics } from "../../../../models/OrderTunnelAnalytics";
import orderTunnelSteps, { orderFunnelDefault } from "../configs/orderTunnelSteps";

interface IProps {
    analytics: OrderTunnelAnalytics[]
}
export default function OrderFunnelGraph({analytics}: IProps) {
  const [funnelChartData, setFunnelChartData] = useState<any[]>([])

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      let analyticExist = funnelChartData.find(a => a.name == payload[0].name)
      return (
        <div className="rounded-rounded border bg-grey-0 border-grey-20 overflow-hidden w-fit h-fit p-2">
          <p>{`${payload[0].name} (${analyticExist && analytics[0] && analytics[0].sessions? parseFloat(((analyticExist.sessions / analytics[0].sessions)*100).toFixed(2).toString()) : 0}%)`}</p>
          <p>Total : {analyticExist ? analyticExist.sessions : 0}</p>
        </div>
      );
    }
  
    return null;
  };

  useEffect(() => {
      if (analytics){
        setFunnelChartData(orderTunnelSteps.map((o) => {
          let analyticExist = analytics.find(a => a.type.includes(o.value))
          if (analyticExist && analytics[0] && analytics[0].sessions) {
            return {value: parseFloat(((analyticExist.sessions / analytics[0].sessions)*100).toFixed(2).toString()), sessions: analyticExist.sessions, name: `${o.label}`, count: analyticExist.sessions, fill: o.fill}
          } else {
            return {value: 0, sessions: 0, name: `${o.label}`, count: 0, fill: o.fill}
          }
        }))
      }
  }, [analytics])

  return (
    <div className="flex flex-col grow">
        <ResponsiveContainer width="100%" height={600}>    
          <FunnelChart width={730} height={250}>
            <Funnel
              dataKey="value"
              data={orderFunnelDefault}
              isAnimationActive
              fillOpacity={0.6}
              width={'80%'}
            >
              <LabelList position="right" fillOpacity={1} stroke={"none"} dataKey="name" />
            </Funnel>
            <Funnel
              dataKey="value"
              data={funnelChartData}
              isAnimationActive
              fill="red"
              width={'80%'}
            >
              <LabelList position="inside" fillOpacity={1} fill={"white"} stroke={"none"} dataKey="value" formatter={(e:any) => `${e}%`}/>
            </Funnel>
            <Tooltip content={CustomTooltip}/>
          </FunnelChart>
        </ResponsiveContainer>
    </div>
  );
}
