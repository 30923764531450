import axios from "axios";
import { Promotion } from "../models/Promotion";
import { DataPaginate } from "../models/utils/DataPaginate";
import { IFilter } from "../models/utils/filter/FilterType.interface";
import {convertFiltersToQuery} from "./utils/serviceHelper";
import {PromotionFormData} from "../pages/logged/promotions/modal-promotion/ModalPromotion";

function list(page: number, search?: string, filters?: IFilter[]) {
  let params = `?page=${page}`;
  if (search) {
    params = `${params}&search=${search}`;
  }
  if (filters) {
    params = `${params}${convertFiltersToQuery(filters)}`;
  }

  return axios
    .get(`${process.env.REACT_APP_API_URL}promotions${params}`)
    .then((response: any) => {
      const dataPaginate = new DataPaginate(response.data);
      dataPaginate.setData(
        dataPaginate.data.map((item) => new Promotion(item))
      );

      return dataPaginate;
    });
}

function find(id: number) {
  return axios
    .get(`${process.env.REACT_APP_API_URL}promotions/${id}`)
    .then((response) => new Promotion(response.data));
}

async function add(body: PromotionFormData): Promise<Promotion> {
    return new Promise(async (resolve, reject) => {
        const promotion = getApiPromotionFormat(body);

        axios.post(`${process.env.REACT_APP_API_URL}promotions`, promotion)
            .then((response) => {
                resolve(new Promotion(response.data))
            })
            .catch((error) => {
                reject({status: error.response.status, message: "Veuillez contacter un administrateur"})
            });
    });
}

async function update(id: number, body: PromotionFormData & {order: number}) {
    return new Promise((resolve, reject) => {
        const promotion = getApiPromotionFormat(body);

        axios.put(`${process.env.REACT_APP_API_URL}promotions/${id}`, promotion)
            .then((response) => {
                resolve(new Promotion(response.data))
            })
            .catch((error) => {
                reject({status: error.response.status, message: "Veuillez contacter un administrateur"})
            });
    })
}

function getApiPromotionFormat(body: PromotionFormData) {
    const promotion: any = {...body, img_base64: body.image.base64};

    delete promotion.image;

    return promotion;
}

async function deletePromotion(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
        axios.delete(`${process.env.REACT_APP_API_URL}promotions/${id}`)
            .then((response) => {
                resolve();
            })
            .catch((error: any) => {
                switch (error.response.status) {
                    case 404:
                        reject({status: error.response.status, message: "Promotion non trouvée"});
                        break;
                    default:
                        reject({status: error.response.status, messagel: "Veuillez contacter un adminstrateur"});
                }
            })
    })
}

export default {
  list,
  find,
  add,
  update,
  deletePromotion,
};
