import { User } from "../../../models/User";
import Sidebar from "../sidebar";
import Topbar from "../topbar";

interface IProps{
    user: User;
    children?: any
}
export default function Layout({user, children}: IProps){
    return  <div className="flex w-full h-screen inter-base-regular text-grey-90">
        <Sidebar />
        <div className="flex flex-col flex-1">
            <Topbar user={user} />
            <div className="large:px-xlarge py-xlarge bg-grey-5 min-h-content overflow-y-auto">
                <main className="xsmall:mx-base small:mx-xlarge medium:mx-4xlarge large:mx-auto large:max-w-7xl large:w-full h-full">
                    {children}
                </main>
            </div>
        </div>
  
  </div>
}