//time steps values are in string to get the default selected working
const timeSteps = [
    {
      label: "Dernières 24h",
      value: {step: 1, type: "D"}
    },
    {
      label: "7 derniers jours",
      value: {step: 7, type: "D"}
    },
    {
      label: "14 derniers jours",
      value: {step: 14, type: "D"}
    },
    {
      label: "Dernier mois",
      value: {step: 1, type: "M"}
    },
    {
      label: "3 dernier mois",
      value: {step: 3, type: "M"}
    },
    {
      label: "6 dernier mois",
      value: {step: 6, type: "M"}
    },
    {
      label: "Dernière année",
      value: {step: 12, type: "M"}
    },
]

export default timeSteps