import { Client } from "./Client";
import { Product } from "./Product";

class Pickup {
  public id: number;
  public address: string;
  public city: string;
  public display_name: string;
  public name: string;
  public division_code: string;
  public emails_notification: boolean;
  public enable: boolean;
  public image_url: string;
  public is_platform: boolean;
  public phone_number: string;
  public pickup_code: string;
  public postal_code: string;
  public created_at: Date;
  public updated_at: Date;

  constructor(data: any) {
    this.id = data.id;
    this.address = data.address;
    this.city = data.city;
    this.display_name = data.display_name;
    this.name = data.name;
    this.division_code = data.division_code;
    this.emails_notification = data.emails_notification;
    this.enable = data.enable;
    this.image_url = data.image_url;
    this.is_platform = data.is_platform;
    this.phone_number = data.phone_number;
    this.pickup_code = data.pickup_code;
    this.postal_code = data.postal_code;
    this.created_at = new Date(data.created_at);
    this.updated_at = new Date(data.updated_at);
  }
}

export class ProductOrder {
  public id: number;
  public status: string;
  public quantity: number;
  public amount: number;
  public product_id: number;
  public user_id: number;
  public order_id: number;
  public product: Product;
  public created_at: Date;
  public updated_at: Date;

  constructor(data: any) {
    this.id = data.id;
    this.status = data.status;
    this.quantity = data.quantity;
    this.amount = data.amount;
    this.product_id = data.product_id;
    this.user_id = data.user_id;
    this.order_id = data.order_id;
    this.product = new Product(data.product);
    this.created_at = new Date(data.created_at);
    this.updated_at = new Date(data.updated_at);
  }
}

export class Order {
  public id: number;
  public status: string;
  public total_amount: number;
  public total_quantity: number;
  public address: string;
  public compl_address: string;
  public postal_code: string;
  public city: string;
  public reference: string;
  public comment: string;
  public file_url: string;
  public client_id: number;
  public applicatif_id: number;
  public pickup_id: number;
  public pickup: Pickup;
  public client: Client;
  public product_orders_with_product: Array<ProductOrder>;
  public created_at: Date;
  public updated_at: Date;

  constructor(data: any) {
    this.id = data.id;
    this.status = data.status;
    this.total_amount = data.total_amount;
    this.total_quantity = data.total_quantity;
    this.address = data.address;
    this.compl_address = data.compl_address;
    this.postal_code = data.postal_code;
    this.city = data.city;
    this.reference = data.reference;
    this.comment = data.comment;
    this.file_url = data.file_url;
    this.client_id = data.client_id;
    this.applicatif_id = data.applicatif_id;
    this.pickup_id = data.pickup_id;
    this.pickup = data.pickup;
    this.client = new Client(data.client);
    this.product_orders_with_product =
      data?.product_orders_with_product?.length > 0
        ? data.product_orders_with_product.map(
            (data: ProductOrder) => new ProductOrder(data)
          )
        : [];
    this.created_at = new Date(data.created_at);
    this.updated_at = new Date(data.updated_at);
  }

  getPickUpAddress(): string | null {
    if (!this.pickup) return null;
    else {
      return `${this.pickup.address ?? ""} / ${this.pickup.postal_code ?? ""} ${
        this.pickup.city ?? ""
      }`;
    }
  }
}
