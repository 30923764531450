import clsx from "clsx";
import { useEffect, useMemo, useState } from "react";
import { LoaderIcon } from "react-hot-toast";
import { LoadingIndicator } from "react-select/dist/declarations/src/components/indicators";
import Actionables, {
  ActionType,
} from "../../../../components/fundamentals/actionables/actionables";
import ChevronDownLightIcon from "../../../../components/fundamentals/icons/chevron-down-light";
import ChevronRightLightIcon from "../../../../components/fundamentals/icons/chevron-right-light";
import DragDropIcon from "../../../../components/fundamentals/icons/DragDropIcon";
import EditIcon from "../../../../components/fundamentals/icons/edit-icon";
import Spinner from "../../../../components/fundamentals/spinner";
import StatusIndicator from "../../../../components/fundamentals/status-indicator";
import StatusSelector from "../../../../components/fundamentals/status-selector";
import Table from "../../../../components/fundamentals/table";
import { IitemDrag } from "../../../../hooks/use-draggable";
import { Category } from "../../../../models/Category";
import { TopViewAnalytics } from "../../../../models/TopViewAnalytics";
import categoryService from "../../../../services/category.service";
import productsService from "../../../../services/products.service";

interface IProps {
  topView: TopViewAnalytics;
}
export function TopViewRow({topView}: IProps) {
  return (
    <Table.Row
      color={"inherit"}
    >
      <Table.Cell className="first-letter:uppercase lowercase">{topView.payload.name}</Table.Cell>
      <Table.Cell>{topView.payload.ean}</Table.Cell>
      <Table.Cell className="uppercase">{topView.payload.code_product}</Table.Cell>
      <Table.Cell>{topView.analytics.visitors}</Table.Cell>
      <Table.Cell>{topView.analytics.views}</Table.Cell>
    </Table.Row>
  );
}
