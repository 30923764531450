import axios from "axios";
import { Price } from "../models/Price";
import { Product } from "../models/Product";
import { Stock } from "../models/Stock";
import { DataPaginate } from "../models/utils/DataPaginate";
import { IFilter } from "../models/utils/filter/FilterType.interface";
import { convertFiltersToQuery, getParamsUrl } from "./utils/serviceHelper";
import {ProductDocument} from "../models/ProductDocument";

function list(
  page?: number | null,
  search?: string | null,
  filters?: IFilter[] | null,
  category_id?: number | null
) {
  let params;
  if (filters) {
    params = `${convertFiltersToQuery(filters)}`;
    console.log(params)
  }
  let paramsUrl = getParamsUrl([
    { key: "page", value: page },
    { key: "search", value: search },
    { key: "category_id", value: category_id },
  ]);
  params = `${params ?? ""}${paramsUrl}`;

  return axios
    .get(`${process.env.REACT_APP_API_URL}products${params}`)
    .then((response: any) => {
      const dataPaginate = new DataPaginate(response.data);
      dataPaginate.setData(dataPaginate.data.map((item) => new Product(item)));

      return dataPaginate;
    });
}

/*
* Currently no taking account of filters
*/
function listWithElastic(searchValue: string, page: number): Promise<DataPaginate> {
    return new Promise((resolve, reject) => {
        axios.get(`${process.env.REACT_APP_API_URL}products/elasticsearch?search=${searchValue}&tag=backend&page=${page}`)
            .then((response) => {
                const dataPaginate = new DataPaginate(response.data, true);

                dataPaginate.setData(dataPaginate.results.map(item => new Product(item)))

                resolve(dataPaginate);
            })
            .catch((error) => {
                reject({status: error.rsponse.status, message: "Veuillez contacter un administrateur"});
            })
    })
}

function search(page: number, search?: string, filters?: IFilter[]){
  let params = `?page=${page}&tag=Backend`;
    if(search){
        params = `${params}&search=${search}`;
    }
    if(filters){
      params = `${params}${convertFiltersToQuery(filters)}`
    }

  return axios.get(`${process.env.REACT_APP_API_URL}products/elasticsearch${params}`)
    .then((response: any) => {
      const dataPaginate = new DataPaginate(response.data);
        dataPaginate.setData(dataPaginate.results.map(item => new Product(item)));

        return dataPaginate;
    })
}

function find(id: number) {
  return axios
    .get(`${process.env.REACT_APP_API_URL}products/${id}`)
    .then((response) => new Product(response.data));
}

function findProductPrice(id: number) {
  return axios
    .get(`${process.env.REACT_APP_API_URL}products/${id}/prices`)
    .then((response) => new Price(response.data));
}

function findProductStock(id: number): Promise<Stock[]> {
  return axios
    .get(`${process.env.REACT_APP_API_URL}products/${id}/stocks`)
    .then((response) =>
      response.data.stock.map((stock: any) => new Stock(stock))
    );
}

function getTotalStock(stocks: Stock[]) {
  return stocks.reduce((acc, cur) => {
    if (cur.stock >= 0) {
      return acc + cur.stock;
    }
    return acc;
  }, 0);
}

function importFile(file: File, type: string){
  const formData = new FormData();
  formData.append('file', file);
  return axios
    .post(`${process.env.REACT_APP_API_URL}products/import/${type}`, formData )
    .then((response) =>response.data);
}

function changeVisibility(id: number, visibility: boolean): Promise<number> {
    return new Promise((resolve, reject) => {
        axios.patch(`${process.env.REACT_APP_API_URL}products/${id}/visibility?visible=${visibility}`)
            .then((response) => {
                resolve(response.data.id);
            })
            .catch((error) => {
                switch (error.response.status) {
                    case 404:
                        reject({status: error.response.status, message: 'Produit inconnu'});
                        break;
                    default:
                        reject({status: error.response.status, message: "Veuillez contacter un administrateur"});
                }
            })
    })
}

function findProductDocuments(id: number): Promise<ProductDocument[]> {
    return new Promise((resolve, reject) => {
       axios.get(`${process.env.REACT_APP_API_URL}products/${id}/documents`)
           .then((response) => {
               if (response.data.length === 0) {
                   resolve([]);
                   return;
               }

               const productDocuments = response.data.map((productDocument: any) => new ProductDocument(productDocument));

               resolve(productDocuments);
           })
           .catch((error) => {
               switch (error.response.status) {
                   case 404:
                       reject({status: error.response.status, message: 'Produit inconnu'});
                       break;
                   default:
                       reject({status: error.response.status, message: "Erreur inconnue"});
               }
           })
    });
}

export default {
    list,
    listWithElastic,
    search,
    find,
    findProductPrice,
    findProductStock,
    findProductDocuments,
    getTotalStock,
    importFile,
    changeVisibility,
}
