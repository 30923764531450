export const pages:
    {
        [key: string]: {
            value: string,
            label: string
        }[]
    } 
= {
    APP: [
        {
            value: "HomeScreen",
            label: "Accueil"
        },
        // {
        //     value: "Loading",
        //     label: "Chargement"
        // },
        {
            value: "LoginScreen",
            label: "Ecran de connexion"
        },
        {
            value: "CreateAccountValidationScreen",
            label: "Création de compte demandée",
        },
        {
            value: "CreateAccountValidationScreen",
            label: "Création de compte demandée",
        },
        // ListUniversesScreen: "Toutes les catégories",
        // ListSubUniversesScreen: "Liste sous-catégories",
        {
            value: "DetailOrderScreen",
            label: "Détails d'une commande",
        },
        {
            value: "ScanScreen",
            label: "Scan",
        },
        {
            value: "ClientScreen",
            label: "Client",
        },
        {
            value: "FAQScreen",
            label: "FAQ",
        },
        {
            value: "ListOrderScreen",
            label: "Liste des commandes",
        },
        {
            value: "LegalScreen",
            label: "Mentions légales",
        },
        {
            value: "DataProtectionScreen",
            label: "Protection des données",
        },
        {
            value: "DataProtectionScreen",
            label: "Protection des données",
        },
        {
            value: "DeliveryDetailsScreen",
            label: "Choix de livraison",
        },
    ],
    WEB: [
        {
            value: "ArticlesScreen",
            label: "Liste des Articles",
        },
        {
            value: "DetailArticle",
            label: "Article",
        },
        {
            value: "DetailCategory",
            label: "Catégorie",
        },
        {
            value: "Contact",
            label: "Contact",
        },
        {
            value: "BrandsList",
            label: "Liste des marques",
        },
        {
            value: "Brand",
            label: "Marque",
        },
        {
            value: "Error404",
            label: "Page non trouvée",
        },
        {
            value: "Promotions",
            label: "Promotions",
        },
    ],
    BOTH : [
        {
            value: "",
            label: "Toutes les pages"
        },
        {
            value: "AgenciesScreen",
            label: "Liste des agences",
        },
        {
            value: "DetailAgencieScreen",
            label: "Agence",
        },
        {
            value: "ListCategoriesScreen",
            label: "Catégories",
        },
        {
            value: "AccountScreen",
            label: "Compte",
        },
        {
            value: "ListShoppingScreen",
            label: "Listes d'achats",
        },
        {
            value: "ListShoppingDetailScreen",
            label: "Détails d'une liste d'achat",
        },
        {
            value: "ListDocumentsScreen",
            label: "Listes des documents",
        },
        {
            value: "CartScreen",
            label: "Panier",
        },
        {
            value: "ValidateOrderScreen",
            label: "Validation de commande",
        },
        {
            value: "ConfirmOrderScreen",
            label: "Commande confirmée",
        },
        {
            value: "DetailProductScreen",
            label: "Détails d'un produit",
        },
        {
            value: "ListProductsScreen",
            label: "Liste des produits",
        },
        {
            value: "CreateAccountScreen",
            label: "Création de compte",
        },
        {
            value: "SearchScreen",
            label: "Recherche",
        },
    ]
}

export default pages