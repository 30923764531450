import { useEffect, useState } from "react";
import { DataPaginate } from "../../../../../models/utils/DataPaginate";
import productsService from "../../../../../services/products.service";
import useProductTableColumn from "./use-product-column";
import Table from "../../../../../components/fundamentals/table";
import { Product } from "../../../../../models/Product";
import ListIcon from "../../../../../components/fundamentals/icons/list-icon";
import TileIcon from "../../../../../components/fundamentals/icons/tile-icon";
import clsx from "clsx";
import ProductList from "../product-list/ProductList";
import TableContainer from "../../../../../components/fundamentals/table-container";
import {
  EFilter,
  IFilter,
} from "../../../../../models/utils/filter/FilterType.interface";
import ProductRow from "./ProductRow";
import {getTablePagingState} from "../../../../../services/utils/TableHelper";

export enum columnProduct {
  SELECT = "select",
  NAME = "Nom du produit",
  PRICE = "Prix à l'unité",
  EAN = "EAN13",
  STOCK = "Stock global",
  VISIBILITY = "Visibilité",
  ACTION = `action`,
  REMOVE = ''
}

interface IProps {
  columns?: columnProduct[],
  selected?: Product[],
  setSelected?: (products: Product[]) => void
  displaySelected?: boolean,
  className?: string,
  useElasticSearch?: boolean
}
export default function ProductTable( 
  {
    columns = [
                columnProduct.NAME,
                columnProduct.PRICE,
                columnProduct.EAN,
                columnProduct.STOCK,
                columnProduct.VISIBILITY,
                columnProduct.ACTION
              ],
    selected,
    setSelected,
    displaySelected = false,
    className,
    useElasticSearch = false
  }: IProps) {
  const [dataPaginate, setDataPaginate] = useState<DataPaginate>();
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState<string>("");
  const [tmpSearch, setTmpSearch] = useState<string>("");
  const [showList, setShowList] = useState(true);
  const [limit, setLimit] = useState(10);
  const [filters, setFilters] = useState<IFilter[]>();

  const setTileView = () => {
    setLimit(10);
    setShowList(false);
  };
  const setListView = () => {
    setLimit(10);
    setShowList(true);
  };
  // const [columns] = useProductTableColumn({
  //   setTileView,
  //   setListView,
  //   showList,
  // });
    async function fetchProducts() {
        setIsLoading(true);

        let dataPaginate: DataPaginate;

        if (useElasticSearch) {
            dataPaginate = await productsService.listWithElastic(search, currentPage);
        } else {
            dataPaginate = await productsService.list(currentPage, search, filters);
        }

        setDataPaginate(dataPaginate);

        setIsLoading(false);
    }

  useEffect(() => {
    !displaySelected && fetchProducts();
  }, [currentPage, search, filters]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setCurrentPage(1);
      setSearch(tmpSearch);
    }, 400);

    return () => clearTimeout(delayDebounceFn);
  }, [tmpSearch]);
  // ---- FILTERS ----
  // useEffect(() => {
  //   setFilters([
  //     {
  //       name: "status",
  //       type: EFilter.SELECT_MULTIPLE,
  //       label: "Status",
  //       list: [
  //         {
  //           label: "proposed",
  //           value: "proposed",
  //         },
  //         {
  //           label: "draft",
  //           value: "draft",
  //         },
  //         {
  //           label: "published",
  //           value: "published",
  //         },
  //         {
  //           label: "rejected",
  //           value: "rejected",
  //         },
  //       ],
  //     },
  //     {
  //       name: "category_id",
  //       type: EFilter.SELECT_MULTIPLE,
  //       label: "Category",
  //       list: [
  //         {
  //           label: "Category1",
  //           value: 1,
  //         },
  //       ],
  //     },
  //     {
  //       name: "tags",
  //       type: EFilter.TAG,
  //       label: "Tags",
  //     },
  //   ]);
  // }, []);

  return (
    <div 
      className={clsx(
        className
      )}
    >
      <TableContainer
        numberOfRows={displaySelected ? selected?.length : 10}
        hasPagination={!displaySelected}
        pagingState={getTablePagingState(dataPaginate!, setCurrentPage, 'résultats')}
        isLoading={isLoading}
      >
        <Table
          enableSearch={displaySelected ? false : true}
          searchValue={tmpSearch}
          handleSearch={(term) => setTmpSearch(term)}
          // filters={displaySelected ? undefined : filters}
          // onClear={() => {
          //   setCurrentPage(1);
          //   if (filters) {
          //     setFilters(
          //       filters.map((f) => {
          //         f.value = undefined;
          //         return f;
          //       })
          //     );
          //   }
          // }}
          // onFilter={(filters: IFilter[]) => {
          //   setCurrentPage(1);
          //   setFilters(filters);
          // }}
        >
          {showList ? (
            <>
              <Table.Head>
                <Table.HeadRow>
                  {columns.map((col: columnProduct, index) => {
                    if (col === columnProduct.ACTION) {
                      return (
                        <Table.HeadCell key={`product-head-cell-action-${index}`}>
                          <div className="text-right flex justify-end">
                            <span
                              onClick={setListView}
                              className={clsx(
                                "hover:bg-grey-5 cursor-pointer rounded p-0.5",
                                {
                                  "text-grey-90": showList,
                                  "text-grey-40": !showList,
                                }
                              )}
                            >
                              <ListIcon size={20} />
                            </span>
                            <span
                              onClick={setTileView}
                              className={clsx(
                                "hover:bg-grey-5 cursor-pointer rounded p-0.5",
                                {
                                  "text-grey-90": !showList,
                                  "text-grey-40": showList,
                                }
                              )}
                            >
                              <TileIcon size={20} />
                            </span>
                          </div>
                        </Table.HeadCell>
                      )
                    } else if (col === columnProduct.SELECT) {
                      return (
                        <Table.HeadCell key={`product-head-cell-select-${index}`}>
                        </Table.HeadCell>
                      )
                    } else return (
                      <Table.HeadCell key={`product-head-cell-other-${index}`}>{col}</Table.HeadCell>
                    )
                  })}
                </Table.HeadRow>
              </Table.Head>
              <Table.Body>
                {showList && !displaySelected &&
                  dataPaginate?.data.map((product: Product) => (
                    <ProductRow
                      selected={selected}
                      setSelected={setSelected}
                      key={`product_item_${product.id}`}
                      product={product}
                      columns={columns}
                      refreshProductsData={fetchProducts}
                    />
                  ))}
                {displaySelected && selected &&
                  selected.map((product: Product) => (
                    <ProductRow
                      selected={selected}
                      setSelected={setSelected}
                      key={`product_item_only_display_${product.id}`}
                      product={product}
                      columns={columns}
                      refreshProductsData={fetchProducts}
                    />
                  ))
                }
              </Table.Body>
            </>
          ) : (
            <ProductList
              products={dataPaginate ? dataPaginate.data : []}
              toggleListView={setListView}
            />
          )}
        </Table>
      </TableContainer>
    </div>
  );
}
