import axios from "axios";
import { Order } from "../models/Order";
import { DataPaginate } from "../models/utils/DataPaginate";
import { IFilter } from "../models/utils/filter/FilterType.interface";
import { convertFiltersToQuery } from "./utils/serviceHelper";

function list(page: number, search?: string, filters?: IFilter[], clientId?: number) {
  let params = `?page=${page}`;
  if (search) {
    params = `${params}&search=${search}`;
  }
  if (filters) {
    params = `${params}${convertFiltersToQuery(filters)}`;
  }
  if(clientId){
    params = `${params}&client_id=${clientId}`
  }

  return axios
    .get(`${process.env.REACT_APP_API_URL}orders${params}`)
    .then((response: any) => {
      const dataPaginate = new DataPaginate(response.data);
      dataPaginate.setData(dataPaginate.data.map((item) => new Order(item)));

      return dataPaginate;
    });
}

function find(id: string) {
  return axios
    .get(`${process.env.REACT_APP_API_URL}orders/${id}`)
    .then((response) => new Order(response.data));
}

export default {
  list,
  find,
};
