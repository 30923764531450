import clsx from "clsx"
import React from "react"
import SectionCollapsible from "../section-collapsible"
import useKeyboardNavigationList from "../use-keyboard-navigation-list"
import { Link } from "react-router-dom"
import ImagePlaceholder from "../../image-placeholder/image-placeholder"
import { Product } from "../../../../models/Product"
import { DataPaginate } from "../../../../models/utils/DataPaginate"

type ProductResultsProps = {
  dataPaginated: DataPaginate
  getLIProps: ReturnType<typeof useKeyboardNavigationList>["getLIProps"]
  offset: number
  selected: number,
  onScrollBottom: () => void
}

const ProductResults = ({
  dataPaginated,
  getLIProps,
  offset,
  selected,
  onScrollBottom
}: ProductResultsProps) => {

  const handleScroll = (e: any) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) { 
      onScrollBottom()
    }
  }

  return dataPaginated.data.length > 0 ? (
    <SectionCollapsible title={"Produit"} length={dataPaginated.total || 0}>
      <div className="mt-large" style={{overflow: 'scroll', maxHeight: 300}} onScroll={handleScroll}>
        <div className="flex flex-col">
          {/*dataPaginated.data?.slice(0, 10).map((product: Product, index: any) => (*/
          dataPaginated.data?.map((product: Product, index: any) => (
            <li
              {...getLIProps({
                index: offset + index,
              })}
              className={clsx(
                "px-base py-1.5 group focus:bg-grey-5 rounded-rounded",
                {
                  "bg-grey-5": selected === offset + index,
                }
              )}
            >
              <Link
                to={`/products/${product.id}`}
                className="flex items-center justify-between"
              >
                <div className="flex items-center gap-x-3">
                  {product.img_url_min ?  <img
                    src={product.img_url_min}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = "/images/placeholder-product.svg";
                    }}
                    className="h-[32px] w-[24px] object-cover rounded"
                  /> : <div className="h-[32px] w-[24px] object-cover rounded"><ImagePlaceholder /></div>}
                 
                  <p className="inter-small-regular text-grey-90">
                    {product.name}
                  </p>
                </div>
                <span
                  className={clsx(
                    "group-focus:visible text-grey-40 inter-small-regular",
                    {
                      invisible: selected !== offset + index,
                    }
                  )}
                >
                  Jump to...
                </span>
              </Link>
            </li>
          ))}
        </div>
      </div>
    </SectionCollapsible>
  ) : null
}

export default ProductResults
