import { useEffect, useState } from "react";
import { DataPaginate } from "../../../../../models/utils/DataPaginate";
import Table from "../../../../../components/fundamentals/table";
import TableContainer from "../../../../../components/fundamentals/table-container";
import {
  EFilter,
  IFilter,
} from "../../../../../models/utils/filter/FilterType.interface";
import OrderRow from "./OrderRow";
import { Order } from "../../../../../models/Order";
import ordersService from "../../../../../services/orders.service";
import clientsService from "../../../../../services/clients.service";
import {getTablePagingState} from "../../../../../services/utils/TableHelper";

export default function OrderTable() {
  const [dataPaginate, setDataPaginate] = useState<DataPaginate>();
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState<string>();
  const [tmpSearch, setTmpSearch] = useState<string>();
  const [limit, setLimit] = useState(10);
  const [filters, setFilters] = useState<IFilter[]>();

  // const [columns] = useOrderTableColumn({
  //   setTileView,
  //   setListView,
  //   showList,
  // })

  useEffect(() => {
    async function fetchOrders() {
      setIsLoading(true);
      setDataPaginate(await ordersService.list(currentPage, search, filters));
      setIsLoading(false);
    }
    fetchOrders();
  }, [currentPage, search, filters]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setCurrentPage(1);
      setSearch(tmpSearch);
    }, 400);

    return () => clearTimeout(delayDebounceFn);
  }, [tmpSearch]);
  
  // ---- FILTERS -----
  // useEffect(() => {
  //   async function fetchFilters() {
  //     const clients = await clientsService.list(currentPage, search, filters);
  //     const clientsList = clients.data.map((c) => {
  //       return {
  //         label: c.name,
  //         value: c.id,
  //       };
  //     });
  //     setFilters([
  //       {
  //         name: "date",
  //         type: EFilter.DATE,
  //         label: "Date",
  //       },
  //       {
  //         name: "client",
  //         type: EFilter.SELECT_MULTIPLE,
  //         label: "Client",
  //         list: clientsList,
  //       },
  //       {
  //         name: "sell_canal",
  //         type: EFilter.SELECT_MULTIPLE,
  //         label: "Canal de vente",
  //         list: [
  //           {
  //             label: "Canal 1",
  //             value: 1,
  //           },
  //           {
  //             label: "Canal 2",
  //             value: 2,
  //           },
  //         ],
  //       },
  //       {
  //         name: "pick_up_address",
  //         type: EFilter.SELECT_MULTIPLE,
  //         label: "Adresse de retrait",
  //         list: [
  //           {
  //             label: "Adresse 1",
  //             value: 1,
  //           },
  //           {
  //             label: "Adresse 2",
  //             value: 2,
  //           },
  //         ],
  //       },
  //     ]);
  //   }
  //   fetchFilters();
  // }, []);

  return (
    <div>
      <TableContainer
        numberOfRows={10}
        hasPagination
        pagingState={getTablePagingState(dataPaginate!, setCurrentPage, "résultats")}
        isLoading={isLoading}
      >
        <Table
          enableSearch={true}
          searchValue={tmpSearch}
          handleSearch={(term) => setTmpSearch(term)}
          // filters={filters}
          // onClear={() => {
          //   setCurrentPage(1);
          //   if (filters) {
          //     setFilters(
          //       filters.map((f) => {
          //         f.value = undefined;
          //         return f;
          //       })
          //     );
          //   }
          // }}
          // onFilter={(filters: IFilter[]) => {
          //   setCurrentPage(1);
          //   setFilters(filters);
          // }}
        >
          <Table.Head>
            <Table.HeadRow>
              <Table.HeadCell>Id</Table.HeadCell>
              <Table.HeadCell>Date</Table.HeadCell>
              <Table.HeadCell>Entreprise</Table.HeadCell>
              <Table.HeadCell>Adresse de retrait</Table.HeadCell>
              <Table.HeadCell>Total HT</Table.HeadCell>
            </Table.HeadRow>
          </Table.Head>
          <Table.Body>
            {dataPaginate?.data.map((order: Order, index: number) => (
              <OrderRow
                key={`order_item_${order.id}`}
                order={order}
                index={index + 1}
              />
            ))}
          </Table.Body>
        </Table>
      </TableContainer>
    </div>
  );
}
