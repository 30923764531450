import React from "react"
import IconProps from "../types/icon-type"

const Ca2eIcon: React.FC<IconProps> = ({
  size = "50",
  color = "currentColor",
  ...attributes
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...attributes}
    >
        <path d="M23.2226 13.8894C24.3246 14.253 25.0381 14.6139 25.0381 14.9913V21.523L30.8452 19.3467L31.5697 20.4486L31.9334 22.6249C32.297 25.1649 30.1179 26.9803 25.7626 28.0685H25.0408L25.7653 31.6966V32.0602C25.7951 32.6752 25.6802 33.2887 25.43 33.8513C25.1798 34.4139 24.8012 34.91 24.3246 35.2999H22.8645C21.4127 35.2999 20.6882 34.9362 20.3246 34.1979V33.4734L19.9609 29.1181L16.6937 29.4817C15.2419 29.4817 14.5174 29.118 14.1538 28.3798V27.6552C13.9708 26.8102 14.0082 25.9323 14.2624 25.1059C14.5167 24.2794 14.9792 23.5323 15.6056 22.9362L20.3246 22.5726L19.9609 19.669L19.5973 18.2172C19.2364 15.7048 20.6882 14.253 23.2226 13.8894Z" fill="#FFDF00"/>
        <path d="M22.5029 46.5508C16.5786 46.4852 10.9193 44.0858 6.75321 39.8734C2.58712 35.6609 0.250488 29.9754 0.250488 24.0508C0.250488 18.1262 2.58712 12.4406 6.75321 8.22817C10.9193 4.01573 16.5786 1.61633 22.5029 1.55078C26.5962 1.58297 30.6063 2.71056 34.1166 4.81642C37.6268 6.92228 40.5092 9.9296 42.4643 13.526C42.849 14.2964 42.9119 15.1881 42.6391 16.0049C42.3663 16.8217 41.7802 17.4967 41.0098 17.8814C40.2393 18.266 39.3476 18.3289 38.5308 18.0561C37.7141 17.7833 37.0391 17.1973 36.6544 16.4268C35.3463 13.7961 33.3263 11.5852 30.8242 10.0453C28.3221 8.50545 25.4381 7.69846 22.5001 7.71608C18.3611 7.85993 14.4397 9.60529 11.5625 12.5842C8.68528 15.5631 7.07718 19.5429 7.07718 23.6844C7.07718 27.8259 8.68528 31.8056 11.5625 34.7846C14.4397 37.7635 18.3611 39.5088 22.5001 39.6527C22.9297 39.6509 23.3554 39.7341 23.7526 39.8977C24.1498 40.0612 24.5107 40.3018 24.8145 40.6056C25.1182 40.9093 25.3588 41.2702 25.5224 41.6675C25.6859 42.0647 25.7692 42.4904 25.7674 42.9199C25.8058 43.3758 25.7505 43.8347 25.6049 44.2683C25.4592 44.702 25.2263 45.1012 24.9204 45.4414C24.6146 45.7816 24.2423 46.0555 23.8265 46.2463C23.4107 46.4371 22.9603 46.5407 22.5029 46.5508V46.5508Z" fill="#FFDF00"/>
    </svg>

  )
}

export default Ca2eIcon
