import Button from "../../../components/fundamentals/button";

type SignInWithGoogleProps = {
    isGoogleSignInLoading: boolean,
    googleSignInErrorMessage: string,
}

function SignInWithGoogle({isGoogleSignInLoading, googleSignInErrorMessage}: SignInWithGoogleProps) {
    return (
        <>
            <Button
                className={isGoogleSignInLoading ? "mt-3 w-[320px]" : "hidden"}
                variant="primary"
                size="medium"
                loading={isGoogleSignInLoading}
            />
            <div
                id="google-sign-in"
                className={isGoogleSignInLoading ? "hidden" : "mt-3"}>
            </div>
            {googleSignInErrorMessage &&
                <span className="text-rose-50 w-full mt-2 inter-small-regular text-center">
                    {googleSignInErrorMessage}
                </span>
            }
        </>
    )
}

export default SignInWithGoogle