import {SyntheticEvent, useCallback, useEffect, useMemo, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import useNotification from "../../../../hooks/use-notification";
import promotionsService from "../../../../services/promotions.service";
import {Promotion} from "../../../../models/Promotion";
import FocusModal from "../../../../components/fundamentals/modal/focus-modal";
import Accordion from "../../../../components/fundamentals/accordion";
import InputField from "../../../../components/form/input";
import Select from "../../../../components/form/select/next-select/select";
import DatePicker from "../../../../components/form/date-picker/date-picker";
import PlusIcon from "../../../../components/fundamentals/icons/plus-icon";
import FileUploadField from "../../../../components/form/file-upload-field/FileUploadField";
import {EErrors} from "../../../../models/utils/ErrorsHelper";
import {colorBasedOnBG} from "../../../../models/utils/ColorHelper";
import ModalProducts from "../modal-products/ModalProducts";
import {Product} from "../../../../models/Product";
import ProductTable, {columnProduct} from "../../products/products-page/product-table/ProductTable";
import moment from "moment";
import {ImageAspectRatio} from "../../../../types/utils";
import {
    getBase64fromFile,
    getBase64FromImageUrl,
    getImageNameFromImageUrl
} from "../../../../services/utils/serviceHelper";

interface IProps {
    handleClose: () => void;
    promotion?: Promotion;
    modalTitle: string
}

export type PromotionFormData = {
    name: string;
    visible: boolean;
    start_date: string;
    end_date: string;
    image: {base64: string, name: string};
    products: number[];
};

type PromotionImage = {
    imageUrl: string,
    base64: string,
    isBright: boolean,
    aspectRatio: ImageAspectRatio
}

const optionsVisible = [
    {value: true, label: "Visible"},
    {value: false, label: "Non visible"},
];

const initialPromotionImage: PromotionImage = {
    imageUrl: "",
    base64: "",
    isBright: true,
    aspectRatio: "4/3"
}

export default function ModalPromotion({promotion, handleClose, modalTitle}: IProps) {

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const {
        register,
        control,
        handleSubmit,
        setValue,
        formState: {isValid, isDirty, errors},
        getValues,
        setError,
        clearErrors,
        trigger,
    } = useForm<PromotionFormData>();
    const notification = useNotification();
    const [addProduct, setAddProduct] = useState(false)
    const [promotionImage, setPromotionImage] = useState<PromotionImage>(initialPromotionImage)
    const [selectedProducts, setSelectedProducts] = useState<Product[]>([])

    useEffect(() => {
        (async () => {
            if (promotion) {
                const fetchedPromotion = await promotionsService.find(promotion.id);

                const promotionImageBase64 = await getBase64FromImageUrl(fetchedPromotion.image_url);

                const promotionImageName = getImageNameFromImageUrl(fetchedPromotion.image_url);

                setValue("image", {base64: promotionImageBase64, name: promotionImageName});

                await trigger('image');

                setSelectedProducts(fetchedPromotion.products);

                setPromotionImage({...promotionImage, imageUrl: fetchedPromotion.image_url});
            }

            setIsLoading(false);
        })()
    }, [promotion]);

    const submit = useCallback(async (data: PromotionFormData) => {
        try {
            data.start_date = moment(new Date(data.start_date)).format('YYYY-MM-DD')

            data.end_date = moment(new Date(data.end_date)).format('YYYY-MM-DD')

            data.products = selectedProducts.map(sel => sel.id);

            setIsLoading(true);

            if (!promotion) {
                await promotionsService.add(data);

                setIsLoading(false);

                notification(
                  "Succès",
                  "L'offre promotionnel a été Ajouté  !",
                  "success"
                );

                handleClose();
            } else {

                const dataWithOrder: PromotionFormData & {order: number} = {...data, order: promotion.order}

                await promotionsService.update(promotion.id, dataWithOrder);

                setIsLoading(false);

                notification(
                  "Succès",
                  "L'offre promotionnel a été modifiée !",
                  "success"
                );

                handleClose();
            }
        } catch (e: any) {
            setIsLoading(false);

            notification("Erreur", "Veuillez contacter un administrateur", "error");
        }
    }, [promotion, selectedProducts]);

    const onAddProducts = useCallback(() => {
        if (!isLoading) {
            setAddProduct(true);
        }
    }, [isLoading, addProduct]);

    const validateDate = useCallback((input: "start_date"|"end_date" ) => {
        const startDate = new Date(getValues("start_date"));

        const endDate = new Date(getValues("end_date"));

        if (startDate >= endDate) {
            setError(input, {type: "custom", message: "La date de fin ne peut être inférieure à la date de début"})
            return false
        }

        clearErrors(input);
        return true;
    }, [getValues]);

    const displayImageHelper = useCallback((img: SyntheticEvent<HTMLImageElement>) => {
        const image = img.target as HTMLImageElement

        const aspectRatio = image.width > image.height ? "16/9" : "4/3"

        setPromotionImage({...promotionImage, aspectRatio, isBright: colorBasedOnBG(img.target)});
    }, [promotionImage]);

    const onFileChosenHandler = useCallback(async (
        file: File[],
        onChange: (...event: any[]) => void,
        error: EErrors|undefined
    ) => {
        clearErrors("image");

        if (file[0]) {
            onChange({base64: await getBase64fromFile(file[0]), name: file[0].name});

            setPromotionImage({
                ...promotionImage,
                imageUrl: URL.createObjectURL(file[0])
            });

            URL.revokeObjectURL(promotionImage.imageUrl);
        }

        if (error) {
            setError('image', {type: 'custom', message: error});
        }
    }, [promotionImage, clearErrors, setError]);

    return (
        <>
            {/* Get image color to change text color */}
            <img src={promotionImage.imageUrl} onLoad={(img) => displayImageHelper(img)}/>

            <FocusModal.BasicFocusModal handleClose={handleClose} onSubmit={handleSubmit(submit)} onSubmitDisabled={!isValid}>

                <ModalProducts
                    open={addProduct}
                    onClose={() => setAddProduct(false)}
                    productsSelected={selectedProducts}
                    save={(products) => {
                        setSelectedProducts(products);
                        setAddProduct(false)
                    }}
                />

                <div className="w-full px-8 overflow-y-auto h-full z-0 ">
                    <div className="flex justify-center mb-[25%]">
                        <div className="max-w-[700px] w-full pt-16">
                            <h1 className="inter-xlarge-semibold">
                                {modalTitle}
                            </h1>
                            <Accordion
                                className="pt-7 text-grey-90"
                                defaultValue={["general", "dates", "image"]}
                                type="multiple"
                            >
                                <Accordion.Item
                                    title="Général"
                                    required
                                    value="general"
                                >
                                    <div className="flex justify-between mt-6 gap-x-base">
                                        <InputField
                                            label="Nom"
                                            required
                                            placeholder="Nom de l'offre"
                                            {...register("name", {required: true})}
                                            defaultValue={promotion?.name ?? ""}
                                        />
                                        <Select
                                            label="Visibilité"
                                            placeholder="Visibilité"
                                            required
                                            isSearchable={false}
                                            options={optionsVisible}
                                            defaultValue={
                                                promotion
                                                    ? promotion.visible ? optionsVisible[0] : optionsVisible[1]
                                                    : optionsVisible[0]
                                            }
                                            onChange={(val: any) => setValue('visible', val.value)}
                                        />
                                    </div>
                                </Accordion.Item>
                                <Accordion.Item title="Dates" value="dates" required>
                                    {(errors.start_date || errors.end_date) &&
                                        <p className="text-rose-50 inter-small-regular mt-2">
                                            {errors.start_date?.message ?? errors.end_date?.message}
                                        </p>
                                    }
                                    <h2 className="my-6 inter-base-semibold text-grey-80">
                                        Date de début<span className="text-rose-50">*</span>
                                    </h2>
                                    <div className="flex gap-x-base">
                                        <Controller
                                            name="start_date"
                                            defaultValue={promotion?.start_date.toDateString() ?? new Date().toDateString()}
                                            control={control}
                                            rules={{
                                                required: true,
                                                validate: (value) => validateDate("start_date")
                                            }}
                                            render={({field: {onChange, value}}) => {
                                                return (
                                                    <DatePicker
                                                        date={new Date(value)}
                                                        label="Date de début"
                                                        onSubmitDate={onChange}
                                                    />
                                                );
                                            }}
                                        />
                                    </div>
                                    <h2 className="my-6 inter-base-semibold text-grey-80">
                                        Date de fin<span className="text-rose-50">*</span>
                                    </h2>
                                    <div className="flex gap-x-base">
                                        <Controller
                                            name="end_date"
                                            defaultValue={
                                                promotion?.end_date.toDateString()
                                                ??
                                                (() => {
                                                const date = new Date();
                                                date.setDate(date.getDate() + 1);
                                                return date.toDateString();
                                                })()
                                            }
                                            control={control}
                                            rules={{
                                                required: true,
                                                validate: (value) => validateDate("end_date")
                                            }}
                                            render={({field: {onChange, value}}) => {
                                                return (
                                                    <DatePicker
                                                        date={new Date(value!)}
                                                        label="Date de fin"
                                                        onSubmitDate={onChange}
                                                    />
                                                );
                                            }}
                                        />
                                    </div>
                                </Accordion.Item>
                                <Accordion.Item title="Image" value="image" required>
                                    <Controller
                                        name="image"
                                        control={control}
                                        rules={{required: true}}
                                        render={({field: {onChange, value}}) => {
                                            return (
                                                <FileUploadField
                                                    hasBackgroundImage={promotionImage.imageUrl}
                                                    className="my-6"
                                                    backgroundImageAspectRatio={promotionImage.aspectRatio}
                                                    text={value
                                                        ?
                                                        <span className="flex flex-col items-center my-8">
                                                            <span className={`inter-base-regular ${promotionImage.isBright ? "text-grey-60" : "text-grey-0"}`}>
                                                                Votre fichier :{" "}
                                                                <span className={promotionImage.isBright ? "text-blue-70" : "text-blue-5"}>{value.name}</span>
                                                            </span>
                                                        </span>
                                                        :
                                                        <span className="flex flex-col items-center my-6">
                                                            <span className={
                                                                `inter-base-regular ${promotionImage.isBright ? "text-grey-60" : "text-grey-0"}`
                                                                }>
                                                                Déposer votre image ici, ou{" "}
                                                                <span className={promotionImage.isBright ? "text-blue-70" : "text-blue-5"}>
                                                                    cliquez pour parcourir
                                                                </span>
                                                            </span>
                                                            <span className="inter-small-regular">
                                                                1200 x 1600 (4:3) recommandé, jusqu'à 10 Mo chacun
                                                            </span>
                                                        </span>
                                                    }
                                                    onFileChosen={async (file: File[], error?: EErrors) => {
                                                        await onFileChosenHandler(file, onChange, error)
                                                    }}
                                                    filetypes={['image/*']}
                                                    errorMessage={`Erreur: ${errors.image?.message}`}
                                                />
                                            );
                                        }}
                                    />
                                </Accordion.Item>
                                <Accordion.Item
                                    title="Produits"
                                    value="products"
                                >
                                    {selectedProducts.length > 0 &&
                                        <ProductTable
                                            className="pt-6"
                                            selected={selectedProducts}
                                            setSelected={setSelectedProducts}
                                            columns={[columnProduct.NAME, columnProduct.PRICE, columnProduct.EAN, columnProduct.STOCK, columnProduct.REMOVE]}
                                            displaySelected={true}
                                        />
                                    }

                                    <div className="shadow-md">
                                        <div
                                            className=" border h-10 flex justify-center items-center gap-2  mt-10 rounded-md cursor-pointer"
                                            onClick={onAddProducts}
                                        >
                                            <PlusIcon/>
                                            <p>Ajouter des Produits</p>
                                        </div>
                                    </div>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                </div>
            </FocusModal.BasicFocusModal>
        </>
    );
}
