import React from "react"
import {NavLink} from "react-router-dom";

type SidebarCompanyLogoProps = {
  storeName?: string
}

const SidebarCompanyLogo: React.FC<SidebarCompanyLogoProps> = ({
  storeName,
}: SidebarCompanyLogoProps) => {
  return (
    <NavLink to="/" className="flex items-center bg-grey-0 px-2.5 pb-6 w-full mb-4 hover:cursor-pointer">
      <div className="w-[32px] h-[32px] flex items-center justify-center bg-blue-60 text-grey-0 rounded">
        <div>{storeName?.slice(0, 1) || "M"}</div>
      </div>
      <span className="font-semibold ml-2.5 text-blue-60">{storeName}</span>
    </NavLink>
  )
}

export default SidebarCompanyLogo
