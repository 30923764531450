import { useEffect, useState } from "react"
import { Outlet } from "react-router-dom"
import { User } from "../../../models/User";
import authService from "../../../services/auth.service";
import Layout from "../../fundamentals/layout/Layout";
import { Toaster } from 'react-hot-toast';

export default function BaseTemplate(){

    const [user, setUser] = useState<User>();

    useEffect(() => {
        async function fetchUser(){
            await authService.updateAxiosInterceptors();
            await authService.updateAxiosAuthorization();
            setUser(await authService.me());
        }

        fetchUser();
    }, [])

    return <div>
            {user && < Layout user={user}>
                <Outlet />
                <Toaster />
            </Layout> }
        </div> 
}