import BodyCard from "../../../../components/fundamentals/body-card/body-card";
import TableViewHeader from "../../../../components/fundamentals/custom-table-header";
import ClientTable from "./client-table/ClientTable";

const VIEWS = ["clients"];

export default function ClientsPage() {
  return (
    <div className="flex flex-col grow h-full">
      <div className="w-full flex flex-col grow">
        <BodyCard
          forceDropdown={false}
          customHeader={<TableViewHeader views={VIEWS} />}
          className="h-fit"
        >
          <ClientTable />
        </BodyCard>
      </div>
     
    </div>
  );
}
