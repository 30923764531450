import { useEffect, useState } from "react";
import { DataPaginate } from "../../../../../models/utils/DataPaginate";
import Table from "../../../../../components/fundamentals/table";
import TableContainer from "../../../../../components/fundamentals/table-container";
import flowsService from "../../../../../services/flows.service";
import { Flow } from "../../../../../models/Flow";
import FlowRow from "./FlowRow";
import { getTablePagingState } from "../../../../../services/utils/TableHelper";

export default function FlowTable() {
  const [dataPaginate, setDataPaginate] = useState<DataPaginate>();
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  async function fetchFlows() {
    setIsLoading(true);
    setDataPaginate(await flowsService.list(currentPage));
    setIsLoading(false);
  }

  useEffect(() => {
    fetchFlows();
  }, [currentPage]);

  return (
    <TableContainer
      numberOfRows={10}
      hasPagination
      pagingState={getTablePagingState(dataPaginate!, setCurrentPage, "résultats")}
      isLoading={isLoading}
    >
      <Table>
        <Table.Head>
          <Table.HeadRow>
            <Table.HeadCell>Nom</Table.HeadCell>
            <Table.HeadCell>Date de début</Table.HeadCell>
            <Table.HeadCell>Date de fin</Table.HeadCell>
            <Table.HeadCell>Status</Table.HeadCell>
            <Table.HeadCell>Éléments</Table.HeadCell>
            <Table.HeadCell>Éléments importés</Table.HeadCell>
          </Table.HeadRow>
        </Table.Head>
        <Table.Body>
          {dataPaginate &&
            dataPaginate?.data.length &&
            dataPaginate.data.map((flow: Flow) => (
              <FlowRow
                key={`flow_item_${flow.id}`}
                flow={flow}
              />
            ))}
        </Table.Body>
      </Table>
    </TableContainer>
  );
}
