export type ScriptAttributes = {
        src: string,
        async?: boolean,
        defer?: boolean,
}
function insertScript(parentNodeSelector: string, scriptAttributes: ScriptAttributes): Promise<void> {
    return new Promise((resolve) => {
        const script = document.createElement('script');

        const parentNode = document.querySelector(parentNodeSelector);

        const {src, async, defer} = scriptAttributes;

        if (parentNode) {
            script.src = src;

            script.async = async ?? false;

            script.defer = defer ?? false;

            parentNode.appendChild(script);

            script.onload = () => {
                resolve();
            }
        }
    });
}

export default {
    insertScript
}