import { update } from "lodash";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { loadingIndicatorCSS } from "react-select/dist/declarations/src/components/indicators";
import InputField from "../../../../../components/form/input";
import Button from "../../../../../components/fundamentals/button";
import Modal from "../../../../../components/fundamentals/modal";
import useNotification from "../../../../../hooks/use-notification";
import { User } from "../../../../../models/User";
import usersService from "../../../../../services/users.service";

    type Props = {
        user?: User,
        open: boolean
        onClose: () => void
    }

    type EditInformationFormType = {
        firstname: string | null
        lastname: string | null
    }

    const EditUserInformationModal = ({ user, open, onClose }: Props) => {

    const [isSubmitting, setIsSubmitting] = useState(false);
    
    const onSubmit = (data: any) =>{
        onClose ();
        console.log(data);
        //update()

    }
      const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm<EditInformationFormType>({
        defaultValues: getDefaultValues(user),
    })
 
  
    useEffect(() => {    
            async function fetchData(){
            if(user){
                usersService.update(user.id, {
                ...user,
                // firstname: 'Admin',
                // lastname: 'Webexpr'
                })
            }
            }
            fetchData();
        }, [open, user ])

        return (
            <Modal handleClose={onClose} open={open} isLargeModal={false}>
            <Modal.Header handleClose={onClose}>
                <h1 className="inter-xlarge-semibold">Edit information</h1>
            </Modal.Header>
            <Modal.Body>
                <Modal.Content>
                <form onSubmit={handleSubmit(onSubmit)}>

                <div className="flex flex-col gap-y-base">
                    <div className="grid grid-cols-2 gap-x-base">
                    <InputField
                        {...register("firstname")}
                        errors={errors}
                        label="Prénom"
                    />
                    <InputField
                        {...register("lastname")}
                        errors={errors}
                        label="Nom"
                    />
                    </div>
                </div>
                </form>
                </Modal.Content>
                <Modal.Footer className="border-t border-grey-20 pt-base">
                <div className="flex items-center justify-end gap-x-xsmall w-full">
                    <Button 
                    variant="secondary" 
                    size="small" 
                    onClick={onClose}
                    >
                    Annuler
                    </Button>
                    <Button
                    variant="primary"
                    size="small"
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    onClick={onSubmit}
                    type="submit"
                    >
                    Enregistrer et fermer
                    </Button>
                </div>
                </Modal.Footer>
            </Modal.Body>
            </Modal>
        )
        }

        const getDefaultValues = (user?: User) => {
            return {
            firstname: user?.firstname,
            lastname: user?.lastname,
            }
        }
        

        export default EditUserInformationModal
    