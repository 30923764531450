export class Analytics {
    public visitors: number;
    public visits: number;
    public views: number;
    public subsets?: Analytics[]

    constructor(data: any){
        this.visitors = data.visitors;
        this.visits = data.visits;
        this.views = data.views;
        this.subsets = data.subsets
    }
}