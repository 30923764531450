import React, { useState } from "react";
import CustomerIcon from "../../fundamentals/icons/customer-icon";
import DollarSignIcon from "../../fundamentals/icons/dollar-sign-icon";
import GearIcon from "../../fundamentals/icons/gear-icon";
import TagIcon from "../../fundamentals/icons/tag-icon";
import DashboardIcon from "../dashboard-icon";
import PromoIcon from "../icons/promo-icon";
import SidebarCompanyLogo from "../sidebar-company-logo";
import SidebarMenuItem from "../sidebar-menu-item";

const ICON_SIZE = 18;
const Sidebar: React.FC = () => {
  const [currentlyOpen, setCurrentlyOpen] = useState(-1);

  const triggerHandler = () => {
    const id = triggerHandler.id++;
    return {
      open: currentlyOpen === id,
      handleTriggerClick: () => setCurrentlyOpen(id),
    };
  };
  // We store the `id` counter on the function object, as a state creates
  // infinite updates, and we do not want the variable to be free floating.
  triggerHandler.id = 0;

  return (
    <div className="min-w-sidebar max-w-sidebar h-screen overflow-y-auto bg-gray-0 border-r border-grey-20 py-base px-base">
      <div className="h-full ">
        <SidebarCompanyLogo storeName={"Ca2e"} />

        <div className="border-b pb-3.5 border-grey-20">
          <SidebarMenuItem
            pageLink={"/orders"}
            icon={<DollarSignIcon size={ICON_SIZE} />}
            triggerHandler={triggerHandler}
            text={"Commandes"}
            isHomePage={true}
            // disabled={true}
          />

          <SidebarMenuItem
            pageLink={"/products?view=produits"}
            icon={<TagIcon size={ICON_SIZE} />}
            text={"Produits"}
            triggerHandler={triggerHandler}
          />

          <SidebarMenuItem
            pageLink={"/customers"}
            icon={<CustomerIcon size={ICON_SIZE} />}
            triggerHandler={triggerHandler}
            text={"Clients"}
          />

          <SidebarMenuItem
            pageLink={"/promotions"}
            icon={<PromoIcon size={ICON_SIZE} />}
            triggerHandler={triggerHandler}
            text={"Offres promotionnelles"}
          />
          
          <SidebarMenuItem
            pageLink={"/dashboard"}
            icon={<DashboardIcon size={ICON_SIZE} />}
            triggerHandler={triggerHandler}
            text={"Tableau de bord"}
          />

          <SidebarMenuItem
            pageLink={"/settings"}
            icon={<GearIcon size={ICON_SIZE} />}
            triggerHandler={triggerHandler}
            text={"Paramètres"}
          />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
