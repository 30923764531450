export class Agency {
  id: number;
  name: string;
  city: string;
  postal_code: string;
  address: string;
  phone_number: string;
  enable: string;
  division_code: string;
  display_name: string;
  is_platform: string;
  emails_notification: string;
  pickup_code: string;
  image_url: string;
  timetable_agencies: string;
  created_at: Date;
  updated_at: Date;

  constructor(data: any) {
    this.id = Number(data.id);
    this.name = data.name;
    this.city = data.city;
    this.postal_code = data.postal_code;
    this.address = data.address;
    this.phone_number = data.phone_number;
    this.enable = data.enable;
    this.division_code = data.division_code;
    this.display_name = data.display_name;
    this.is_platform = data.is_platform;
    this.pickup_code = data.pickup_code;
    this.image_url = data.image_url;
    this.emails_notification = data.emails_notification;
    this.timetable_agencies = data.timetable_agencies;
    this.created_at = new Date(data.created_at);
    this.updated_at = new Date(data.updated_at);
  }
}
