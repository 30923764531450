import React from "react";
import IconProps from "./types/icon-type";

const ChevronRightLightIcon: React.FC<IconProps> = ({
  size = "10px",
  color = "currentColor",
  ...attributes
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 6 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...attributes}
    >
      <path
        d="M0.5 10.5L5.5 5.5L0.5 0.5"
        stroke="#475467"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ChevronRightLightIcon;
