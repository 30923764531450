import * as React from "react"
import IconProps from "../types/icon-type"

const DashboardIcon: React.FC<IconProps> = ({
    size = "24",
    color = "currentColor",
    ...attributes
  }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox={`0 0 ${size} ${size}`}
            fill="none"
            {...attributes}
        >
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M13.5 15V3m-9 12v-3M9 15V7.5"
        />
      </svg>
    )
  }
export default DashboardIcon
