import { useEffect, useState } from "react";
import Modal from "../../../../components/fundamentals/modal";
import LayeredModal from "../../../../components/fundamentals/modal/layered-modal";
import Table from "../../../../components/fundamentals/table";
import TableContainer from "../../../../components/fundamentals/table-container";
import { useDraggable } from "../../../../hooks/use-draggable";
import { Category } from "../../../../models/Category";
import { DataPaginate } from "../../../../models/utils/DataPaginate";
import categoryService from "../../../../services/category.service";
import ModalCategory from "../products-page/modal-category/ModalCategory";
import { CategoryRow } from "./CategoryRow";

export default function CategoryTable() {
  const [isLoading, setIsLoading] = useState<boolean>();
  const [categories, setCategories] = useState<Category[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState<string>();
  const [tmpSearch, setTmpSearch] = useState<string>();
  // const [showEditCategory, setShowEditCategory] = useState<boolean>(false);
  const { clear, dragStart, dragEnter, dragItem, dragOverItem } =
    useDraggable();

  const sortOrderCategories = (x: Category, y: Category) => {
    if (x.order > y.order) {
      return 1;
    }
    if (x.order < y.order) {
      return -1;
    }
    return 0;
  };

  async function fetchCategories(activeLoader = true) {
    try {
      activeLoader && setIsLoading(true);
      const categories = await categoryService.list(
          null,
          search,
          null,
          null,
          1,
          null)

      setCategories(categories);

      activeLoader && setIsLoading(false);
    } catch (error) {
      activeLoader && setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchCategories();
    // eslint-disable-next-line
  }, [currentPage, search]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setCurrentPage(1);
      setSearch(tmpSearch);
    }, 400);

    return () => clearTimeout(delayDebounceFn);
  }, [tmpSearch]);

  const handleDropItem = async () => {
    clear();
    if (dragItem && dragOverItem) {
      const { item: itemFrom } = dragItem;
      const { position: posTarget } = dragOverItem;
      try {
        await categoryService.update(itemFrom.id, {
          enable: itemFrom.enable,
          img_base64: itemFrom?.img_base64,
          order: posTarget,
        });
        fetchCategories(false);
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div>
      <TableContainer
        numberOfRows={10}
        isLoading={isLoading}
      >
        <Table
          enableSearch={true}
          searchValue={tmpSearch}
          handleSearch={(term) => setTmpSearch(term)}
          onClear={() => {
            setCurrentPage(1);
          }}
        >
          <>
            <Table.Head>
              <Table.HeadRow>
                <Table.HeadCell></Table.HeadCell>
                <Table.HeadCell>Nom de la catégorie</Table.HeadCell>
                <Table.HeadCell>Nombre de produits</Table.HeadCell>
                <Table.HeadCell>Visibilité</Table.HeadCell>
              </Table.HeadRow>
            </Table.Head>
            <Table.Body>
              {categories.length > 0 &&
                categories.sort(sortOrderCategories)
                    .map((category, index) => (
                      <CategoryRow
                          key={`category_item_${category.id}`}
                          index={index}
                          category={category}
                          dragOverItem={dragOverItem}
                          onDragStart={(e: any) =>
                              dragStart(e, {
                                position: category.order,
                                item: category,
                              })
                          }
                          onDragEnter={(e: any) =>
                              dragEnter(e, {
                                position: category.order,
                                item: category,
                              })
                          }
                          onDragEnd={handleDropItem}
                          refresh={() => fetchCategories(false)}
                      />
                ))
              }
            </Table.Body>
          </>
          {/* {showEditCategory && (
            <ModalCategory onClose={() => setShowEditCategory(false)} category={ca}/>
          )} */}
        </Table>
      </TableContainer>
    </div>
  );
}
