import { useEffect, useState } from "react";
import DatePicker from "../../../components/form/date-picker/date-picker";
import Select from "../../../components/form/select/next-select/select";
import BodyCard from "../../../components/fundamentals/body-card/body-card";
import TableViewHeader from "../../../components/fundamentals/custom-table-header";
import { Analytics } from "../../../models/Analytics";
import { OrderTunnelAnalytics } from "../../../models/OrderTunnelAnalytics";
import { TopViewAnalytics } from "../../../models/TopViewAnalytics";
import analyticsService from "../../../services/analytics.service";
import AnalyticsGraph from "./analytics-graph/AnalyticsGraph";
import pages from "./configs/pages";
import timeSteps from "./configs/timeSteps";
import OrderFunnelGraph from "./order-tunnel-graph/OrderFunnelGraph";
import TopViewsTable from "./top-views-table/TopViewsTable";
import sources from "./configs/sources";

const VIEWS = [["Tableau de bord"], ["Statistiques"], ["Tunnel d'achat"], ["Top 20 des produits les plus vus"]];

export default function DashboardPage() {

  const [source, setSource] = useState<{label: string, value: string}>(sources[0])
  const [page, setPage] = useState<{label: string, value: string}>(pages['BOTH'][0])
  const [timeStep, setTimeStep] = useState<{label: string, value: {step: number, type: string}}>(timeSteps[1])
  const [date, setDate] = useState<Date>(new Date())
  const [analytics, setAnalytics] = useState<Analytics | null>(null)
  const [topViews, setTopViews] = useState<TopViewAnalytics[] | null>(null)
  const [orderTunnel, setOrderTunnel] = useState<OrderTunnelAnalytics[] | null>(null)

  function getStartDate(step: number, type: string, refDate: Date){
    let dateStep = new Date(refDate)
    if (type == "D") {
      dateStep.setDate(dateStep.getDate() - step)
    }
    if (type == "M") {
      dateStep.setMonth(dateStep.getMonth() - step)
    }
    return dateStep
  }

  useEffect(() => {
    async function fetchAnalytics(){
      let allAnalytics = await analyticsService.get(getStartDate(timeStep.value.step, timeStep.value.type, date), date, page.value, timeStep.value.type, source.value)
      setAnalytics(allAnalytics)
      setTopViews(await analyticsService.getTopViewsBy(getStartDate(timeStep.value.step, timeStep.value.type, date), date, 'views', 20, 'DetailProductScreen', source.value))
      
      let orderTunnelAnalytics = await analyticsService.getOrderTunnel(getStartDate(timeStep.value.step, timeStep.value.type, date), date, source.value)
      let orderFunnel: OrderTunnelAnalytics[] = []
      if (allAnalytics) {
        orderFunnel = [new OrderTunnelAnalytics({
          type: `event:order:0`,
          sessions: allAnalytics.visits,
          users: allAnalytics.visitors,
          clicks: allAnalytics.views
        })]
      }
      if (orderTunnelAnalytics) {
        orderFunnel = [...orderFunnel, ...orderTunnelAnalytics]
      }
      setOrderTunnel(orderFunnel)
    }
    fetchAnalytics()
  }, [page, timeStep, date, source])

  return (
    <div className="flex flex-col grow h-full">
      <div className="w-full flex flex-col grow gap-2">
        <BodyCard
          forceDropdown={false}
          customHeader={<TableViewHeader views={VIEWS[0]} />}
          className="h-fit"
          compact
        >
          <div className="w-full grid grid-cols-4 grid-rows-1 gap-4 mt-10">
            <DatePicker
              date={date}
              label="Date de début"
              onSubmitDate={setDate}
              maxDate={new Date()}
              simple
            />
            <Select
              label="Période"
              isSearchable={false}
              options={timeSteps}
              value={timeStep}
              onChange={(val: any) => setTimeStep(val)}
            />
            <Select
              label="Source"
              isSearchable={false}
              options={sources}
              value={source}
              onChange={(val: any) => {setPage(pages['BOTH'][0]) ; setSource(val)}}
            />
            <Select
              label="Pages"
              isSearchable={false}
              options={source.value ? [...pages['BOTH'], ...pages[source.value]] : [...pages['BOTH'], ...pages['APP'], ...pages['WEB']]}
              value={page}
              onChange={(val: any) => setPage(val)}
            />
          </div>
        </BodyCard>

        {analytics && <BodyCard
          forceDropdown={false}
          customHeader={<TableViewHeader views={VIEWS[1]} />}
          className="h-fit"
        >
          <AnalyticsGraph analytics={analytics}/>
        </BodyCard>}

        {orderTunnel &&<BodyCard
          forceDropdown={false}
          customHeader={<TableViewHeader views={VIEWS[2]} />}
          className="h-fit"
        >
          <OrderFunnelGraph analytics={orderTunnel}/>
        </BodyCard>}

        {topViews && <BodyCard
          forceDropdown={false}
          customHeader={<TableViewHeader views={VIEWS[3]} />}
          className="h-fit mb-4"
        >
          <TopViewsTable analytics={topViews}/>
        </BodyCard>}
      </div>
     
    </div>
  );
}
