import {useCallback, useState} from "react";
import BodyCard from "../../../../components/fundamentals/body-card/body-card";
import Button from "../../../../components/fundamentals/button";
import TableViewHeader from "../../../../components/fundamentals/custom-table-header";
import PlusIcon from "../../../../components/fundamentals/icons/plus-icon";
import ModalPromotion from "../modal-promotion/ModalPromotion";
import PromotionTable from "./promotion-table/PromotionTable";

const VIEWS = ["Gestion des offres promotionnelles"];

export default function PromotionsPage() {
  const [openPromotionCreate, setOpenPromotionCreate] = useState<boolean>(false);
  const [refresh, setRefresh] = useState(false);

  const onClosePromotionModal = useCallback(() => {
      setOpenPromotionCreate(false)
      setRefresh(true)
  }, []);

  return (
    <div className="flex flex-col grow h-full">
      <div className="w-full flex flex-col grow">
        <BodyCard
          forceDropdown={false}
          customActionable={
            <div className="flex space-x-2">
              <Button
                variant="secondary"
                size="small"
                onClick={() => setOpenPromotionCreate(true)}
              >
                <PlusIcon size={20} />
                Ajouter une promotion
              </Button>
            </div>
          }
          customHeader={<TableViewHeader views={VIEWS} />}
          className="h-fit"
        >
          <PromotionTable
            refresh={refresh}
            setRefresh={setRefresh}
          />
        </BodyCard>
      </div>
      {openPromotionCreate &&
          <ModalPromotion
              modalTitle="Ajouter une offre promotionnelle"
              handleClose={onClosePromotionModal}
          />
      }
    </div>
  );
}
