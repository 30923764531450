import React, { ReactNode, useState } from "react"
import FileIcon from "../../fundamentals/icons/file-icon"

type FileSummaryProps = {
  name: string
  size: number
  action: ReactNode
  progress?: number
  status?: string
}

/**
 * Render an upload file summary (& upload progress).
 */
export default function FileSummary(props: FileSummaryProps) {
  const { action, name, progress, size, status } = props

  const formattedSize =
    size / 1024 < 10
      ? `${(size / 1024).toFixed(2)} Ko`
      : `${(size / (1024 * 1024)).toFixed(2)} Mo`

  return (
    <div className="relative">
      <div
        style={{ width: `${progress}%` }}
        className="absolute bg-grey-5 h-full transition-width duration-150 ease-in-out"
      />
      <div className="relative flex items-center rounded-xl border border-1 mt-6">
        <div className="m-4">
          <FileIcon size={30} fill={progress ? "#9CA3AF" : "#2ED3B7"} />
        </div>

        <div className="flex-1 my-6">
          <div className="text-small leading-5 text-grey-90">{name}</div>
          <div className="text-xsmall leading-4 text-grey-50">
            {status || formattedSize}
          </div>
        </div>

        <div className="m-6">{action}</div>
      </div>
    </div>
  )
}