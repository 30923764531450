import { useState } from "react";
import BodyCard from "../../../../../components/fundamentals/body-card/body-card";
import Breadcrumb from "../../../../../components/fundamentals/breadcrumb/Breadcrumb";
import Button from "../../../../../components/fundamentals/button";
import TableViewHeader from "../../../../../components/fundamentals/custom-table-header";
import PlusIcon from "../../../../../components/fundamentals/icons/plus-icon";
import FlowTable from "../flow-table/FlowTable";

const VIEWS = ["Flux automatiques"];

export default function FlowsPage() {

  return (
    <div className="flex flex-col grow h-full">
      <div className="w-full flex flex-col grow">
        <Breadcrumb previousRoute="/settings" previousBreadcrumb="Paramètres" currentPage={"Flux automatiques"}/>
        <BodyCard
          forceDropdown={false}
          customHeader={<TableViewHeader views={VIEWS} />}
          className="h-fit"
        >
          <FlowTable/>
        </BodyCard>
      </div>
    </div>
  );
}
