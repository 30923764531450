import axios from "axios";
import { Agency } from "../models/Agency";
import { DataPaginate } from "../models/utils/DataPaginate";
import { IFilter } from "../models/utils/filter/FilterType.interface";
import { convertFiltersToQuery } from "./utils/serviceHelper";

function list(page: number, search?: string, filters?: IFilter[]) {
  let params = `?page=${page}`;
  if (search) {
    params = `${params}&search=${search}`;
  }
  if (filters) {
    params = `${params}${convertFiltersToQuery(filters)}`;
  }

  return axios
    .get(`${process.env.REACT_APP_API_URL}agencies${params}`)
    .then((response: any) => {
      const dataPaginate = new DataPaginate(response.data);
      dataPaginate.setData(dataPaginate.data.map((item) => new Agency(item)));

      return dataPaginate;
    });
}

function find(id: string): Promise<Agency> {
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_API_URL}agencies/${id}`)
        .then((response) => {
          resolve(new Agency(response.data))
        })
        .catch((error) => {
          switch (error.response.status) {
            case 404:
              reject({status: error.response.status, message: "Agence inconnue"});
              break;
            default:
              reject({status: error.response.status, message: "Erreur inconnue"});
          }
        })
  })
}

function update(id: any, body: any) {
  return axios
    .put(`${process.env.REACT_APP_API_URL}agencies/${id}`, body)
    .then((response) => new Agency(response.data));
}

export default {
  list,
  find,
  update,
};
