import { useEffect, useState } from "react";
import Spinner from "../../../../../components/fundamentals/spinner";
import StatusSelector from "../../../../../components/fundamentals/status-selector";
import Table from "../../../../../components/fundamentals/table";
import { Agency } from "../../../../../models/Agency";
import { Client } from "../../../../../models/Client";
import agencesService from "../../../../../services/agences.service";
import clientsService from "../../../../../services/clients.service";

interface IProps {
  client: Client;
  index: number;
  refresh: () => {};
}
export default function ClientRow({ client, index, refresh }: IProps) {
  const [agencie, setAgencie] = useState<Agency | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingVisibility, setIsLoadingVisibility] = useState<boolean>();

  const getBgEntreprise = () => {
    if (index % 3 === 0) return "bg-orange-40";
    else if (index % 2 === 0) return "bg-pink-40";
    else return "bg-fuschia-40";
  };

  useEffect(() => {
    const fetchAgency = async () => {
      setAgencie(await agencesService.find(client.agencie_id));
      setIsLoading(false);
    };
    client.agencie_id ? fetchAgency() : setIsLoading(false);
  }, [client]);

  const updateVisibility = async () => {
    try {
      setIsLoadingVisibility(true);
      await clientsService.patchDisable(client.id, !client.disabled);
      await refresh();
      setIsLoadingVisibility(false);
    } catch (error) {
      setIsLoadingVisibility(false);
      console.log(error);
    }
  };

  return (
    <Table.Row color={"inherit"} linkTo={`/customers/${client.id}`}>
      <Table.Cell>
        <div className="flex items-center">
          <div className="w-[24px] h-[24px]">
            <span
              className={`w-full h-full items-center justify-center overflow-hidden select-none rounded-circle ${getBgEntreprise()}`}
            >
              <span className="w-full h-full flex items-center justify-center bg-inherit text-grey-0 rounded-circle inter-small-semibold">
                {client.name.charAt(0)}
              </span>
            </span>
          </div>
          <span className="ml-2">{client.name}</span>
        </div>
      </Table.Cell>
      <Table.Cell>{client.siret ?? "-"}</Table.Cell>
      <Table.Cell>
        {isLoading ? (
          <Spinner size="small" variant="secondary" />
        ) : (
          agencie?.name ?? "-"
        )}
      </Table.Cell>
      <Table.Cell>{client.users?.length ?? 0} </Table.Cell>
      <Table.Cell onClick={(e) => e.stopPropagation()}>
        {!isLoadingVisibility ? (
          <StatusSelector
            isActive={!client.disabled}
            activeState="Actif"
            inactiveState="Inactif"
            onChange={updateVisibility}
          />
        ) : (
          <Spinner size="small" variant="secondary" />
        )}
      </Table.Cell>
    </Table.Row>
  );
}
