
import React, { useEffect, useState } from "react"
import { useDebounce } from "../../../hooks/use-debounce"
import SearchIcon from "../../fundamentals/icons/search-icon"
import * as RadixDialog from "@radix-ui/react-dialog"
import CustomerResults from "./results/customer-results"
import DiscountResults from "./results/discount-results"
import KeyboardShortcuts from "./keyboard-shortcuts"
import ProductResults from "./results/product-results"
import useKeyboardNavigationList from "./use-keyboard-navigation-list"
import clsx from "clsx"
import OrderResults from "./results/order-results"
import CrossIcon from "../../fundamentals/icons/cross-icon"
import Spinner from "../spinner"
import Input from "../../form/input"
import Tooltip from "../../form/tooltip"
import productsService from "../../../services/products.service";
import { Product } from "../../../models/Product"
import { DataPaginate } from "../../../models/utils/DataPaginate"


const getTotal = (...lists: any)=>
  lists.reduce((total: any, list = []) => total + list.length, 0)

const SearchModal = ({ handleClose }: any) => {
  const [q, setQ] = React.useState("");
  const [loading, setLoading] = useState(false);
  const [productsPaginated, setProductsPaginated] = useState<DataPaginate>();
  const query = useDebounce(q, 500)
  const onChange = (e: any) => setQ(e.target.value)

  // No longer used but still in code if needed further
  // const handleClear = () => {
  //   setQ("")
  // }
  const [page, setPage] = useState(1)


  useEffect(() => {
    const fetchResults = async () => {
      setLoading(true);
      const res = await productsService.search(page, query);
      setProductsPaginated(res);
      setLoading(false);
    }

    if(query){
      fetchResults();
    }
  }, [query])

  const fetchNextResults = async () => {
    const res = await productsService.search(page + 1, query);
    if(productsPaginated){
      res.setData([...productsPaginated.data, ...res.data ]);
      console.log(res);
      setProductsPaginated(res);
      setPage(page + 1);
    }
    
  }

  /*const { orders, isFetching: isFetchingOrders } = useAdminOrders(
    {
      q: query,
      limit: 5,
      offset: 0,
    },
    { enabled: !!query, keepPreviousData: true }
  )
  const { customers, isFetching: isFetchingCustomers } = useAdminCustomers(
    {
      q: query,
      limit: 5,
      offset: 0,
    },
    { enabled: !!query, keepPreviousData: true, retry: 0 }
  )
  const { discounts, isFetching: isFetchingDiscounts } = useAdminDiscounts(
    { q: query, limit: 5, offset: 0 },
    { enabled: !!query, keepPreviousData: true }
  )
  const { products, isFetching: isFetchingProducts } = useAdminProducts(
    { q: query, limit: 5 },
    { enabled: !!query, keepPreviousData: true }
  )

  const isFetching =
    isFetchingDiscounts ||
    isFetchingCustomers ||
    isFetchingProducts ||
    isFetchingOrders

  const totalLength = getTotal(products, discounts, customers, orders)*/

  const { getInputProps, getLIProps, getULProps, selected } =
    useKeyboardNavigationList({
      length: 0,
    })

  return (
    <RadixDialog.Root open onOpenChange={handleClose}>
      <RadixDialog.Portal>
        <RadixDialog.Overlay
          className={clsx(
            "fixed pt-[140px] pb-[100px] z-50 inset-0 backdrop-blur-sm",
            { flex: 0 > 0 }
          )}
        >
          <RadixDialog.Content
            className={clsx(
              "max-w-[640px] flex-1 flex mx-auto bg-grey-0 rounded-rounded shadow-searchModal"
            )}
          >
            <div className="py-large flex-1 flex flex-col">
              <div className="flex items-center gap-x-4 pb-large border-solid px-xlarge border-b border-grey-20">
                <SearchIcon className="text-grey-40" />
                <Input
                  className="flex-1"
                  onChange={onChange}
                  value={q}
                  placeholder="Taper pour rechercher..."
                  {...getInputProps()}
                />
                <Tooltip
                  className="bg-grey-0"
                  onClick={handleClose}
                  content="Clear search"
                >
                  <CrossIcon className="flex text-grey-50" />
                </Tooltip>
              </div>
              <KeyboardShortcuts className="mt-xlarge px-xlarge flex items-center gap-x-3 text-grey-40 inter-small-regular" />
                <ul
                  {...getULProps()}
                  className="flex-1 overflow-y-auto mt-large px-xlarge"
                >
                  {loading ? (
                    <div className="w-full pt-2xlarge flex items-center justify-center">
                      <Spinner size={"large"} variant={"secondary"} />
                    </div>
                  ) : (
                    <>
                      <div>
                        <OrderResults
                          orders={[]}
                          offset={0}
                          getLIProps={getLIProps}
                          selected={selected}
                        />
                      </div>

                      <div className="mt-xlarge">
                        <CustomerResults
                          customers={[]}
                          offset={ 0}
                          getLIProps={getLIProps}
                          selected={selected}
                        />
                      </div>

                      <div className="mt-xlarge">
                        <DiscountResults
                          discounts={[]}
                          offset={0}
                          getLIProps={getLIProps}
                          selected={selected}
                        />
                      </div>

                      <div className="mt-xlarge">
                        {productsPaginated && <ProductResults
                          dataPaginated={productsPaginated}
                          offset={productsPaginated ? productsPaginated.total : 0}
                          getLIProps={getLIProps}
                          selected={selected}
                          onScrollBottom={fetchNextResults}
                        />}
                      </div>
                    </>
                  )}
                </ul>
            </div>
          </RadixDialog.Content>
        </RadixDialog.Overlay>
      </RadixDialog.Portal>
    </RadixDialog.Root>
  )
}

export default SearchModal
