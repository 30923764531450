import {DataPaginate} from "../../models/utils/DataPaginate";
import {PagingProps} from "../../components/fundamentals/table-container/types";

function getTablePagingState(dataPaginate: DataPaginate, changePage: (page: number) => void, title: string): PagingProps|undefined {
    if (!dataPaginate) {
        return undefined;
    }

    const currentPage = dataPaginate.current_page;

    return {
        count: dataPaginate.total,
        offset: currentPage > 1
            ? (dataPaginate.current_page - 1) * 10
            : 0,
        pageSize: currentPage * 10,
        title,
        currentPage,
        pageCount: dataPaginate.last_page,
        nextPage: () => changePage(currentPage + 1),
        prevPage: () => changePage(currentPage - 1),
        hasNext: !!dataPaginate.next_page_url ?? false,
        hasPrev: !!dataPaginate.prev_page_url ?? false,
    }
}

export {getTablePagingState}