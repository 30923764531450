import clsx from "clsx";
import { EFilter, IFilter } from "../../../models/utils/filter/FilterType.interface";
import * as RadixCollapsible from "@radix-ui/react-collapsible"
import { useEffect, useState } from "react";
import CheckIcon from "../icons/check-icon";
import ChevronUpIcon from "../icons/chevron-up";
import TagInput from "../tag-input";


interface IProps{
    filter: IFilter,
    update: (filter: IFilter, value: any) => void
}
export default function DropdownFilterItem({filter, update}: IProps){
    const [open, setOpen] = useState<boolean>(filter.value);

    return <div
    className={clsx("w-full cursor-pointer", {
      "inter-small-semibold": open,
      "inter-small-regular": !open,
    })}
  >
    <RadixCollapsible.Root
      className="w-full"
      open={open}
      onOpenChange={(open) => setOpen(open)}
    >
      <RadixCollapsible.Trigger
        className={clsx(
          "py-1.5 px-3 flex w-full items-center hover:bg-grey-5 rounded justify-between",
          {
            "inter-small-semibold": open,
            "inter-small-regular": !open,
          }
        )}
      >
        <div className="flex items-center">
          <div
            className={`w-5 h-5 flex justify-center text-grey-0 border-grey-30 border rounded-base ${
              open && "bg-blue-60"
            }`}
          >
            <span className="self-center">
              {open && <CheckIcon size={16} />}
            </span>
          </div>
          <input
            id={filter.label}
            className="hidden"
            checked={open}
            readOnly
            type="checkbox"
          />
          <span className="ml-2">{filter.label}</span>
        </div>
        {open && (
          <span className="text-grey-50 self-end">
            <ChevronUpIcon size={20} />
          </span>
        )}
      </RadixCollapsible.Trigger>
      <RadixCollapsible.Content className="w-full">
        
        
          { filter.list && filter.list.map((el, i) => {
            let value: string
            let label: string

            if (typeof el === "string") {
              value = el
              label = el
            } else {
              value = el.value
              label = el.label
            }

            function isSelected(){
                if(filter.type == EFilter.SELECT){
                    return filter.value == el.value;
                } else if(filter.type == EFilter.SELECT_MULTIPLE && Array.isArray(filter.value)){
                    return filter.value.includes(value);
                }

                return false;
            }
        
            return (
              <div
                className={clsx(
                  "w-full flex hover:bg-grey-20 my-1 py-1.5 pl-6 items-center rounded",
                  {
                    "inter-small-semibold": isSelected(),
                    "inter-small-regular": !isSelected(),
                  }
                )}
                key={i}
                onClick={() => update(filter, el.value)}
              >
                <div
                  className={`w-5 h-5 flex justify-center text-grey-0 border-grey-30 border mr-2 rounded-base ${
                    isSelected() && "bg-blue-60"
                  }`}
                >
                  <span className="self-center">
                    {isSelected() && <CheckIcon size={16} />}
                  </span>
                </div>
                <input
                  type="checkbox"
                  className="hidden"
                  id={value}
                  name={label}
                  value={value}
                  checked={isSelected()}
                  readOnly
                  style={{ marginRight: "5px" }}
                />
                {label}
              </div>
            )
          })
        } 

        {filter.type == EFilter.TEXT &&
            <div>TODO</div>
        }

        {filter.type == EFilter.TAG && 
             
               <div
                 className="pl-6"
               >
                 <TagInput
                   className="pt-0 pb-1"
                   showLabel={false}
                   placeholder="Spring, summer..."
                   values={filter.value}
                   onChange={(values) => {
                        console.log(values);
                        update(filter, values)
                   }}
                 />
               </div>
            
        }
        
        
      </RadixCollapsible.Content>
    </RadixCollapsible.Root>
  </div>
}