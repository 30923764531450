import * as PopoverPrimitive from "@radix-ui/react-popover";
import clsx from "clsx";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "../../fundamentals/button";
import ArrowDownIcon from "../../fundamentals/icons/arrow-down-icon";
import PlusIcon from "../../fundamentals/icons/plus-icon";
import InputContainer from "../input-container/input-container";
import InputHeader from "../input-header/input-header";
import CustomHeader from "./custom-header";
import { DateTimePickerProps } from "./types";
import { fr } from 'date-fns/locale'
import CalendarIcon from "../../fundamentals/calendar-icon";

const getDateClassname = (d: Date, tempDate: Date) => {
  return moment(d).format("YY,MM,DD") === moment(tempDate).format("YY,MM,DD")
    ? "date chosen"
    : `date ${
        moment(d).format("YY,MM,DD") < moment(new Date()).format("YY,MM,DD")
          ? "past"
          : ""
      }`;
};

const DatePicker: React.FC<DateTimePickerProps> = ({
  date,
  onSubmitDate,
  reset,
  label = "start date",
  required = false,
  tooltipContent,
  tooltip,
  minDate = null,
  maxDate = null,
  simple = false
}) => {
  const [tempDate, setTempDate] = useState(date);
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    setTempDate(date ? date : new Date())
  }, [isOpen]);


  const submitDate = () => {
    // update only date, month and year
    if(tempDate){
      const newDate = new Date(tempDate.getTime());
      newDate.setUTCDate(tempDate.getUTCDate());
      newDate.setUTCMonth(tempDate.getUTCMonth());
      newDate.setUTCFullYear(tempDate.getUTCFullYear());

      onSubmitDate(newDate);
      setIsOpen(false);
    }  
  };

  return (
    <div className={`w-full ${simple? 'grid gap-y-xsmall' : ''}`}>
      {simple && <label className="inter-small-semibold text-grey-50">{label}</label>}
      <PopoverPrimitive.Root open={isOpen} onOpenChange={setIsOpen}>
        <PopoverPrimitive.Trigger asChild>
          <button
            className={clsx("", {
              "shadow-input border-violet-60": isOpen && !simple,
              "border-grey-20": !isOpen && !simple,
              "rounded-rounded border w-full cursor-pointer": !simple
            })}
          >
            <InputContainer className={clsx("", {
              "shadow-input border-violet-60": simple && isOpen,
              "border-grey-20": simple && !isOpen,
              "rounded-rounded border w-full cursor-pointer shadown-none focus-within:shadow-none h-10 justify-center": simple,
              "border-0 shadown-none focus-within:shadow-none": !simple
            })}>
              {!simple && 
                <div className="w-full flex text-grey-50 pr-0.5 justify-between">
                  <InputHeader 
                    {...{
                      label,
                      required,
                      tooltipContent,
                      tooltip,
                    }}
                  />
                  <ArrowDownIcon size={20} />
                </div>
              }
            
              {date && 
                <div className="flex flex-row space-between items-center">
                  <label className={`w-full text-left ${simple? '' : 'mt-2'} text-grey-90`}>
                    {moment(date).format("LL")}
                  </label>
                  {simple && <CalendarIcon/>}
                </div>
              }
            </InputContainer>
          </button>
        </PopoverPrimitive.Trigger>
        <PopoverPrimitive.Content
          side="top"
          sideOffset={8}
          className="rounded-rounded border border-grey-20 bg-grey-0 w-full shadow-dropdown z-40"
        >
          {tempDate && <CalendarComponent date={tempDate} onChange={setTempDate} minDate={minDate} maxDate={maxDate}/> }
          <div className="flex w-full p-2 mt-5 flex space-x-4">
            <Button
              variant="ghost"
              size="medium"
              onClick={() => {setIsOpen(false); if(reset){reset()} }}
              //className="mr-2 w-1/3 flex justify-center border border-grey-20"
              className=" w-2/3 flex justify-center  border border-grey-20"
            >{`Annuler`}</Button>
            <Button
              size="medium"
              variant="primary"
              onClick={() => submitDate()}
              className="w-2/3 flex justify-center"
            >{`Modifier`}</Button>
          </div>
        </PopoverPrimitive.Content>
      </PopoverPrimitive.Root>
    </div>
  );
};
interface ICalendarProps {
  date: Date;
  onChange: (e: any) => void;
  minDate?: Date | null,
  maxDate?: Date | null
}


export const CalendarComponent = ({ date, onChange, minDate, maxDate }: ICalendarProps) => {

  // const [highlightDates, setHighlightDates] = useState<Date[]>([])
  // useEffect(() => {
  //   if (minDate && maxDate) {
  //     setHighlightDates([minDate, maxDate])
  //   } else if (minDate) {
  //     setHighlightDates([minDate])
  //   } else if (maxDate) {
  //     setHighlightDates([maxDate])
  //   }
  // }, [minDate, maxDate])
  
  return (
    <ReactDatePicker
      selected={date || null}
      inline
      onChange={onChange}
      calendarClassName="date-picker"
      dayClassName={(d: Date) => getDateClassname(d, date)}
      renderCustomHeader={({ ...props }) => <CustomHeader {...props} />}
      locale={fr}
      // highlightDates={highlightDates}
      minDate={minDate}
      maxDate={maxDate} 
    />
  )
};

export default DatePicker;
