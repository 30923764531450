import { useEffect, useState } from "react";
import Modal from "../../../../components/fundamentals/modal";
import LayeredModal from "../../../../components/fundamentals/modal/layered-modal";
import Table from "../../../../components/fundamentals/table";
import TableContainer from "../../../../components/fundamentals/table-container";
import { useDraggable } from "../../../../hooks/use-draggable";
import { Category } from "../../../../models/Category";
import { TopViewAnalytics } from "../../../../models/TopViewAnalytics";
import { DataPaginate } from "../../../../models/utils/DataPaginate";
import categoryService from "../../../../services/category.service";
import { TopViewRow } from "./TopViewRow";

interface IProps {
  analytics: TopViewAnalytics[]
}

export default function CategoryTable({analytics}: IProps) {
console.log(analytics)
  return (
    <div>
      <TableContainer
        numberOfRows={20}
      >
        <Table
        >
          <>
            <Table.Head>
              <Table.HeadRow>
                <Table.HeadCell>Nom du produit</Table.HeadCell>
                <Table.HeadCell>Code EAN13</Table.HeadCell>
                <Table.HeadCell>Code du produit</Table.HeadCell>
                <Table.HeadCell>Visiteurs uniques</Table.HeadCell>
                <Table.HeadCell>Nombre de vues</Table.HeadCell>
              </Table.HeadRow>
            </Table.Head>
            <Table.Body>
              {analytics.map((tp) => <TopViewRow topView={tp}/>)}
            </Table.Body>
          </>
          {/* {showEditCategory && (
            <ModalCategory onClose={() => setShowEditCategory(false)} category={ca}/>
          )} */}
        </Table>
      </TableContainer>
    </div>
  );
}
