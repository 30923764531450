export class Price{
    public price_ht?: number;
    public price_total_ht: number;
    public price_ttc: number;
    public price_total_ttc: number|null;
    public discount_1:number;
    public discount_2:number;
    public discount_3:number;
    public step:number;

    constructor(data: any){
        this.price_ht = data.price_ht;
        this.price_total_ht = data.price_total_ht;
        this.price_ttc = data.price_ttc;
        this.price_total_ttc = data.price_total_ttc;
        this.discount_1 = data.discount_1;
        this.discount_2=data.discount_2;
        this.discount_3=data.discount_3;
        this.step=data.step ?? 1;
    }

    getPriceHTFormat(){
        if (this.price_ht) {
            return Number.parseFloat(this.price_ht.toString()).toFixed(2).replace('.',',')
        }
        return 
    }
    getPriceTotalHTFormat(){
        if (this.price_total_ht) {
            return Number.parseFloat((this.price_total_ht).toString()).toFixed(2).replace('.',',')
        }
        return
    }
    getPriceHTByQuantity(quantity:number, divider:number){
        if(this.price_total_ht){
            return Number.parseFloat(((this.price_total_ht / divider) * quantity).toString()).toFixed(2).replace('.',',')
        }
        return
    }
    getPriceTTCFormat(){
        if(this.price_ttc){
            return Number.parseFloat(this.price_ttc.toString()).toFixed(2).replace('.',',')
        }
        return
    }
    getPriceTotalTTCFormat(){
        if(this.price_total_ttc){
            return Number.parseFloat(this.price_total_ttc.toString()).toFixed(2).replace('.',',')
        }
        return
    }
    getPriceTTCByQuantity(quantity:number, divider:number){
        if(this.price_ttc){
            return Number.parseFloat(((this.price_ttc / divider ) * quantity).toString()).toFixed(2).replace('.',',')
        }
        return
    }
    getDiscount(){
        if(this.discount_1){
            return Number.parseFloat((this.discount_1).toString()).toFixed(0)
        }
        return
    }
    getPriceByUnity(multiplier: number){
        if (this.price_total_ht) {
            return Number.parseFloat((this.price_total_ht / multiplier).toString()).toFixed(2).replace('.',',')
        }
        return
    }
}