import BodyCard from "../../../../components/fundamentals/body-card/body-card";
import TableViewHeader from "../../../../components/fundamentals/custom-table-header";
import OrderTable from "./order-table/OrderTable";

const VIEWS = ["commandes"];

export default function OrdersPage() {
  return (
    <div className="flex flex-col grow h-full">
      <div className="w-full flex flex-col grow">
        <BodyCard
          forceDropdown={false}
          customActionable={
            <div className="flex space-x-2">
              {/*<Button
              variant="secondary"
              size="small"
              onClick={openProductCreate}
              >
                <PlusIcon size={20} />
                Nouveau produit
              </Button>*/}
            </div>
          }
          customHeader={<TableViewHeader views={VIEWS} />}
          className="h-fit"
        >
          <OrderTable />
        </BodyCard>
      </div>
      {/* {showNewCollection && <ModalCollection onClose={() => setShowNewCollection(false)} /> } */}
    </div>
  );
}
