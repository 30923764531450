import { useRef, useState } from "react";
import DownloadIcon from "../../fundamentals/icons/download-icon";

interface IDragDropImage {
  setFileUpl: (value: any) => void;
  fileUpl: any;
  displayRequired?: boolean;
}

function DragDropImage({
  setFileUpl,
  fileUpl,
  displayRequired,
}: IDragDropImage) {
  const inputFileRef = useRef<any>(null);
  const [dragActive, setDragActive] = useState<boolean>(false);

  const generateFile = async (files: any) => {
    if (files.length === 0) return;
    const file = files[0];
    const base64 = await readFileAsDataURL(file);
    console.log(base64);
    setFileUpl(base64);
  };

  async function readFileAsDataURL(file: any) {
    let result_base64 = await new Promise((resolve) => {
      let fileReader = new FileReader();
      fileReader.onload = (e) => resolve(fileReader.result);
      fileReader.readAsDataURL(file);
    });

    return result_base64;
  }

  const handleDrag = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      generateFile(e.dataTransfer.files);
    }
  };
  return (
    <div className="w-full">
      <input
        hidden
        id="file-button"
        type="file"
        onClick={(event) => {
          event.stopPropagation();
        }}
        onChange={(e: any) => {
          generateFile(e.target.files);
          e.target.value = null;
        }}
        ref={inputFileRef}
      />
      <div className="relative" onDragEnter={handleDrag}>
        <button
          // className={`drag-drop ${dragActive ? "active" : ""}`}
          className={`${
            dragActive ? "bg-sky-50" : ""
          } hover:bg-sky-50 w-full h-28 rounded-lg flex flex-col items-center justify-center border border-gray-300 border-dashed	transition-all duration-100`}
          onClick={() => inputFileRef.current?.click()}
        >
          <span className="transform-none text-gray-500 font-medium text-medium">
            Déposez votre image ici,
            <span className="text-sky-700"> ou cliquez pour parcourir</span>
          </span>
          <span className="text-gray-700 text-xs mt-2">Fichier svg ou png</span>
        </button>
        {!fileUpl && displayRequired && (
          <div className="mt-4 text-center text-red-600">
            Le fichier est obligatoire.
          </div>
        )}
        {dragActive && (
          <div
            id="drag-file-element"
            className="absolute w-full h-full top-0 right-0 bottom-0 left-0 rounded-lg"
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          ></div>
        )}
      </div>
    </div>
  );
}

export default DragDropImage;
