import { useEffect, useState } from "react";
import { DataPaginate } from "../../../../../models/utils/DataPaginate";
import Table from "../../../../../components/fundamentals/table";
import TableContainer from "../../../../../components/fundamentals/table-container";
import {
  EFilter,
  IFilter,
} from "../../../../../models/utils/filter/FilterType.interface";
import promotionsService from "../../../../../services/promotions.service";
import clientsService from "../../../../../services/clients.service";
import { Promotion } from "../../../../../models/Promotion";
import PromotionRow from "./PromotionRow";
import FocusModal from "../../../../../components/fundamentals/modal/focus-modal";
import ModalPromotion from "../../modal-promotion/ModalPromotion";
import {getTablePagingState} from "../../../../../services/utils/TableHelper";

interface IProps{
  refresh : boolean
  setRefresh : (argument: boolean)=>void
}

export default function PromotionTable({refresh , setRefresh}:IProps) {
  const [dataPaginate, setDataPaginate] = useState<DataPaginate>();
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState<string>();
  const [tmpSearch, setTmpSearch] = useState<string>();
  const [filters, setFilters] = useState<IFilter[]>();
  const [promotionEdit, setPromotionEdit] = useState<Promotion | null>(null);


  async function fetchOrders() {
    setIsLoading(true);
    setDataPaginate(await promotionsService.list(currentPage, search, filters));
    setIsLoading(false);
    setRefresh(false)
  }

  useEffect(() => {
    fetchOrders();
  }, [currentPage, search, filters, refresh]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setCurrentPage(1);
      setSearch(tmpSearch);
    }, 400);

    return () => clearTimeout(delayDebounceFn);
  }, [tmpSearch]);
  // ---- FILTERS ----
  // useEffect(() => {
  //   async function fetchFilters() {
  //     const clients = await clientsService.list(currentPage, search, filters);
  //     const clientsList = clients.data.map((c) => {
  //       return {
  //         label: c.name,
  //         value: c.id,
  //       };
  //     });
  //     setFilters([
  //       {
  //         name: "date",
  //         type: EFilter.DATE,
  //         label: "Date",
  //       },
  //       {
  //         name: "client",
  //         type: EFilter.SELECT_MULTIPLE,
  //         label: "Client",
  //         list: clientsList,
  //       },
  //       {
  //         name: "sell_canal",
  //         type: EFilter.SELECT_MULTIPLE,
  //         label: "Canal de vente",
  //         list: [
  //           {
  //             label: "Canal 1",
  //             value: 1,
  //           },
  //           {
  //             label: "Canal 2",
  //             value: 2,
  //           },
  //         ],
  //       },
  //       {
  //         name: "pick_up_address",
  //         type: EFilter.SELECT_MULTIPLE,
  //         label: "Adresse de retrait",
  //         list: [
  //           {
  //             label: "Adresse 1",
  //             value: 1,
  //           },
  //           {
  //             label: "Adresse 2",
  //             value: 2,
  //           },
  //         ],
  //       },
  //     ]);
  //   }
  //   fetchFilters();
  // }, []);

  return (
    <div>
      <TableContainer
        numberOfRows={10}
        hasPagination
        pagingState={getTablePagingState(dataPaginate!, setCurrentPage, "résultats")}
        isLoading={isLoading}
      >
        <Table
          enableSearch={true}
          searchValue={tmpSearch}
          handleSearch={(term) => setTmpSearch(term)}
          // filters={filters}
          // onClear={() => {
          //   setCurrentPage(1);
          //   if (filters) {
          //     setFilters(
          //       filters.map((f) => {
          //         f.value = undefined;
          //         return f;
          //       })
          //     );
          //   }
          // }}
          // onFilter={(filters: IFilter[]) => {
          //   setCurrentPage(1);
          //   setFilters(filters);
          // }}
        >
          <Table.Head>
            <Table.HeadRow>
              <Table.HeadCell >Nomre de l'offre</Table.HeadCell >
              <Table.HeadCell >Nombre de produits</Table.HeadCell >
              <Table.HeadCell >Date de début</Table.HeadCell >
              <Table.HeadCell >Date de fin</Table.HeadCell >
              <Table.HeadCell >Visibilité</Table.HeadCell >
            </Table.HeadRow>
          </Table.Head>
          <Table.Body>
            {dataPaginate &&
              dataPaginate?.data.length &&
              dataPaginate.data.map((promotion: Promotion, index: number) => (
                <PromotionRow
                  key={`promotion_item_${promotion.id}`}
                  promotion={promotion}
                  index={index + 1}
                  refresh={fetchOrders}
                  setPromotionEdit={() => setPromotionEdit(promotion)}
                />
              ))}
          </Table.Body>
        </Table>
      </TableContainer>
     
      {promotionEdit && (
        <ModalPromotion
            modalTitle="Modifier une offre promotionnelle"
            promotion={promotionEdit}
            handleClose={() => {
              setPromotionEdit(null)
              setRefresh(true)
            }}
        />
      )}
    </div>
  );
}
