export class OrderTunnelAnalytics {
    public type: string;
    public sessions: number;
    public users: number;
    public clicks: number;

    constructor(data: any){
        this.type = data.type;
        this.sessions = data.sessions;
        this.users = data.users;
        this.clicks = data.clicks;
    }
}