import React from 'react';
import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './assets/styles/global.css';
import router from './router/router';

function App() {
  
  return (
    <RouterProvider router={router} />
  );
}

export default App;

