import * as DropdownMenu from "@radix-ui/react-dropdown-menu"
import React, {
  type MouseEvent,
  useCallback,
  useContext,
  useState,
} from "react"
import { useNavigate } from "react-router-dom"
import useToggleState from "../../../hooks/use-toggle-state"
import { User } from "../../../models/User"
import authService from "../../../services/auth.service"
import SearchBar from "../../form/search-bar/search-bar"
import Button from "../../fundamentals/button"
import GearIcon from "../../fundamentals/icons/gear-icon"
import HelpCircleIcon from "../../fundamentals/icons/help-circle"
import SignOutIcon from "../../fundamentals/icons/log-out-icon"
import ActivityDrawer from "../activity-drawer"
import Avatar from "../avatar"
import MailDialog from "../help-dialog"
import NotificationBell from "../notification-bell"

interface IProps{
  user: User
}
function Topbar({user}: IProps){
  const navigate = useNavigate()

  const {
    state: activityDrawerState,
    toggle: toggleActivityDrawer,
    close: activityDrawerClose,
  } = useToggleState(false)

  const logOut = () => {
    authService.logout();
    navigate("/login")
  }

  return (
    <div className="w-full min-h-topbar max-h-topbar pr-xlarge pl-base bg-grey-0 border-b border-grey-20 sticky top-0 flex items-center justify-between z-40">
      <SearchBar />
      <div className="flex items-center">
        <div className="ml-large w-large h-large">
          <DropdownMenu.Root>
            <DropdownMenu.Trigger asChild>
              <div className="cursor-pointer w-full h-full">
                <Avatar
                  user={{ first_name: user.firstname , last_name: user.lastname, email: user.email }}
                  color="bg-fuschia-40"
                />
              </div>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content
              sideOffset={5}
              className="border bg-grey-0 border-grey-20 rounded-rounded shadow-dropdown p-xsmall min-w-[200px] z-30"
            >
              <DropdownMenu.Item className="mb-1 last:mb-0">
                <Button
                  variant="ghost"
                  size="small"
                  className={"w-full justify-start"}
                  onClick={() => navigate("/settings")}
                >
                  <GearIcon />
                  Paramètres
                </Button>
                <Button
                  variant="ghost"
                  size="small"
                  className={"w-full justify-start text-rose-50"}
                  onClick={() => logOut()}
                >
                  <SignOutIcon size={20} />
                  Sign out
                </Button>
              </DropdownMenu.Item>
            </DropdownMenu.Content>
          </DropdownMenu.Root>
        </div>
      </div>
      {activityDrawerState && (
        <ActivityDrawer onDismiss={activityDrawerClose} />
      )}
    </div>
  )
}

export default Topbar
