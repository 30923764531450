import { Analytics } from "./Analytics";

export class TopViewAnalytics {
    public route: string;
    public analytics: Analytics;
    public payload: any;
  
    constructor(data: any){
        this.route = data.route;
        this.analytics = new Analytics({
            visitors: data.visitors,
            visits: data.visits,
            views: data.views
        })
        this.payload = data.payload
    }
  }