import React from "react";
import IconProps from "./types/icon-type";

const ChevronDownLightIcon: React.FC<IconProps> = ({
  size = "10px",
  color = "currentColor",
  ...attributes
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 6 11"
      fill="none"
      {...attributes}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1L6 6L11 1"
        stroke="#475467"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ChevronDownLightIcon;
